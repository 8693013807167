import '../../../Style/animation.css'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import BannerImg from '../../../Assets/Image/service-page/explainer-video-banner.webp'
import ConceptDev from "../../../Assets/Image/service-page/concept-development-icon.svg"
import StoryBording from "../../../Assets/Image/service-page/storybording-icon.svg"
import Design from "../../../Assets/Image/service-page/design-icon.svg"
import Sound from "../../../Assets/Image/service-page/sound-icon.svg"
import Review from "../../../Assets/Image/service-page/review-icon.svg"
import ExplainerVideoTool from '../../../Assets/Image/service-page/explainer-web-animation.webp'
import Animation2D from '../../../Assets/Image/animation/Skater-Boy.webp'
import Animation3D from '../../../Assets/Image/animation/Latest-Projects-_-MadebyRadio.webp'
import MotionGraphics from '../../../Assets/Image/animation/Producer.gif'
import WhiteboardAnimation from '../../../Assets/Image/animation/Motion-Design_-35-Funny-Animations-by-Markus-Magnusson.webp'
import ScreenCast from '../../../Assets/Image/service-page/screen cast -animation.gif'
import LiveAction from '../../../Assets/Image/service-page/live-action-animation.gif'
import KineticTypography from '../../../Assets/Image/service-page/kinetic-typography.gif'
import CharacterAnimation from '../../../Assets/Image/service-page/charactor-animation.gif'
import ExplainerVideo from '../../../Assets/Image/service-page/Explainer-Video.gif'
import WhyShouldUse1 from '../../../Assets/Image/service-page/Exceptional-Creative-Team-explainer.svg'
import WhyShouldUse2 from '../../../Assets/Image/service-page/Client-Collaboration-expalainer.svg'
import WhyShouldUse3 from '../../../Assets/Image/service-page/State-of-the-Art-Tools-explainer.svg'
import WhyShouldUse4 from '../../../Assets/Image/service-page/Making-changes-and-tailoring-explainer.svg'
import WhyShouldUse5 from '../../../Assets/Image/service-page/Innovative-Storytelling-explainer.svg'
import WhyShouldUse6 from '../../../Assets/Image/service-page/Results-Driven-Approach-explainer.svg'
import WhyShouldUse7 from '../../../Assets/Image/service-page/Engaging-Visuals-and-Animation-expaliner.svg'
import WhyShouldUse8 from '../../../Assets/Image/service-page/On-Time-Delivery-explainer.svg'
import WhyShouldUse9 from '../../../Assets/Image/service-page/Proven-Track-Record-explainer.svg'
import WhyShouldUse10 from '../../../Assets/Image/service-page/Reviews-from-Clients-explainer.svg'
import ContactForm from '../../../Components/ContactForm';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const ExplainerVideoAnimation = () => {

    const listItem = document.querySelectorAll(".list");

    function activateLink() {
        listItem.forEach((item) => {
            item.classList.remove("active");
        });
        this.classList.add("active");
    }

    listItem.forEach((item) => {
        item.addEventListener("click", activateLink);
    });

    const [scrollToPos, setScrollToPos] = useState(0);

    const handleClick = (e, targetId) => {
        e.preventDefault();
        const targetElement = document.getElementById(targetId);
        const container = document.querySelector(".guide-answers");
        const containerTop = container.getBoundingClientRect().top;
        const currentScrollTop = container.scrollTop;
        const selectionTop = targetElement.getBoundingClientRect().top;

        setScrollToPos(selectionTop - containerTop + currentScrollTop);

        // Optionally, you can add smooth scrolling animation here
        container.scrollTo({
            top: scrollToPos,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        // Scroll to the desired position when scrollToPos changes
        const container = document.querySelector(".guide-answers");
        container.scrollTo({
            top: scrollToPos,
            behavior: "smooth",
        });
    }, [scrollToPos]);


    useEffect(() => {
        const handleScroll = () => {
            let containerTop = document.getElementsByClassName("guide-answers")[0].getBoundingClientRect();
            let questionOne = document.getElementById("question-1").getBoundingClientRect();
            let questionTwo = document.getElementById("question-2").getBoundingClientRect();
            let questionThree = document.getElementById("question-3").getBoundingClientRect();
            let questionFour = document.getElementById("question-4").getBoundingClientRect();
            let questionFive = document.getElementById("question-5").getBoundingClientRect();
            let questionSix = document.getElementById("question-6").getBoundingClientRect();

            let topCondition = containerTop.top + containerTop.top / 2;

            if (questionOne.top < topCondition && questionOne.top > 0) {
                setActiveClass('#guideNav li a[href="#question-1"]');
            } else if (questionTwo.top < topCondition && questionTwo.top > 0) {
                setActiveClass('#guideNav li a[href="#question-2"]');
            } else if (questionThree.top < topCondition && questionThree.top > 0) {
                setActiveClass('#guideNav li a[href="#question-3"]');
            } else if (questionFour.top < topCondition && questionFour.top > 0) {
                setActiveClass('#guideNav li a[href="#question-4"]');
            } else if (questionFive.top < topCondition && questionFive.top > 0) {
                setActiveClass('#guideNav li a[href="#question-5"]');
            } else if (questionSix.top < topCondition && questionSix.top > 0) {
                setActiveClass('#guideNav li a[href="#question-6"]');
            }
        };

        const setActiveClass = (selector) => {
            document.getElementById("guideNav").querySelectorAll('li').forEach((li) => {
                li.classList.remove("active");
            });
            document.querySelector(selector).parentNode.classList.add("active");
        };

        // Attach scroll event listener
        document.getElementsByClassName("guide-answers")[0].addEventListener("scroll", handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            document.getElementsByClassName("guide-answers")[0].removeEventListener("scroll", handleScroll);
        };
    }, []); // Empty dependency array ensures that this effect runs once on mount



    const options = {
        loop: true,
        margin: 10,
        dots: false,
        responsiveClass: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
                nav: true,
                loop: false
            },
            600: {
                items: 1,
                nav: true,
                loop: false
            },
            900: {
                items: 1.5,
                nav: true,
                loop: false
            },
            1000: {
                items: 2.5,
                nav: true,
                loop: false
            },
            1300: {
                items: 3,
                nav: true,
                loop: false
            }
        }
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>Professional 2D and 3D Explainer Video Services in India | Akkenna</title>
                <meta name="description"
                    content="Transform your ideas into captivating visuals with Akkenna's expert 2D and 3D explainer video services in India. Elevate your brand and more" />
                <meta name="keywords"
                    content="explainer video services in india, 2d and 3d animation service,  2d and 3d animated explainer video service, 3D animation services in india" />
            </Helmet>

            <main id="explainer_video">
                <section className="banner">
                    <div className="container-fluid bannerImage">
                        <div className="container bannerCont">
                            <div className="row justify-content-between">
                                <div className="col-lg-7 bannerLeft">
                                    <div>
                                        <h5 className="text-violet fw-bold mb-0">Transforming Ideas into Stories</h5>
                                    </div>
                                    <div>
                                        <h1 className="text-white">
                                            Explainer Video Service
                                        </h1>
                                    </div>
                                    <div className='animation-bannerpara'>
                                        <p className="h6 text-bannerpara nunitoMedium">
                                            Captivate your audience and convey your message with our expertly crafted explainer videos.
                                        </p>
                                    </div>
                                    <div className="animation-bannerBtn">
                                        <a href="/contact" target='_blank'>
                                            <button className="whiteBtn bg-whiteButton text-smallHeading fw-bold">
                                                Ready to Engage Your Audience?
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-5 bannerRightImage">
                                    <div className="animation-banner">
                                        <img src={BannerImg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* <Container fluid className='mt-5 mb-5 video-explain'>
                <div className="container mb-4">
                    <div className="row text-center d-flex justify-content-center align-items-center">
                        <div className="col-lg-10">
                            <h3 className="fw-bold BringHeading">
                                Our Latest And Greatest Explainer Videos
                            </h3>
                            <p className="animation-text-para nunitoRegular">
                                You can use our digital storytelling expertise to explain your
                                ideas, product or service to the world.
                                Watch some of our successful explainer video production
                                projects.
                            </p>
                        </div>
                    </div>
                </div>
                <Container>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row className='d-flex flex-column justify-content-center align-items-center' >
                            <Col lg={9} className="text-center d-flex justify-content-center align-items-center video-explainer">
                                <Nav variant="pills" className='d-flex justify-content-center align-items-center' >
                                    <Nav.Item className="col-md-4 video-explainer-title explainer">
                                        <Nav.Link eventKey="first" className="video-explainer-text">Animated Explainer Video</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="col-md-4 video-explainer-title whiteboard">
                                        <Nav.Link eventKey="second" className="video-explainer-text">Whiteboard Video</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="col-md-4 video-explainer-title live">
                                        <Nav.Link eventKey="third" className="video-explainer-text">Live Video Production</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col lg={10} className='mt-3'>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first" className="row justify-content-center video-cards">
                                        <div className="technologyCards">
                                            <div>
                                                <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/KmSSQveJ258?si=4zC6ZAHjwH7xCitx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                            <div>
                                                <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/8XST11KhL7k?si=5gimrlerw1JRIYWM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second" className="row justify-content-center video-cards">
                                        <div className="technologyCards">
                                            <div>
                                                <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/4xwQpzeiHLE?si=G80kQjXKgZ2gWGO-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                            <div>
                                                <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/FDZVGJIM0W8?si=G_TcVgsO77bNWKEV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div className="technologyCards">
                                            <div>
                                                <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/AEEWRfU4Er8?si=Dc3T4uw07ufaCUOb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                            <div>
                                                <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/bBP4HskFDBg?si=3ckJAehRW0FvB2_8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </Container>


            <section className="container-fluid animation hireLabelTag pt-4 pb-4">
                <div className="container">
                    <div className="row justify-content-center align-items-center">

                        <div className="col-md-5 col-lg-5 HireHeading text-right">
                            <h4 className="text-white">Need Help With a Project?</h4>
                            <h4 className="text-white" style={{ display: "none" }}>
                                Need Help With a Project?
                            </h4>
                        </div>
                        <div className="col-md-2 col-lg-2 hire-arrowImg text-left">
                            <img src={arrow} alt="arrow" />
                        </div>
                        <div className="col-md-3 col-lg-3 hirePosition text-center">
                            <img src={btnImage} alt="" style={{ display: "none" }} />
                            <div className="hirebtnbg hirePositionRelatbutton">
                                <button className="whiteBtn bg-whiteButton text-smallHeading fw-bold text-center hireBtn">
                                    <a href="/contact"  target='_blank'>Get in Touch</a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}



                <section className="container-fluid mt-5 process-section">
                    <div className="container">
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-md-10">
                                <div className="process-title">
                                    <p>Our Animated Explainer Video Production Process</p>
                                </div>
                            </div>
                            <div className="col-md-10 process-description nunitoRegular">
                                <div className="">
                                    <p>
                                        Akkenna, an animated explainer video company in India, takes these steps to make sure your video looks great, gets your message across, meets the needs of your business, and fits the style and tone of your brand. Collaborative research-based explainer videos will be created. At our Akkenna Explainer Video Productions company, the following steps will be followed to create high-quality videos
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row text-center d-flex justify-content-between align-item-center">
                            <div className="col-lg-2 col-md-2 centered-div">
                                <div className="content">
                                    <p className="rounded-circle search">
                                        <img src={ConceptDev} alt="" />
                                    </p>
                                    <label>Discovery Phase</label>
                                    {/* <label style="display: none">Concept Development</label> */}
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 centered-div">
                                <div className="content">
                                    <p className="rounded-circle bulb">
                                        <img src={StoryBording} alt="" />
                                    </p>
                                    <label>Storyboarding</label>
                                    {/* <label style="display: none">Storyboarding</label> */}
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 centered-div">
                                <div className="content">
                                    <p className="rounded-circle people">
                                        <img src={Design} alt="" />
                                    </p>
                                    <label>Design</label>
                                    {/* <label style="display: none">Design and Animation</label> */}
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 centered-div">
                                <div className="content">
                                    <p className="rounded-circle time">
                                        <img src={Sound} alt="" />
                                    </p>
                                    <label>Animation and Voiceover</label>
                                    {/* <label style="display: none">Sound Design</label> */}
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 centered-div">
                                <div className="content">
                                    <p className="rounded-circle settings">
                                        <img src={Review} alt="" />
                                    </p>
                                    <label>Review and Delivery</label>
                                    {/* <label style="display: none">Review and Final Delivery</label> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="container-fluid pt-3 pb-3 mt-5 bgVoilet experience-section">
                    <div className="container">
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-md-10 experience-title ">
                                <p className='text-bannerpara'>Why Should You Use Akkenna Animation?</p>
                            </div>
                        </div>
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-md-10 experience-description">
                                <p className='text-white'>
                                    Selecting Akkenna as your explainer video company is a smart move if you need one because we are the best animated explainers committed to quality, innovation, and making sure our customers are completely satisfied. The following is a list of persuasive arguments in favor of selecting our company
                                </p>
                            </div>
                        </div>
                        <div className="row experience-image nunitoRegular pt-3 mb-3">
                            <div className="col-md-6 motion-services">
                                <div className="row experience-content ani-boxes">
                                    <div className="col-md-12 d-flex gap-3 mb-3">
                                        <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                                            <img src={WhyShouldUse1} alt="" />
                                        </div>
                                        <div>
                                            <p className="experience-subtitle mb-1">
                                                Exceptional Creative Team
                                            </p>
                                            <p className="experience-description">
                                                Our skilled animators, scriptwriters, and creatives create appealing explanation videos.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 d-flex gap-3 mb-3">
                                        <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                                            <img src={WhyShouldUse2} alt="" />
                                        </div>
                                        <div>
                                            <p className="experience-subtitle mb-1">Client Collaboration</p>
                                            <p className="experience-description">
                                                We collaborate openly with clients. Feedback is valued throughout video production.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-md-12 d-flex gap-3 mb-3">
                                        <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                                            <img src={WhyShouldUse3} alt="" />
                                        </div>
                                        <div>
                                            <p className="experience-subtitle mb-1">State-of-the-Art Tools</p>
                                            <p className="experience-description">
                                                Advanced animation and video production tools let us create amazing explainer videos.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 d-flex gap-3 mb-3">
                                        <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                                            <img src={WhyShouldUse4} alt="" />
                                        </div>
                                        <div>
                                            <p className="experience-subtitle mb-1">Making changes and tailoring</p>
                                            <p className="experience-description">
                                                Each client and project is unique. We customize explainer videos for you.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 d-flex gap-3 mb-3">
                                        <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                                            <img src={WhyShouldUse5} alt="" />
                                        </div>
                                        <div>
                                            <p className="experience-subtitle mb-1">Innovative Storytelling</p>
                                            <p className="experience-description">
                                                Through visual storytelling, we simplify complex ideas.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 motion-services">
                                <div className="row experience-content">
                                    <div className="col-md-12 d-flex gap-3 mb-3">
                                        <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                                            <img src={WhyShouldUse6} alt="" />
                                        </div>
                                        <div>
                                            <p className="experience-subtitle mb-1">Results-Driven Approach</p>
                                            <p className="experience-description">
                                                Our service that increase conversions, educate your audience, or both are our main focus.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 d-flex gap-3 mb-3">
                                        <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                                            <img src={WhyShouldUse7} alt="" />
                                        </div>
                                        <div>
                                            <p className="experience-subtitle mb-1">Engaging Visuals and Animation</p>
                                            <p className="experience-description">
                                                We pride ourselves on making videos that convey a message and are visually appealing.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 d-flex gap-3 mb-3">
                                        <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                                            <img src={WhyShouldUse8} alt="" />
                                        </div>
                                        <div>
                                            <p className="experience-subtitle mb-1">On-Time Delivery</p>
                                            <p className="experience-description">
                                                We know deadlines matter. We'll provide your explainer video on time and on budget.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 d-flex gap-3 mb-3">
                                        <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                                            <img src={WhyShouldUse9} alt="" />
                                        </div>
                                        <div>
                                            <p className="experience-subtitle mb-1">Proven Track Record</p>
                                            <p className="experience-description">
                                                Our portfolio highlights a selection of completed projects and happy customers.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 d-flex gap-3 mb-3">
                                        <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                                            <img src={WhyShouldUse10} alt="" />
                                        </div>
                                        <div>
                                            <p className="experience-subtitle mb-1">Reviews from Clients</p>
                                            <p className="experience-description">
                                                Our clients' reviews demonstrate our explainer videos' effectiveness.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container-fluid pt-3 experience2-section">
                    <div className="container">
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-md-10 experience-title">
                                <p>Akkenna Animations's Explainer Video Tools</p>
                            </div>
                        </div>
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-md-10 nunitoRegular experience-description">
                                <p>
                                    Akkenna Animation creates high-quality explainer videos using a variety of tools and programs. That being said, here is a list of some of the most common hardware and apps used to make explainer videos:
                                </p>
                            </div>
                        </div>
                        <div className="row experience-image pt-3 mb-1">
                            <div className="col-md-7">
                                <div className="row experience-content circularBook">
                                    <div className="col-md-12 mb-3">
                                        <p className="experience-subtitle">Illustration and Animation Software</p>
                                        <p className="experience-description">
                                            <b>Adobe After Effects:</b> A powerful animation and composing software

                                        </p>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <p className="experience-subtitle">3D Animation Software</p>
                                        <p className="experience-description">
                                            <b> Autodesk Maya:</b> Create creative 3D animations and visual effects with this app.

                                        </p>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <p className="experience-subtitle">Video Editing Software
                                        </p>
                                        <p className="experience-description">
                                            <b>Adobe Premiere Pro:</b>A flexible video editor for clip assembly, editing, and effects.

                                        </p>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <p className="experience-subtitle">Storyboarding and Design
                                        </p>
                                        <p className="experience-description">
                                            <b>Adobe Illustrator:</b> Useful for vector graphics, drawings, and design.

                                        </p>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <p className="experience-subtitle">Rendering & Composing Software</p>
                                        <p className="experience-description">
                                            <b>Media Encoder:</b> A powerful application that can convert videos to their final forms

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="experience-banner motionImg box-3d">
                                    <img src={ExplainerVideoTool} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




                <section className="container-fluid pt-3 pb-3 mt-5 explainer-section">
                    <div className="container-fluid">
                        <div className="row text-center d-flex justify-content-center align-item-center">
                            <div className="col-md-8 explainer-title">
                                <p>Types Of Explainer Videos</p>
                            </div>
                        </div>
                        <div className="row text-center d-flex justify-content-center align-item-center">
                            <div className="col-md-8 nunitoRegular explainer-description">
                                <p>
                                    Explainer videos are a popular and useful way to teach, share knowledge, or promote a product. Explainer movies come in different types, each made to do a different job and interest a different group of people. All of the following are popular types of explainer videos.
                                </p>
                            </div>
                        </div>
                        <div className="row text-center d-flex justify-content-center align-item-center">
                            <div className="col-md-12 explain-carousel">
                                <OwlCarousel className='owl-theme ' loop margin={15} nav {...options}>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={Animation2D} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>2D Animations</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                The bright and artistically engaging 2D animations use two-dimensional visuals. They can explain products and teach.</p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='process'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={Animation3D} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>3D Animations</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Three-dimensional models and environments give honesty and depth to movies, games, simulations, and commercial demos.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='process'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={MotionGraphics} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Motion Graphics</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Marketing, explainer, and presentation videos use motion graphic videos to visually communicate information, concepts, and narratives.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='process'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={WhiteboardAnimation} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Whiteboard Animation </p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Doodling on paper in whiteboard cartoons helps explain hard ideas. It's easier to do training, presentations, and educational tools.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='process'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={ScreenCast} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Screen Cast </p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Screencast films show how software or digital tools work by recording the computer screen. Many software tutorials and how-to guides use them.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='process'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={LiveAction} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Live Action Videos</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Explainer videos in live-action use real-life footage of people, products, or scenes with animated components for visual appeal and explanation.

                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='process' />
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={MotionGraphics} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Stop Motion</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Stop-motion explainer videos use photos of tangible items to animate. Unique and tactile, they can be visually appealing.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='process'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={KineticTypography} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Kinetic Typography</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Kinetic typography videos produce kinetic text and word animations. They effectively transmit messages and energize material.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='process'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={CharacterAnimation} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Character Animation</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Character animations deliver stories, inform, and engage with the audience. They are useful in marketing and education.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='process'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={ExplainerVideo} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Process Explanation Videos</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Process explanation videos simplify complex workflows with visuals. Manufacturing and healthcare use them extensively.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='process'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </OwlCarousel>

                            </div>

                        </div>
                    </div>
                </section>

                <Container fluid className='mt-5 mb-5'>
                    <Container>
                        <Row>
                            <div className="row text-center circularMedium d-flex justify-content-center align-item-center">
                                <div className="col-md-8 guide-title">
                                    <p>Explainer Videos And Some Useful Tips</p>
                                </div>
                            </div>
                            <div className="row text-center nunitoRegular d-flex justify-content-center align-item-center">
                                <div className="col-md-10 guide-description">
                                    <p>
                                        Explainer videos are short, engaging, and instructive videos that simplify complicated ideas, concepts, and messages for a wide audience. Visuals, narration, text, and animation are used to convey stories, explain processes, and promote products and services. Explainer videos are used in marketing, education, corporate communication, and entertainment.

                                    </p>
                                </div>
                            </div>

                            <Col lg={12} className='d-flex mt-3'>
                                <Col lg={4} className="guide-menu">
                                    <div className="guide-topics">
                                        <p>Guide Topics</p>
                                    </div>
                                    <ul className="nav" id="guideNav">
                                        <li onClick={(e) => handleClick(e, "question-1")} className="active">
                                            <a href="#question-1">Purpose Of Explainer Videos</a>
                                        </li>
                                        <li onClick={(e) => handleClick(e, "question-2")}>
                                            <a href="#question-2">How Explainer Videos Simplify Complex Ideas</a>
                                        </li>
                                        <li onClick={(e) => handleClick(e, "question-3")}><a href="#question-3">Key Elements Of An Effective Explainer Video</a></li>
                                        <li onClick={(e) => handleClick(e, "question-4")}>
                                            <a href="#question-4">What Are the Benefits of Having an Explanation Video?</a>
                                        </li>
                                        <li onClick={(e) => handleClick(e, "question-5")}>
                                            <a href="#question-5">Who Can Benefit From Developing Explainer Videos?</a>
                                        </li>
                                        <li onClick={(e) => handleClick(e, "question-6")}>
                                            <a href="#question-6">Breakdown of Explainer Video Production Services</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col lg={8} className="guide-answers circularBook">
                                    <div id="question-1" className="guide-details">
                                        <p className="guide-sub-question">
                                            Purpose Of Explainer Videos
                                        </p>
                                        <p className="guide-sub-answer">
                                            Many people find explainer films interesting and short. They use different media to try to make complicated ideas, products, or services simple to understand. In order to make something simpler, fix a problem, or get someone to do something, like sign up or buy something, they are often used to teach, inform, or sell.
                                        </p>

                                        <p className="guide-sub-answer">
                                            The basic goal of explainer video creation is to demystify difficult concepts while simultaneously keeping the audience interested. It can be accomplished by:
                                        </p>

                                        <ul className="guide-sub-answer">
                                            <li><b>Clarifying Concepts:</b> Explainers break down complicated ideas, making them easy to understand.</li>
                                            <li><b>Captivating Audiences:</b> People watch explainer videos because they clearly tell stories, which keeps their attention.</li>
                                            <li><b>Driving Action:</b> Explainer films are often used as a call to action, whether the goal is to get people to buy something, sign up for something, or learn more about a product or service.</li>
                                        </ul>
                                    </div>
                                    <div id="question-2" className="guide-details">
                                        <p className="guide-sub-question">
                                            How Explainer Videos Simplify Complex Ideas:
                                        </p>
                                        <ul className="guide-sub-answer">
                                            <li><b>Visual Storytelling:</b> You can make an explainer movie with pictures, animations, and different ways to tell a story to help people understand a hard idea.</li>
                                            <li><b>Clear Messaging:</b> They focus on making their point very clear and concise, leaving out words or technical details that aren't needed.</li>
                                            <li><b>Engagement:</b> Visuals, sound, and a clear plot keep people interested and help them remember what they see and hear.</li>
                                            <li><b>Visualization:</b>People can better understand what they're learning with the help of explainer movies that talk about how things work.</li>
                                            <li><b>Repetition:</b> People can understand and remember what they are learning better when they watch and listen to explainer movies.</li>

                                        </ul>
                                    </div>
                                    <div id="question-3" className="guide-details">
                                        <p className="guide-sub-question">Key Elements Of An Effective Explainer Video</p>
                                        <p className="guide-sub-question">Compelling Scripts</p>
                                        <p className="guide-sub-answer">
                                            The best explainer videos start with good scripts. It should grab attention in the first few seconds, identify the problem or need, explain how the product or service solves it, and end with a strong call to action.
                                        </p>
                                        <p className="guide-sub-question">Visuals and Animations</p>
                                        <p className="guide-sub-answer">
                                            Effective communication relies on visuals and animation. They simplify complex concepts into entertaining images. Animation simplifies complex operations, shows product characteristics, and supports the script.
                                        </p>
                                        <p className="guide-sub-question">Clear and Concise Voiceover</p>
                                        <p className="guide-sub-answer">
                                            The script and images benefit from a clear voiceover. The voiceover should complement the video's tone and style and communicate clearly. I repeat key concepts, guide viewers through the story, and keep them engaged.
                                        </p>
                                        <p className="guide-sub-question">Background Music and Sound Effects</p>
                                        <p className="guide-sub-answer">
                                            Sound effects and music make explainer videos more emotional. Music adds atmosphere, emotion, and relatability to a film. Sound effects emphasize events. Audio must enhance rather than detract from the message.

                                        </p>

                                    </div>
                                    <div id="question-4" className="guide-details">
                                        <p className="guide-sub-question">
                                            What Are the Benefits of Having an Explanation Video?
                                        </p>
                                        <p className="guide-sub-answer">
                                            Nowadays, explainer films are a great way to share information, advertise products and services, and keep people interested in the fast-paced digital world we live in. Explainer videos have several benefits for streamlining and boosting your communication efforts, whether you're a business, educator, or content developer.
                                        </p>

                                        <p className="guide-sub-answer">
                                            Individuals, corporations, and organisations can all benefit from having an explanatory video. The following are some of the primary benefits:
                                        </p>

                                        <p className="guide-sub-answer">
                                            <ul>
                                                <li>Enhance Conversion Rate</li>
                                                <li>Creates Strong Branding</li>
                                                <li>Makes You More Distinctive</li>
                                                <li>To Boost ROI</li>
                                                <li>Boosts Visibility</li>
                                                <li>Excellent On Mobile Devices</li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div id="question-5" className="guide-details">
                                        <p className="guide-sub-question">
                                            Who Can Benefit From Developing Explainer Videos?
                                        </p>
                                        <p className="guide-sub-answer">
                                            A wide range of people, companies, and organisations in numerous industries can profit from explainer video development services.
                                        </p>

                                        <p className="guide-sub-answer">
                                            These and many other industries can have explainer video development services customised to meet their particular requirements, giving them a flexible and interesting approach to reach their target consumers.
                                        </p>
                                        <p className="guide-sub-answer">
                                            Here are some organisations that could benefit from explainer video services:
                                        </p>


                                        <div className="guide-sub-answer d-flex gap-5">
                                            <ul>
                                                <li>Startups</li>
                                                <li>Small Businesses </li>
                                                <li>E-commerce Retailers</li>
                                                <li>Tech Companies </li>
                                                <li>Educational Institutions</li>
                                                <li>Healthcare Providers</li>
                                                <li>Nonprofit Organizations</li>
                                                <li>Fitness and Wellness Professionals</li>
                                            </ul>
                                            <ul>
                                                <li>Financial Institutions</li>
                                                <li>Real Estate Agencies</li>
                                                <li>Government Agencies</li>
                                                <li>Creative Professionals</li>
                                                <li>Manufacturers</li>
                                                <li>Travel and Hospitality Industry</li>
                                                <li>Event Planners</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div id="question-6" className="guide-details">
                                        <p className="guide-sub-question">
                                            Breakdown of Explainer Video Production Services
                                        </p>
                                        <p className="guide-sub-answer">
                                            A good explanation movie that tells a story or sends a message usually follows a set of steps. The way that services for making explainer videos are split up can change based on the project, what the client wants, and what the production company wants. There may need to be more than one version and talks between the client and the production team for the process to go as planned. To make an explainer film, you need to do the following:
                                        </p>

                                        <p className="guide-sub-answer">
                                            <ul>
                                                <li>Pre-Production</li>
                                                <li>Production</li>
                                                <li>Post-Production</li>
                                                <li>Delivery</li>
                                            </ul>
                                        </p>
                                    </div>
                                </Col>
                            </Col>
                        </Row>
                    </Container>

                </Container>

                {

                    <ContactForm />

                }







                <section>
                    <div className="homeFaq">
                        <div className="container">
                            <div className="genaral-title">
                                <div className="row d-flex justify-content-center align-item-center">
                                    <div className="col-md-7 text-center">
                                        <h3 className="fw-bold">Frequently Asked Question</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion accordion-faq" id="accordionfaqExample">
                                <div className="row d-flex justify-content-center align-item-center">
                                    <div className="col-md-6">
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                                                    How much does the explainer video service cost?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseOne" className="accordion-collapse collapse" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    Explainer video costs depend on length, complexity, animation style, and service provider charges. You must discuss your budget and needs with us to obtain an exact price.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
                                                    Can I provide information during the video creation process?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseTwo" className="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    Your opinion and feedback are vital. Akkenna will work with you on scriptwriting, design, and revisions to meet your goals.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
                                                    How long does it take to make an explain video?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseThree" className="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    The time it takes to make an explainer video depends on how long and complicated it is. In most cases, it takes a few weeks to a few months. To meet your needs, it's important to talk to the service provider about schedules.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFour" aria-expanded="false" aria-controls="faq-collapseFour">
                                                    What kinds of explainer films are out there?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseFour" className="accordion-collapse collapse" aria-labelledby="faq-headingFour" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    There are different ways to tell a story or make an explainer film. For each topic, the style of the message, the audience, the brand's image, and the budget determine the choice of one of three main types of explanation films.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseSix" aria-expanded="false" aria-controls="faq-collapseSix">
                                                    Could an explanation video help my business?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseSix" className="accordion-collapse collapse" aria-labelledby="faq-headingSix" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    Explainer videos can make your brand more visible, get your target audience interested, break down complicated ideas, and increase sales. They get your message across well and help your marketing efforts.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingEight">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseEight" aria-expanded="false" aria-controls="faq-collapseEight">
                                                    What industries have you worked with before?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseEight" className="accordion-collapse collapse" aria-labelledby="faq-headingEight" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    We have worked with a lot of different types of businesses, so look at some of our past projects to make sure we can meet the needs of your field.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </HelmetProvider>
    )
}

export default ExplainerVideoAnimation;