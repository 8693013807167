
import React from 'react'
import AboutIndex from './AboutIndex'


const Aboutus = () => {
  return (
    <div>
   <AboutIndex/>
    </div>
  )
}

export default Aboutus