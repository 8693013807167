import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Markdown from "react-markdown";
import '../../Style/blog.css'
import fb from '../../Assets/Image/blog/fb.svg'
import insta from '../../Assets/Image/blog/insta.svg'
import twitter from '../../Assets/Image/blog/twitter.svg'
import share from '../../Assets/Image/blog/share.svg'
import pin from '../../Assets/Image/blog/pin.svg'
import youtube from '../../Assets/Image/blog/youtube.svg'
import Linkedin from '../../Assets/Image/blog/linkedin.svg'
import ContactForm from '../../Components/ContactForm'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Blogs = () => {



  const { url } = useParams();
  const { department } = useParams();
  

  const [blogData, setBlogData] = useState([]);
  const [RecentBlogs, setRecentBlogs] = useState("");

  const Apiurl = "https://api.akkenna.com";

  // const Apiurl = "http://localhost:1337";


  async function getDataFromApi() {
    try {
      const Data = await axios.get(
        `${Apiurl}/api/${department}?filters[seoUrl][$eq]=${url}&populate=*`
      );
      setBlogData(Data.data.data[0]);
      } catch (err) {
      console.log(err);
    }
  }

  function Blogurl(department, url) {
    window.location.href = `https://akkenna.com/blogs/${department}/${url}`;
  }

  async function RecentBlogsApi() {
    let filterDept;
    if (department === "app-developments") {
      filterDept = "App";
    }
    if (department === "devops") {
      filterDept = "devops";
    }
    if (department === "designs") {
      filterDept = "design";
    }
    if (department === "digital-marketings") {
      filterDept = "Marketing";
    }
    if (department === "developements") {
      filterDept = "Web";
    }
    const Recentblogs = await axios.get(
      `${Apiurl}/api/filtering/getRecent${filterDept}`
    );

    if (Recentblogs.status == 200) {
      setRecentBlogs(Recentblogs.data);
    }
  }



  useEffect(() => {
    getDataFromApi();
    RecentBlogsApi();

  }, []);


  function handleCopyFn(e) {
    e.preventDefault()
    const val = window.location.href
    navigator.clipboard.writeText(val)
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Copy
    </Tooltip>
  );

  return (
    <HelmetProvider>
    <div>
      <Helmet>
        <title>{blogData?.attributes?.SEOTitle}</title>
        <meta
          name="description"
          content={blogData?.attributes?.SEODescription}
        />
        <meta name="keywords" content={blogData?.attributes?.SEOKeywords} />
        <meta property="og:image" content={blogData?.attributes?.OGimage} />
      </Helmet>
      <main id='blogDetailsMain'>
        <div className="container-fluid BlogDetailBanner mt-80">
          <div className="container">
            <div className="row BlogDetailMain">
              <div className="col-lg-6 BlogDetailBannerLeft">
                <div>
                  <button
                    id="BlogDetailCategory"
                    className="background-lightViolet FontVoilet"
                  >
                    {blogData?.attributes?.Category}
                  </button>
                </div>
                <div>
                  <h3 className="FontBoldDark circularBook" id="BlogDetailTitle">
                    {blogData?.attributes?.Title}
                  </h3>
                  <small
                    className="FontgrayMid nunitoRegular"
                    id="BlogDetailDescription"
                  >
                    {blogData?.attributes?.Description}
                  </small>
                </div>
                <div>
                  <p className="date FontBoldDark" id="BlogDetailTime">
                    May 02, 2022{" "}
                    <span className="pl-1">
                      {" "}
                      &#9679;{blogData?.attributes?.Author}{" "}
                    </span>{" "}
                    <span className="pl-3">
                      {" "}
                      &#9679;{blogData?.attributes?.Readtime}
                    </span>
                  </p>
                </div>
                <div className="BlogDetailProfinleMai">
                  <div>
                    <img
                      src={`${Apiurl}${blogData?.attributes?.AuthorImage?.data?.attributes?.url}`}
                      id="BlogDetailProfileImg"
                      alt=""
                      className="BlogDetailProfile"
                    />
                  </div>
                  <div id="BlogDetailAuthor">
                    <h6 className="fw-bold circularBook">Written by</h6>
                    <h6 className="FontVoilet nunitoRegular fw-bolder" >
                      {blogData?.attributes?.Author}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 BlogDetailBannerRight">
                <img
                  src={`${Apiurl}${blogData?.attributes?.BannerImage?.data?.attributes?.url}`}
                  alt=""
                  id="BlogDetailBanner"
                />
              </div>
            </div>
            <hr className="hrLine" />
          </div>
        </div>



        <div className="container-fluid mb-5">
          <div className="container">
            <div className="row d-flex justify-content-between">
              <div className="col-lg-7 Contentimg">
                <div id="BlogDetailContent">
                  <Markdown>{blogData?.attributes?.Content}</Markdown>
                </div>
              </div>

              <div className="col-lg-5 d-flex flex-column gap-3 popularBlog" id="popularBlog">
                <div className="sticky-top">
                  <div className="heading">
                    <h5 className="FontBoldDark fw-bold">Recent Posts</h5>
                    <hr />
                  </div>
                  <div className="blogPopular d-flex flex-column gap-3">
                    {RecentBlogs.length > 0 ? (
                      RecentBlogs?.map((item, index) => (
                        <div
                          className="d-flex gap-3 hover-now"
                          onClick={() => {
                            Blogurl(department, item.seoUrl);
                          }}
                        >
                          <h3>{`0${index + 1}`}</h3>
                          <h5 className="FontBoldDark fw-bold">{item.Title}</h5>
                        </div>
                      ))
                    ) : (
                      <h4>No Data</h4>
                    )}
                  </div>
                  <div className="heading mt-5">
                    <h5 className="FontBoldDark fw-bold">Follow Us</h5>
                    <hr />
                  </div>
                  <div className="blogDetailMedia">
                    <div className="row">
                      <div className="col">
                        <a href="https://www.facebook.com/profile.php?id=100083063685938" target="_blank"><img src={fb} alt="" /></a>
                      </div>
                      <div className="col">
                        <a href="https://www.instagram.com/akkennatech/" target="_blank"><img src={insta} alt="" /></a>
                      </div>
                      <div className="col">
                        <a href="https://twitter.com/akkennatech" target="_blank"><img src={twitter} alt="" /></a>
                      </div>
                      <div className="col">
                        <a href="https://www.youtube.com/channel/UClgWW-tIXra9U7N1K7ncA5A" target="_blank"><img src={youtube} alt="" /></a>
                      </div>
                      <div className="col">
                        <a href="https://www.akkenna.com/static/media/linkedin.3ef9a137a2f22c9ad602.webp" target="_blank"><img src={Linkedin} alt="" /></a>
                      </div>
                      <div className="col" onClick={(e) => handleCopyFn(e)}>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <a href=""><img src={share} alt="" /></a>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          <ContactForm />
        }
      </main>
    </div>
    </HelmetProvider>
  );
};

export default Blogs;
