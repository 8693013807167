import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import IconF from "../Assets/Image/NewFooter/IconF.svg";
import IconF from "../Assets/Image/NewFooter/IconF.svg";
import IconV from "../Assets/Image/NewFooter/IconV.svg";
import IconIn from "../Assets/Image/NewFooter/IconIn.svg";
import IconX from "../Assets/Image/NewFooter/IconX.svg";
import Akkenna from "../Assets/Image/NewFooter/akkenna.png";
import Phone from "../Assets/Image/NewFooter/Phone.png";
import Msg from "../Assets/Image/NewFooter/Msg.png";
import "../Style/NewFooter.css"
import Certificate from '../Assets/Image/bfirmsCertified.png'


import Menu from '../Assets/Image/NewFooter/menu.svg'
import call from '../Assets/Image/NewFooter/call.svg'
import bot from '../Assets/Image/NewFooter/bot.svg'
import whatsapp from '../Assets/Image/NewFooter/whatsapp.svg'
import Offcanvas from 'react-bootstrap/Offcanvas';


function MenuCanva({ show, handleClose }) {


  return (
      <section>
          <Offcanvas show={show} onHide={handleClose} placement='start' className='mobileFaqCanva'>
              <Offcanvas.Header closeButton>
              </Offcanvas.Header>
              <Offcanvas.Body>
              <ul>
                  <li><a href='/'>Home</a></li>
                  <li><a href='/about'>About</a></li>
                  <li><a href='/services/website-development-company-coimbatore'>Web Development</a></li>
                  <li><a href='/services/app-development-company-coimbatore'>App Development</a></li>
                  {/* <li><a href='/services/sass-development-company-coimbatore'>Sass Development</a></li> */}
                  <li><a href='/nft-marketplace-development-company'>NFT</a></li>
                  <li><a href='/motion-graphics-company-india'>Motion Graphics</a></li>
                  <li><a href='/explainervideo-animation-company-india'>3D Animation</a></li>
                  <li><a href='/services/software-development-company-in-india'>Software Development</a></li>
                  <li><a href='/services/genai-development-company-in-india'>GenAi</a></li>
                  <li><a href='/services/data-enggineering-development-company-in-india'>Data Engineering Development</a></li>
                  <li><a href='/services/data-analytics-development-company-in-india'>Data Analytics</a></li>
                  <li><a href='/career'>Career</a></li>
                  <li><a href='/jobapply'>Jobapply</a></li>
                  <li><a href='/contact'>Contact</a></li>
                  <li><a href='/privacy-policy'>Privacy Policy</a></li>
                  <li><a href='/term-condition'>Terms and Conditions</a></li>
              </ul>
              </Offcanvas.Body>
          </Offcanvas>
      </section>
  );
}

const NewFooter = () => {
  const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
  return (
    <main id="NewFooter">
      <section className="DesktopFooter">
      <Container fluid className="fluid-bg ">
        <Container className="Footer">
          <Row className="">
            <Col lg={4} className="">
              <Col className="mb-3 mt-5">
                <img src={Akkenna} alt="akkenna Logo" />
              </Col>
              <Col className="mb-1">
                <h5 className="text-white ">Akkenna Technologies Pvt Ltd </h5>
              </Col>
              <Col className="mb-4">
                <p className="foot-para">
                  Start your day with the spirit of exercising and <br />you will
                  get many very important benefits
                </p>
              </Col>
              <Col className="d-flex gap-3 mb-2">
                <img src={Phone} alt="" className="phone" />
                <p className="text-white ">+91 74185 55205</p>
              </Col>
              <Col className="d-flex gap-3 mb-4">
                <img src={Msg} alt="" className="phone" />
                <p className="text-white ">sales@akkenna.com</p>
              </Col>
              <Row>
                <Col className="d-flex mb-1  gap-3">
                  <div className="Icon-Div">
                    <a
                      href="https://www.facebook.com/profile.php?id=100083063685938"
                      target="_blank" rel="noreferrer"
                    >
                      <img src={IconF} alt="FaceBook" className="Icons-Style" />
                    </a>

                  </div>
                  <div className="Icon-Div">
                    <a
                      href="https://www.facebook.com/profile.php?id=100083063685938"
                      target="_blank" rel="noreferrer"
                    >
                      <img src={IconV} alt="V" className="Icons-Style" />
                    </a>
                  </div>
                  <div className="Icon-Div Normal">
                    <a href="https://twitter.com/akkennatech" rel="noreferrer" target="_blank">
                      <img src={IconX} alt="App" className="Icons-Style" />
                    </a>

                  </div>
                  <div className="Icon-Div">
                    <a
                      href="https://www.linkedin.com/company/akkenna-animation-technologies/mycompany"
                      target="_blank" rel="noreferrer"
                    >
                      <img src={IconIn} alt="InApp" className="Icons-Style" />
                    </a>
                  </div>
                  <div  className="certificateImage">
                    <img src={Certificate} alt="" />
                  </div>
                </Col>
                {/* <Col className="certificateImage">
                    
                </Col> */}
              </Row>
            </Col>
            <Col lg={3} className="mt-5">
              <Col>
                <h5 className="text-white text-heads">Address</h5>
              </Col>
              <Col className="mt-2">
                <h6 className="text-USA">USA-New Jersey</h6>{" "}
              </Col>
              <Col className="mt-2">
                <p className="foot-para">
                  1040D Orchard Street, North <br /> Brunswick, New Jersey
                  08902.
                </p>
              </Col>
              <Col className="mt-4">
                <h6 className="text-head">India-Coimbatore</h6>
              </Col>
              <Col className="mt-2">
                <p className="foot-para">
                  No.37/1, First Floor, Rajeshwari Nager,
                  <br /> Udayampalayam Main Road, <br />
                  Nava India, Coimbatore-641028
                </p>
              </Col>
            </Col>
            <Col lg={2} className="">
              <Col className="mt-3">
                <h5 className="text-white mt-5 text-heads">Service</h5>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">UI/UX Design</p>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">Web Development</p>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">App Development</p>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">Digital Marketing</p>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">Blockchain Development</p>
              </Col>
            </Col>
            <Col lg={2} className="">
              <Col className="mt-3">
                <h5 className="text-white mt-5 text-heads">
                  Dedicated Developers
                </h5>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">Hire Reactjs Developer</p>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">Hire Angular Developer</p>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">Hire NodeJs Developer</p>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">Hire Flutter Developer</p>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">PHP Developer</p>
              </Col>
            </Col>
            <Col lg={1} className="">
              <Col className="mt-3">
                <h5 className="text-white mt-5 text-heads">Company</h5>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">About Us</p>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">Careers</p>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">Blogs</p>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">Services</p>
              </Col>
              <Col className="mt-3">
                <p className="foot-para">Contact Us</p>
              </Col>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="foot-bgc">
        <Container>
          <Row>
            <Col lg={4}>
              <h5 className="bt-footer mt-3">
                © 2024 Akkenna Animation & Technologies Pvt Ltd
              </h5>
            </Col>
            <Col lg={4}>
              <h5 className="bt-footer mt-3">CIN : U72200TZ2022PTC039323</h5>
            </Col>
            <Col lg={4}>
              <h5 className="bt-footer m-3 text-decoration-underline">Privacy Policy | Terms of use</h5>
            </Col>
          </Row>
        </Container>
      </Container>
      </section>
      <section className="MobileFooter">
      <Container fluid className='newMobileFooterCont'>
            <Row className='newMobileFooterRow'>
                <Col onClick={()=>{setShow(true)}}>
                    <img src={Menu} alt="" />
                </Col>
                <Col>
                <img src={call} alt="" />
                </Col>
                <Col>
                <img src={bot} alt="" />
                </Col>
                <Col>
                <img src={whatsapp} alt="" />
                </Col>
            </Row>
        </Container>
        <MenuCanva show={show} handleClose={handleClose} />
      </section>
    </main>
    


  )
}
export default NewFooter