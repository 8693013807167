import React from 'react'
import Icon1 from '../../Assets/Image/home/Icons/01.svg'
import Icon2 from '../../Assets/Image/home/Icons/02.svg'
import Icon3 from '../../Assets/Image/home/Icons/03.svg'
import Icon4 from '../../Assets/Image/home/Icons/04.svg'
import Icon5 from '../../Assets/Image/home/Icons/05.svg'
import Icon6 from '../../Assets/Image/home/Icons/06.svg'
import Slider from 'react-slick'


const CareerJoin = () => {


    const setting = {
        centerMode: true,
        centerPadding: '5px',
        slidesToShow: 4,
        mobileFirst: true,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplayTimeout: 1000,
        responsive: [{
              breakpoint: 800,
              settings: {
                 slidesToShow: 2,
                 slidesToScroll: 1,
                 centerMode: true,
                 infinite: true,
              }
           },
           {
            breakpoint: 600,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               centerMode: true,
               infinite: true,
            }
         },
           {
              breakpoint: 1300,
              settings: {
                 slidesToShow: 3,
                 slidesToScroll: 1,
                 centerMode: true,
                 infinite: true,
              }
           }
        ]
  }



  return (
    <>
    <section className="container-fluid HireSection mt-5 mb-5">
         <div className="container">
            <div className=" row d-flex justify-content-center align-item-center text-center">
               <div className="col-lg-6 careerHead">
                  <h3 className="text-Heading circularMedium pt-2">Why you Should Join Our Awesome Team</h3>
                  <h6 className='nunitoMedium pt-2'>Companies to stay up-to-date and adapt to changes in order to remain successful in today's rapidly evolving world.</h6>
               </div>
            </div>
         </div>
         <div className="container mt-3">
            <div className="whoweare-slider-container">
               <Slider {...setting} className="whoweare-slider">
                  <div className="whoweare-slider-item HireCards whoweare-bgfull px-10 py-20">
                     <div className="slider-card-icon">
                        <img src={Icon1} alt="Icon"/>
                     </div>
                     <div className="p-3">
                        <h5 className='circularMedium pb-2'>Workdays</h5>
                        <p>Monday - Friday: 10 AM to 7 PM Saturday: Half Day</p>
                     </div>
                  </div>
                  <div className="whoweare-slider-item HireCards whoweare-bgfull">
                     <div className="slider-card-icon">
                        <img src={Icon2} alt="Icon"/>
                     </div>
                     <div className="p-3">
                        <h5 className='circularMedium pb-2'>Work-Location</h5>
                        <p>Anywhere with High Speed Internet On Site - Hybrid</p>
                     </div>

                  </div>
                  <div className="whoweare-slider-item HireCards whoweare-bgfull">

                     <div className="slider-card-icon">
                        <img src={Icon3} alt="Icon"/>
                     </div>
                     <div className="p-3">
                        <h5 className='circularMedium pb-2'>Leaves & Probation</h5>
                        <p>Leaves: 12 Days Annual Paid LeavesProbation: 6 Months</p>
                     </div>

                  </div>
                  <div className="whoweare-slider-item HireCards whoweare-bgfull">

                     <div className="slider-card-icon">
                        <img src={Icon4} alt="Icon"/>
                     </div>
                     <div className="p-3">
                        <h5 className='circularMedium pb-2'>Salary Cycle</h5>
                        <p>5th of every month</p>
                     </div>

                  </div>
                  <div className="whoweare-slider-item HireCards whoweare-bgfull">

                     <div className="slider-card-icon">
                        <img src={Icon5} alt="Icon"/>
                     </div>
                     <div className="p-3">
                        <h5 className='circularMedium pb-2'>Salary Appraisal</h5>
                        <p>Post 1 Year Completion</p>
                     </div>

                  </div>
                  <div className="whoweare-slider-item HireCards whoweare-bgfull">

                     <div className="slider-card-icon">
                        <img src={Icon6} alt="Icon"/>
                     </div>
                     <div className="p-3">
                        <h5 className='circularMedium pb-2'>Performance Evaluation</h5>
                        <p>Doing a Monthly Self Evaluation</p>
                     </div>

                  </div>

               </Slider>
            </div>
         </div>
         <div className="container">
            <div className="blockchain-content">
               <h4 className="text-blue">Slide to Learn more</h4>
            </div>
         </div>
      </section>
    </>
  )
}

export default CareerJoin