import React from 'react'
import '../../../Style/animation.css'
import NFTBanner from '../../../Assets/Image/animation/nft-banner.svg'
import art from '../../../Assets/Image/service-page/art-nft.webp'
import collectibles from '../../../Assets/Image/service-page/collectibles-nft.webp'
import gaming from '../../../Assets/Image/service-page/gaming-nft.webp'
import music from '../../../Assets/Image/service-page/music nft.webp'
import domain from '../../../Assets/Image/service-page/domain-nft.webp'
import real from '../../../Assets/Image/service-page/real-estate-nft.webp'
import sports from '../../../Assets/Image/service-page/sports-nft.webp'
import fashion from '../../../Assets/Image/service-page/fashion-nft.webp'
import content from '../../../Assets/Image/service-page/content-nft.webp'
import virtual from '../../../Assets/Image/service-page/virtual-nft.webp'
import Slider from "react-slick";
import arrow from "../../../Assets/Image/animation/arrow.png"
import GetArrow from "../../../Assets/Image/animation/GetArrow.svg"
import btnImage from "../../../Assets/Image/animation/btnbgimg.svg"
import token from '../../../Assets/Image/service-page/nft-token-development-service.webp'
import Popular from '../../../Assets/Image/service-page/nft-marketplace-platform.webp'
import genaral from '../../../Assets/Image/service-page/genaral-nft-carousal.gif'
import Specialized from '../../../Assets/Image/service-page/Specialized-nft-carousal.gif'
import blockchain from '../../../Assets/Image/service-page/blockchain-nft-carousal.gif'
import Auction from '../../../Assets/Image/service-page/Auction-NFT-carousal.gif'
import Defi from '../../../Assets/Image/service-page/Defi-nft-Marketplace-carousal.gif'
import Cross from '../../../Assets/Image/service-page/Cross-Platform-NFT-carousal.gif'
import Fractionalized from '../../../Assets/Image/service-page/Fractionalized-nft-carousal.gif'
import Dynamic from '../../../Assets/Image/service-page/Dynamic-nft-carousal.gif'
import Tokenization from '../../../Assets/Image/service-page/Tokenization-Crowd-Funding-carousal.gif'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';


import ProcessIcon1 from '../../../Assets/Image/service-page/nft-our-process-icon-1.webp'
import ProcessIcon2 from '../../../Assets/Image/service-page/nft-our-process-icon-2.webp'
import ProcessIcon3 from '../../../Assets/Image/service-page/nft-our-process-icon-3.webp'
import ProcessIcon4 from '../../../Assets/Image/service-page/nft-our-process-icon-4.webp'
import ProcessIcon5 from '../../../Assets/Image/service-page/nft-our-process-icon-5.webp'
import ProcessIcon6 from '../../../Assets/Image/service-page/nft-our-process-icon-6.webp'
import ProcessIcon7 from '../../../Assets/Image/service-page/nft-our-process-icon-7.webp'
import ProcessIcon8 from '../../../Assets/Image/service-page/nft-our-process-icon-8.webp'
import ContactForm from '../../../Components/ContactForm'

const options = {
  loop: true,
  margin: 10,
  dots: false,
  responsiveClass: true,
  autoplay: true,
  autoplayTimeout: 3000,
  autoplayHoverPause: true,
  responsive: {
      0: {
          items: 1,
          nav: true,
          loop: false
      },
      600: {
          items: 1,
          nav: true,
          loop: false
      },
      900: {
          items: 1.5,
          nav: true,
          loop: false
      },
      1000: {
          items: 2.5,
          nav: true,
          loop: false
      },
      1300: {
          items: 3,
          nav: true,
          loop: false
      }
  }
}

const settings = {
  centerMode: true,
  speed: 500,
  arrows: true,
  autoplay: true,
  autoplayTimeout: 3000,
  slidesToShow: 5,
  responsive: [{
    breakpoint: 800,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      infinite: true,
    },
  },
  {
    breakpoint: 450,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 300,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  ],
}


const NFT = () => {

  const [scrollToPos, setScrollToPos] = useState(0);

  const handleClick = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    const container = document.querySelector(".guide-answers");
    const containerTop = container.getBoundingClientRect().top;
    const currentScrollTop = container.scrollTop;
    const selectionTop = targetElement.getBoundingClientRect().top;

    setScrollToPos(selectionTop - containerTop + currentScrollTop);

    // Optionally, you can add smooth scrolling animation here
    container.scrollTo({
      top: scrollToPos,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the desired position when scrollToPos changes
    const container = document.querySelector(".guide-answers");
    container.scrollTo({
      top: scrollToPos,
      behavior: "smooth",
    });
  }, [scrollToPos]);


  useEffect(() => {
    const handleScroll = () => {
      let containerTop = document.getElementsByClassName("guide-answers")[0].getBoundingClientRect();
      let questionOne = document.getElementById("question-1").getBoundingClientRect();
      let questionTwo = document.getElementById("question-2").getBoundingClientRect();
      let questionThree = document.getElementById("question-3").getBoundingClientRect();
      let questionFour = document.getElementById("question-4").getBoundingClientRect();
      let questionFive = document.getElementById("question-5").getBoundingClientRect();
      let questionSix = document.getElementById("question-6").getBoundingClientRect();

      let topCondition = containerTop.top + containerTop.top / 2;

      if (questionOne.top < topCondition && questionOne.top > 0) {
        setActiveClass('#guideNav li a[href="#question-1"]');
      } else if (questionTwo.top < topCondition && questionTwo.top > 0) {
        setActiveClass('#guideNav li a[href="#question-2"]');
      } else if (questionThree.top < topCondition && questionThree.top > 0) {
        setActiveClass('#guideNav li a[href="#question-3"]');
      } else if (questionFour.top < topCondition && questionFour.top > 0) {
        setActiveClass('#guideNav li a[href="#question-4"]');
      } else if (questionFive.top < topCondition && questionFive.top > 0) {
        setActiveClass('#guideNav li a[href="#question-5"]');
      } else if (questionSix.top < topCondition && questionSix.top > 0) {
        setActiveClass('#guideNav li a[href="#question-6"]');
      }
    };

    const setActiveClass = (selector) => {
      document.getElementById("guideNav").querySelectorAll('li').forEach((li) => {
        li.classList.remove("active");
      });
      document.querySelector(selector).parentNode.classList.add("active");
    };

    document.getElementsByClassName("guide-answers")[0].addEventListener("scroll", handleScroll);

    return () => {
      document.getElementsByClassName("guide-answers")[0].removeEventListener("scroll", handleScroll);
    };
  }, []); 



  return (
    <main id="nft">
      <section className="banner">
        <div className="container-fluid">
          <div className="container bannerCont">
            <div className="row justify-content-between">
              <div className="col-lg-7 bannerLeft">
                <div>
                  <h1 className="text-white">
                    Your Gateway To NFT Marketplace Development Company
                  </h1>
                </div>
                <div>
                  <p className="h6 text-bannerpara nunitoMedium">
                    Unlock the Future of Digital Collectibles with Our NFT Development Services. Join the revolution!
                  </p>
                </div>
                <div className="animation-bannerBtn">
                  <a href="/contact"  target='_blank'>
                    <button className="whiteBtn bg-whiteButton text-smallHeading fw-bold">
                      Get Started on Your NFT Journey Today!
                    </button>
                  </a>
                </div>
              </div>
              <div className="col-lg-5 bannerRightImage">
                <p className="animation-banner">
                  <img src={NFTBanner} alt="" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="mt-5 pb-3 mb-5 video-explain ">
        <div className="container">
          <div className="row text-center d-flex justify-content-center align-items-center">
            <div className="col-lg-10">
              <h3 className="fw-bold BringHeading">
                Our Latest And Greatest NFT Token Development

              </h3>
              <p className="animation-text-para nunitoRegular">
                Non-Fungible Tokens (NFTs) are immutable digital assets or tokens that indicate ownership or proof of
                validity of a certain item, content, or digital property and are often kept on a distributed ledger called
                a blockchain technology. NFT development services are unique and cannot be exchanged on a one-to-one basis
                due to their uniqueness and particular qualities, unlike fungible cryptocurrencies like Bitcoin and
                Ethereum.
                NFTs (Non-Fungible tokens) can be grouped in a number of ways, depending on their traits and potential
                use. The following are some typical NFT marketplace development services done by Akkenna Animations and
                Technologies
              </p>
            </div>
          </div>
          <div className="row justify-content-center" id="explainer">
            <div className="">
              <div className="col-lg-12 technologyCards">
                <div className="col-lg-2 techCard">
                  <div className="frontCard">
                    <img src={art} alt="Software-Development" />
                    <div className="techCard-title">
                      <h6>Art NFT’s</h6>
                    </div>
                  </div>
                  <div className="backCard">
                    <h6>Art NFT’s </h6>
                    <p>
                      These are digital tokens representing digital or traditional artwork. Art NFTs have gained
                      significant
                      attention in the NFT space, with artists selling their creations as unique tokens.
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 techCard">
                  <div className="frontCard">
                    <img src={collectibles} alt="UI-UX-Designing" />
                    <div className="techCard-title">
                      <h6>Collectibles NFT’s
                      </h6>
                    </div>
                  </div>
                  <div className="backCard">
                    <h6>Collectibles NFT’s
                    </h6>
                    <p>
                      These tokens represent digital or physical collectibles, such as trading cards, rare in-game items,
                      or
                      virtual pets.
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 techCard">
                  <div className="frontCard">
                    <img src={gaming} alt="Web-Development" />
                    <div className="techCard-title">
                      <h6>Gaming NFT’s</h6>
                    </div>
                  </div>
                  <div className="backCard">
                    <h6>Gaming NFT’s
                    </h6>
                    <p>
                      In the gaming world, NFTs can represent in-game assets like characters, skins, weapons, or virtual
                      real estate.

                    </p>
                  </div>
                </div>
                <div className="col-lg-2 techCard">
                  <div className="frontCard">
                    <img src={music} alt="App-Development" />
                    <div className="techCard-title">
                      <h6>Music NFT’s</h6>
                    </div>
                  </div>
                  <div className="backCard">
                    <h6>Music NFT’s</h6>
                    <p>
                      Musicians and artists use NFTs to tokenize their music, albums, concert tickets, and exclusive
                      experiences.
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 techCard">
                  <div className="frontCard">
                    <img src={domain} alt="UI-UX-Designing" />
                    <div className="techCard-title">
                      <h6>Domain NFT’s</h6>
                    </div>
                  </div>
                  <div className="backCard">
                    <h6>Domain NFT’s</h6>
                    <p>
                      These NFTs represent ownership of domain names in the decentralized web (Web3).
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 techCard">
                  <div className="frontCard">
                    <img src={real} alt="Web-Development" />
                    <div className="techCard-title">
                      <h6>Real Estate NFT’s</h6>
                    </div>
                  </div>
                  <div className="backCard">
                    <h6>Real Estate NFT’s</h6>
                    <p>
                      These tokens represent ownership or access rights to virtual land and property within virtual worlds
                      or metaverse environments.
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 techCard">
                  <div className="frontCard">
                    <img src={sports} alt="App-Development" />
                    <div className="techCard-title">
                      <h6>Sports NFT’s</h6>
                    </div>
                  </div>
                  <div className="backCard">
                    <h6>Sports NFT’s </h6>
                    <p>
                      Athletes, sports teams, and organizations issue NFTs for collectible cards, exclusive content, and
                      memorabilia.
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 techCard">
                  <div className="frontCard">
                    <img src={fashion} alt="Software-Development" />
                    <div className="techCard-title">
                      <h6>Fashion NFT’s
                      </h6>
                    </div>
                  </div>
                  <div className="backCard">
                    <h6>Fashion NFT’s
                    </h6>
                    <p>
                      Fashion brands create NFTs to tokenize unique clothing designs, wearables, and accessories.

                    </p>
                  </div>
                </div>
                <div className="col-lg-2 techCard">
                  <div className="frontCard">
                    <img src={content} alt="UI-UX-Designing" />
                    <div className="techCard-title">
                      <h6>Content NFT’s
                      </h6>
                    </div>
                  </div>
                  <div className="backCard">
                    <h6>Content NFT’s
                    </h6>
                    <p>
                      NFTs for written content, like articles, books, or blog posts, provide ownership and monetization
                      opportunities for creators.

                    </p>
                  </div>
                </div>
                <div className="col-lg-2 techCard">
                  <div className="frontCard">
                    <img src={virtual} alt="Web-Development" />
                    <div className="techCard-title">
                      <h6>Virtual Goods NFT’s
                      </h6>
                    </div>
                  </div>
                  <div className="backCard">
                    <h6>Virtual Goods NFT’s
                    </h6>
                    <p>
                      These represent virtual items within applications, like avatars, stickers, or emojis for use in
                      virtual communication platforms.

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>


      <section className="container-fluid animation hireLabelTag pt-4 pb-4">
        <div className="container">
          <div className="row justify-content-center align-items-center">

            <div className="col-md-5 col-lg-5 HireHeading text-right">
              <h4 className="text-white">Have a Project in your Mind</h4>
              <h4 className="text-white" style={{ display: "none" }}>
                Hire the world’s best developers and designers around!
              </h4>
            </div>
            <div className="col-md-3 col-lg-3 hire-arrowImg text-center">
              <img src={arrow} alt="arrow" />
            </div>
            <div className="col-md-4 col-lg-4 hirePosition text-center">
              <img src={btnImage} alt="" style={{ display: "none" }} />
              <div className="hirebtnbg hirePositionRelatbutton">
                <a href="/contact"  target='_blank'>
                  <button className="whiteBtn bg-whiteButton text-smallHeading fw-bold text-center hireBtn">
                    Hire Akkennian's
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="container-fluid mt-5 mb-5 process-section">
        <div className="container">
          <div className="row text-center d-flex justify-content-center align-items-center">
            <div className="col-md-10">
              <div className="process-title">
                <p>Our Process Of NFT Token Development And Minting </p>
              </div>
            </div>
            <div className="col-md-10 process-description nunitoRegular">
              <div className="">
                <p>
                  It is vital to have a solid understanding of the process in order to properly tokenize your digital
                  assets. Creating and minting NFT marketplace development services includes a number of stages, and it is
                  important to have this knowledge.

                </p>
              </div>
            </div>
          </div>
          <div className="row experience-details d-flex justify-content-center align-items-center mb-3">
            <div className="col-lg-11 processCard">
              <Slider {...settings} className="row ">
                <div className="col-lg-1 col-md-1 centered-div">

                  <div className="content">
                    <p className="rounded-circle search">
                      <img src={ProcessIcon1} className="rounded-circles" alt="" />
                    </p>
                    <label>Conceptualization</label>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 centered-div">
                  <div className="content">
                    <p className="rounded-circle bulb">
                      <img src={ProcessIcon2} className="rounded-circles" alt="" />
                    </p>
                    <label>Content Preparation
                    </label>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 centered-div">
                  <div className="content">
                    <p className="rounded-circle people">
                      <img src={ProcessIcon3} className="rounded-circles" alt="" />
                    </p>
                    <label>Blockchain Selection</label>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 centered-div">
                  <div className="content">
                    <p className="rounded-circle time">
                      <img src={ProcessIcon4} className="rounded-circles" alt="" />
                    </p>
                    <label>Minting
                    </label>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 centered-div">
                  <div className="content">
                    <p className="rounded-circle settings">
                      <img src={ProcessIcon5} className="rounded-circles" alt="" />
                    </p>
                    <label>Metadata Enhancement</label>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 centered-div">
                  <div className="content">
                    <p className="rounded-circle settings">
                      <img src={ProcessIcon6} className="rounded-circles" alt="" />
                    </p>
                    <label>Smart Contracts
                    </label>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 centered-div">
                  <div className="content">
                    <p className="rounded-circle settings">
                      <img src={ProcessIcon7} className="rounded-circles" alt="" />
                    </p>
                    <label>Security and Compliance
                    </label>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 centered-div">
                  <div className="content">
                    <p className="rounded-circle settings">
                      <img src={ProcessIcon8} className="rounded-circles" alt="" />
                    </p>
                    <label>Marketplace Integration

                    </label>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid pt-3 pb-3 mt-3 bgVoilet experience-section">
        <div className="container">
          <div className="row text-center d-flex justify-content-center align-items-center">
            <div className="col-md-10 experience-title">
              <p className='text-bannerpara'>The Importance Of Choosing Our NFT Token Development Service
              </p>
            </div>
          </div>
          <div className="row text-center d-flex justify-content-center align-items-center">
            <div className="col-md-10 experience-description">
              <p className='text-white'>
                Selecting the right white label NFT marketplace development company in India is crucial, and its
                importance cannot be overstated. Here are several key reasons why choosing the right NFT marketplace
                development company is essential

              </p>
            </div>
          </div>
          <div className="row experience-image pt-3 mb-3">
            <div className="col-md-5">
              <div className="experience-banner box-3d">
                <img src={token} alt="" />
              </div>
            </div>
            <div className="col-md-7 nunitoRegular">
              <div className="row experience-content">
                <div className="col-md-12 pb-3">
                  <p className="experience-subtitle">
                    Expertise and Guidance

                  </p>
                  <p className="experience-description">
                    As a group, we are well-versed in the subtleties of NFT development, blockchain technology, and the
                    NFT industry. Our detailed instructions will help you manufacture and mint your NFT with user
                    friendly.
                  </p>
                </div>
                <div className="col-md-12 pb-3">
                  <p className="experience-subtitle">Time Efficiency
                  </p>
                  <p className="experience-description">
                    By choosing our service, you save a lot of time and effort. Navigating the NFT ecosystem can be hard,
                    so we simplify the process for you so you can focus on your artistic work.
                  </p>
                </div>
                <div className="col-md-12 pb-3">
                  <p className="experience-subtitle">Security and Compliance</p>
                  <p className="experience-description">
                    We put the safety of your digital assets first and make sure they meet legal and regulatory standards.
                    This reduces the risks that could come with creating NFTs.
                  </p>
                </div>
                <div className="col-md-12 pb-3">
                  <p className="experience-subtitle">Optimized Visibility
                  </p>
                  <p className="experience-description">
                    We make your NFT more visible by using our knowledge of how to integrate it into marketplaces and
                    promote it. This makes it more likely that buyers and fans will be interested in it.
                  </p>
                </div>
                <div className="col-md-12 pb-3">
                  <p className="experience-subtitle">Customization

                  </p>
                  <p className="experience-description">
                    No matter if you are an artist, a musician, a gamer, or a content maker, we can help you to create
                    your own NFT marketplace. Our NFT marketplace development services can be changed to fit the needs of
                    many different creative businesses.

                  </p>
                </div>
              </div>
              <div className="animation-bannerBtn">
                <a href="/contact"  target='_blank'>
                  <button className="whiteBtn bg-whiteButton text-smallHeading fw-bold">
                    Create Your Own NFTs Now
                  </button>
                </a>
              </div>

            </div>
          </div>
        </div>

      </section>


      <section className="container-fluid pt-3 experience2-section">
        <div className="container">
          <div className="row text-center d-flex justify-content-center align-items-center">
            <div className="col-md-10 experience-title">
              <p>Popular NFT Marketplace Platforms We Use</p>

            </div>
          </div>

          <div className="row experience-image pt-3 mb-1">
            <div className="col-md-7 nunitoRegular">
              <div className="row experience-content">
                <div className="col-md-12 pb-3">
                  <p className="experience-subtitle">Open Sea </p>
                  <p className="experience-description">
                    OpenSea is one of the biggest and most well-known NFT marketplaces. It has a simple interface that
                    makes it easy for both producers and collectors to use.

                  </p>
                </div>
                <div className="col-md-12 pb-3">
                  <p className="experience-subtitle">Rarible </p>
                  <p className="experience-description">
                    Rarible is a decentralized platform that lets you create and mint NFTs with special features, like
                    government tokens.

                  </p>
                </div>
                <div className="col-md-12 pb-3">
                  <p className="experience-subtitle">Mintable</p>
                  <p className="experience-description">
                    Mintable makes making NFTs easy and straightforward, and it has choices for gas-free minting
                    onTransforming Ideas into NFT Reality: Akkenna's Premier NFT Marketplace Development Service. Discover
                    the Future of Digital Asset Trading with Us. layer 2 solutions.
                  </p>
                </div>
                <div className="col-md-12 pb-3">
                  <p className="experience-subtitle">Etherium and Binance Smart Chain </p>
                  <p className="experience-description">
                    We use the Ethereum and Binance Smart Chain blockchains, which are widely used to make NFTs because
                    they have strong infrastructures and ecosystems behind them.

                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="experience-banner box-3d">
                <img src={Popular} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid pt-3 pb-3 mt-5 explainer-section">
        <div className="container-fluid">
          <div className="row text-center d-flex justify-content-center align-item-center">
            <div className="col-md-10 explainer-title">
              <p>Types Of NFT Development Marketplace
              </p>
            </div>
          </div>
          <div className="row text-center d-flex justify-content-center align-item-center">
            <div className="col-md-10 explainer-description nunitoRegular">
              <p>
                To make an NFT (Non-Fungible Token) store, you need to set up a website where people can buy, sell, and
                trade NFTs. Different NFT platforms have different features, blockchain technology, and ways for users to
                interact with them. Here are some types of changes in the NFT market.

              </p>
            </div>
          </div>
          <div className="row text-center d-flex justify-content-center align-item-center">
            <div className="col-md-12 explain-carousel">
              <OwlCarousel className='owl-theme ' loop margin={10} nav {...options}>
                <div className="item">
                  <div className="item-image">
                    <img src={genaral} alt="" />
                  </div>
                  <div className="item-title">
                    <p>General NFT Marketplaces</p>
                  </div>
                  <div className="item-description">
                    <p>
                      Users can buy, sell, and trade art, collectibles, music, and more on these platforms.
                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src={GetArrow} />
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={Specialized} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Specialized NFT Marketplaces
                    </p>
                  </div>
                  <div className="item-description">
                    <p>
                      Some marketplaces specialize in art, music, gaming, virtual real estate, or domain names.

                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src={GetArrow} />
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={blockchain} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Blockchain-Specific Marketplaces
                    </p>
                  </div>
                  <div className="item-description">
                    <p>
                      Ethereum, Binance Smart Chain, Flow, and others can power NFT marketplaces.Add powerup blockchain in between power and nft.
                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src={GetArrow} />
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={Auction} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Auction NFT Marketplaces
                    </p>
                  </div>
                  <div className="item-description">
                    <p>
                      Auction platforms let users bid on NFTs, and the highest bidder wins. Bidder wins in auction nft marketplaces.

                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src={GetArrow} />
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={Defi} alt="" />
                  </div>
                  <div className="item-title">
                    <p>DeFi NFT Marketplaces

                    </p>
                  </div>
                  <div className="item-description">
                    <p>
                      DeFi NFT marketplaces let users lend, borrow, stake, and earn interest on NFT assets.

                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src={GetArrow} />
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={Cross} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Cross-Platform NFT Marketplace </p>
                  </div>
                  <div className="item-description">
                    <p>
                      These platforms allow NFT trading between blockchains, facilitating interoperability and asset
                      transfers.
                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src={GetArrow} />
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={Fractionalized} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Fractionalized NFT Marketplaces

                    </p>
                  </div>
                  <div className="item-description">
                    <p>
                      These platforms enable users to buy, sell, or trade fractions of high-value NFTs, increasing
                      accessibility.

                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src={GetArrow} />
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={Dynamic} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Dynamic Pricing NFT Marketplaces

                    </p>
                  </div>
                  <div className="item-description">
                    <p>
                      Dynamics like bonding curves dictate NFT prices in some markets based on supply and demand.

                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src={GetArrow} />
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={Tokenization} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Tokenization & Crowdfunding NFT’s
                    </p>
                  </div>
                  <div className="item-description">
                    <p>
                      Real estate, art, and investment opportunities are tokenized on these marketplaces, allowing users to
                      invest in fractions.
                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src={GetArrow} />
                      </span>
                    </p>
                  </div>
                </div>
              </OwlCarousel>

            </div>

          </div>
        </div>
      </section>


      <Container fluid className='mt-5 mb-5'>
        <Container>
          <Row>
            <div className="row text-center d-flex justify-content-center align-item-center">
              <div className="col-md-10 guide-title">
                <p>NFT Marketplace Development</p>
              </div>
            </div>
            <div className="row text-center d-flex justify-content-center align-item-center">
              <div className="col-md-10 guide-description nunitoRegular">
                <p>
                  The creation of a digital marketplace that allows users to purchase, sell, and trade non-fungible tokens
                  (NFTs) is required in order to develop a marketplace for non-fungible tokens (NFTs). NFT marketplaces can
                  vary greatly from one another in terms of the features they offer, the blockchain technology they use, and
                  the user experience they provide.

                </p>
              </div>
            </div>

            <Col lg={12} className='d-flex mt-3'>
              <Col lg={4} className="guide-menu">
                <div className="guide-topics">
                  <p>NFT Marketing and Promotion</p>
                </div>
                <ul className="nav" id="guideNav">
                  <li onClick={(e) => handleClick(e, "question-1")} className="active">
                    <a href="#question-1">NFT Marketing and Promotion</a>
                  </li>
                  <li onClick={(e) => handleClick(e, "question-2")}>
                    <a href="#question-2">How Akkenna Assists in Promoting NFTs?</a>
                  </li>
                  <li onClick={(e) => handleClick(e, "question-3")}>
                    <a href="#question-3">Importance Of NFT Analytics and Management ?</a>
                  </li>
                  <li onClick={(e) => handleClick(e, "question-4")}>
                    <a href="#question-4">NFT Token Standards</a>
                  </li>
                  <li onClick={(e) => handleClick(e, "question-5")}>
                    <a href="#question-5">Key Features & Use Cases Of ERC - 721</a>
                  </li>
                  <li onClick={(e) => handleClick(e, "question-6")}>
                    <a href="#question-6">Key Features & Use Cases Of ERC - 1155</a>
                  </li>
                </ul>
              </Col>
              <Col lg={8} className="guide-answers">
              <div id="question-1" className="guide-details">
                <p className="guide-sub-question">
                  NFT Marketing and Promotion

                </p>
                <p className="guide-sub-answer">
                  Importance of Marketing NFT<br />
                  Effective marketing and promotion are crucial to the success of non-profit organizations for a number
                  of reasons, including the following:
                  <li>Visibility
                  </li>
                  <li>Attracting Buyers</li>
                  <li>Brand Building</li>
                  <li>Price Appreciation
                  </li>

                </p>

                <p className="guide-sub-answer">
                  Marketing Strategies Used By Us<br />
                  At Akkenna Animation and Technologies Private Limited, we use a variety of different tactics for
                  marketing NFT development services in order to maximize the visibility and desirability of your NFTs,
                  including the following:

                  <li>Social Media Campaigns

                  </li>
                  <li>Influencer Collaboration
                  </li>
                  <li>Community Engagement</li>
                  <li>Email Marketing
                  </li>
                  <li>Airdrops and Giveaways
                  </li>
                  <li>Exclusive Events
                  </li>

                </p>


              </div>
              <div id="question-2" className="guide-details">
                <p className="guide-sub-question">
                  How Akkenna Assists in Promoting NFTs?

                </p>
                <p className="guide-sub-answer">
                  <li>Strategy Development</li>
                  We tailor a marketing plan to your NFTs' unique attributes and target audience..
                  <br />
                  <li>Content Creation</li>
                  Our team creates captivating artwork, logos, and video trailers to promote your NFTs and attract
                  consumers.
                  <br />
                  <li> Campaign Execution</li>
                  We run marketing campaigns across platforms to give your NFTs a strong presence.
                  <br />
                  <li> Analytics and Campaigns</li>
                  We track marketing results to improve strategies.
                  <br />
                  <li>Community Building</li>
                  We organize NFT communities with conversations, Q&As, and member-only access.
                  <br />
                  <li>Collaboration Management</li>
                  Negotiations, alliances, and campaign management for influencer collaborations


                </p>


              </div>
              <div id="question-3" className="guide-details">
                <p className="guide-sub-question">Importance Of NFT Analytics and Management ?
                </p>
                <p className="guide-sub-answer">
                  It is impossible to emphasize the significance of NFT analytics and optimization in the field of
                  Non-Fungible Tokens (NFTs). To illustrate how important these factors really are, consider the
                  following
                  <li>Performance Evaluation
                  </li>
                  <li>Market Trends</li>
                  <li>Pricing Strategy

                  </li>
                  <li>Portfolio Optimization
                  </li>
                  <li> Content Strategy</li>
                  <li> Promotion and Marketing
                  </li>
                  <li>Community Engagement

                  </li>
                  <li> Security
                  </li>
                  <li> Secondary Market Insights
                  </li>
                  <li>Continuous Improvements</li>
                </p>


              </div>
              <div id="question-4" className="guide-details">
                <p className="guide-sub-question">
                  NFT Token Standards

                </p>
                <p className="guide-sub-answer">
                  NFT (Non-Fungible Token) standards are a set of specifications and rules that specify how NFTs are created, kept, and interact within blockchain networks. These standards ensure that NFTs are interoperable and can be recognised and used by a variety of applications, wallets, and marketplaces. ERC-721 and ERC-1155, which are based on the Ethereum blockchain, are two of the most extensively used NFT standards. Here's an overview of these NFT token standards

                </p>

                <p className="guide-sub-answer">
                  Outside of Ethereum, there are numerous more NFT standards and protocols, such as Flow's Flow Fungible Token (FFT), Binance Smart Chain's BEP-721, and many more. These standards have many implementations and might provide special features or traits to accommodate various blockchain ecosystems and use cases.
                </p>

                <p className="guide-sub-answer">
                  The needs of your project, the blockchain platform you're employing, and the kind of digital or physical assets you're tokenizing will determine which NFT standard to use. The NFT environment has been significantly shaped by ERC-721 and ERC-1155, but the market is continually changing as new standards and advancements come forth as the technology develops.

                </p>
              </div>
              <div id="question-5" className="guide-details">
                <p className="guide-sub-question">
                  Key Features & Use Cases Of ERC - 721
                </p>
                <p className="guide-sub-question">
                  Name
                </p>

                <p className="guide-sub-answer">
                  Ethereum Request for Comment 721

                </p>
                <p className="guide-sub-question">
                  Properties
                </p>

                <p className="guide-sub-answer">
                  ERC-721 is a standard for creating NFTs with unique characteristics, making it suitable for representing one-of-a-kind digital or physical assets.


                </p>
                <p className="guide-sub-question">
                  Key Features

                </p>
                <p className="guide-sub-answer">
                  <li>Each token within the contract is distinct and non-fungible.
                  </li>
                  <li>Every token ID is unique and cannot be replicated.</li>
                  <li>ERC-721 tokens are most commonly used for digital art, collectibles, and unique items.
                  </li>
                </p>

                <p className="guide-sub-question">
                  Use Cases:

                </p>
                <p className="guide-sub-answer">
                  <li>Crypto Kitties, a popular blockchain-based game, uses ERC-721 tokens to represent unique digital cats.

                  </li>
                  <li>Art marketplaces often employ ERC-721 for selling unique digital art pieces.</li>

                </p>
              </div>
              <div id="question-6" className="guide-details">
                <p className="guide-sub-question">
                  Key Features & Use Cases Of ERC-1155

                </p>
                <p className="guide-sub-question">
                  Name
                </p>

                <p className="guide-sub-answer">
                  Ethereum Request for Comment 1155


                </p>
                <p className="guide-sub-question">
                  Properties
                </p>

                <p className="guide-sub-answer">
                  ERC-1155 is a more flexible and versatile standard that allows for the creation of both fungible and non-fungible tokens within a single contract. It's often seen as an upgrade from ERC-20 and ERC-721.


                </p>
                <p className="guide-sub-question">
                  Key Features

                </p>
                <p className="guide-sub-answer">
                  <li>A single contract can mint both fungible and non-fungible tokens.

                  </li>
                  <li>ERC-1155 tokens are useful for representing items with varying scarcity, like trading cards with different rarities.
                  </li>

                </p>

                <p className="guide-sub-question">
                  Use Cases

                </p>
                <p className="guide-sub-answer">
                  <li>Enjin, a blockchain gaming platform, uses ERC-1155 tokens to represent in-game items and assets of varying rarities.


                  </li>
                  <li>This standard is ideal for use in blockchain gaming and virtual worlds where both unique and interchangeable assets coexist.
                  </li>

                </p>
              </div>
              </Col>
            </Col>
          </Row>
        </Container>

      </Container>


      {

<ContactForm />

}





      <section>
        <div className="homeFaq">
          <div className="container">
            <div className="genaral-title">
              <div className="row d-flex justify-content-center align-item-center">
                <div className="col-md-10 text-center">
                  <h3 className="fw-bold">We Bring People & Technology Together</h3>
                  <p>
                    Together, we can design and build the most cutting-edge digital
                    products.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion accordion-faq" id="accordionfaqExample">
              <div className="row d-flex justify-content-center align-item-center">
                <div className="col-md-6">
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
                        What is the pricing structure for your NFT development services?
                      </button>
                    </h3>
                    <div id="faq-collapseThree" className="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">
                        Our prices change depending on the particular services that you need and the intricacy of the
                        project that you're working on. During the initial marketplace development we will present you
                        with an open and honest price structure.

                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingOne">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                        Do I need technical knowledge or experience with blockchain to use your NFT services?
                      </button>
                    </h3>
                    <div id="faq-collapseOne" className="accordion-collapse collapse" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">
                        No, deep technological knowledge is not required. Our development team will walk you through the
                        process and take care of the technical parts, so even people who have never used blockchain
                        technology before can do it.

                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
                        How do you ensure the security of my NFTs and comply with legal requirements?
                      </button>
                    </h3>
                    <div id="faq-collapseTwo" className="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">
                        We put security first by giving you choices for safe storage and using the best practices in the
                        industry. We also help you understand legal issues and make sure you follow the rules.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFour" aria-expanded="false" aria-controls="faq-collapseFour">
                        What types of NFT services do you offer?

                      </button>
                    </h3>
                    <div id="faq-collapseFour" className="accordion-collapse collapse" aria-labelledby="faq-headingFour" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">
                        We offer a wide range of services, such as making and minting NFTs, integrating them into
                        marketplaces, storing and protecting them, marketing and promoting them, and managing and
                        analyzing NFTs.

                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFive" aria-expanded="false" aria-controls="faq-collapseFive">
                        What is an NFT service, and how can it benefit me as a customer?

                      </button>
                    </h3>
                    <div id="faq-collapseFive" className="accordion-collapse collapse" aria-labelledby="faq-headingFive" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">
                        At Akkenna, we provide a range of Non-Fungible Token (NFTs) related services. It can help you by
                        providing knowledge and direction in the intricate NFT ecosystem, which will enable you to
                        properly construct, manage, market, and trade NFTs.

                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingSix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseSix" aria-expanded="false" aria-controls="faq-collapseSix">
                        Can you help me turn my digital content into NFTs, and how does this process work?

                      </button>
                    </h3>
                    <div id="faq-collapseSix" className="accordion-collapse collapse" aria-labelledby="faq-headingSix" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">
                        Yes, we can help you make NFTs from your digital material. We'll help you pick the right
                        blockchain, get your content ready, mint the NFT, and add information to make it more valuable
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default NFT