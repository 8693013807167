import '../../../Style/animation.css'
// import '../../../Style/NewAnimation.css'
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Slider from "react-slick";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import BannerImg from '../../../Assets/Image/animation/3d-banner.svg'
import animation3D1 from '../../../Assets/Image/animation/animation_3d-1.svg'
import animation3D2 from '../../../Assets/Image/animation/animation_3d-2.svg'
import ProcessIcon1 from '../../../Assets/Image/service-page/1-visual-appeal-3d.svg'
import ProcessIcon2 from '../../../Assets/Image/service-page/2-realism-3d.svg'
import ProcessIcon3 from '../../../Assets/Image/service-page/3-complexity-3d.svg'
import ProcessIcon4 from '../../../Assets/Image/service-page/4-creativity-3d.svg'
import ProcessIcon5 from '../../../Assets/Image/service-page/5-consistensy-3d.svg'
import ProcessIcon6 from '../../../Assets/Image/service-page/6-cost-3d.svg'
import ProcessIcon7 from '../../../Assets/Image/service-page/7-ability-3d.svg'
import ProcessIcon8 from '../../../Assets/Image/service-page/8-learning-3d.svg'
import ProcessIcon9 from '../../../Assets/Image/service-page/9-brand-3d.svg'
import ProcessIcon10 from '../../../Assets/Image/service-page/10-prototyping-3d.svg'
import ProcessIcon11 from '../../../Assets/Image/service-page/11-impact-3d.svg'
import ProcessIcon12 from '../../../Assets/Image/service-page/12-data-3d.svg'
import Explain from '../../../Assets/Image/service-page/explain.gif'
import Product from '../../../Assets/Image/service-page/product.gif'
import animation3d from '../../../Assets/Image/service-page/3d-animation.gif'
import Presentation from '../../../Assets/Image/service-page/presentation-gif-11.gif'
import Advertising from '../../../Assets/Image/service-page/advertising.gif'
import FilmEditing from '../../../Assets/Image/service-page/film editing.gif'
import ContactForm from '../../../Components/ContactForm';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const options = {
    loop: true,
    dots: false,
    margin: 10,
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
        0: {
            items: 1,
            nav: true,
            loop: false
        },
        600: {
            items: 1,
            nav: true,
            loop: false
        },
        900: {
            items: 1.5,
            nav: true,
            loop: false
        },
        1000: {
            items: 2.5,
            nav: true,
            loop: false
        },
        1300: {
            items: 3,
            nav: true,
            loop: false
        }
    }
}


const settings = {
    centerMode: true,
    speed: 500,
    arrows: true,
    autoplay: true,
    autoplayTimeout: 3000,
    slidesToShow: 5,
    responsive: [{
        breakpoint: 800,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            infinite: true,
        },
    },
    {
        breakpoint: 450,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
        },
    },
    {
        breakpoint: 300,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
        },
    },
    ],
}


const Animation3D = () => {

    const [scrollToPos, setScrollToPos] = useState(0);

    const handleClick = (e, targetId) => {
        e.preventDefault();
        const targetElement = document.getElementById(targetId);
        const container = document.querySelector(".guide-answers");
        const containerTop = container.getBoundingClientRect().top;
        const currentScrollTop = container.scrollTop;
        const selectionTop = targetElement.getBoundingClientRect().top;

        setScrollToPos(selectionTop - containerTop + currentScrollTop);

        // Optionally, you can add smooth scrolling animation here
        container.scrollTo({
            top: scrollToPos,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        // Scroll to the desired position when scrollToPos changes
        const container = document.querySelector(".guide-answers");
        container.scrollTo({
            top: scrollToPos,
            behavior: "smooth",
        });
    }, [scrollToPos]);


    useEffect(() => {
        const handleScroll = () => {
            let containerTop = document.getElementsByClassName("guide-answers")[0].getBoundingClientRect();
            let questionOne = document.getElementById("question-1").getBoundingClientRect();
            let questionTwo = document.getElementById("question-2").getBoundingClientRect();
            let questionThree = document.getElementById("question-3").getBoundingClientRect();
            let questionFour = document.getElementById("question-4").getBoundingClientRect();

            let topCondition = containerTop.top + containerTop.top / 2;

            if (questionOne.top < topCondition && questionOne.top > 0) {
                setActiveClass('#guideNav li a[href="#question-1"]');
            } else if (questionTwo.top < topCondition && questionTwo.top > 0) {
                setActiveClass('#guideNav li a[href="#question-2"]');
            } else if (questionThree.top < topCondition && questionThree.top > 0) {
                setActiveClass('#guideNav li a[href="#question-3"]');
            } else if (questionFour.top < topCondition && questionFour.top > 0) {
                setActiveClass('#guideNav li a[href="#question-4"]');
            }
        };

        const setActiveClass = (selector) => {
            document.getElementById("guideNav").querySelectorAll('li').forEach((li) => {
                li.classList.remove("active");
            });
            document.querySelector(selector).parentNode.classList.add("active");
        };

        // Attach scroll event listener
        document.getElementsByClassName("guide-answers")[0].addEventListener("scroll", handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            document.getElementsByClassName("guide-answers")[0].removeEventListener("scroll", handleScroll);
        };
    }, []); // Empty dependency array ensures that this effect runs once on mount

    return (
        <HelmetProvider>
        <Helmet>
                <title>Professional 2D and 3D Explainer Video Services in India | Akkenna</title>
                <meta name="description"
                    content="Transform your ideas into captivating visuals with Akkenna's expert 2D and 3D explainer video services in India. Elevate your brand and more" />
                <meta name="keywords"
                    content="explainer video services in india, 2d and 3d animation service,  2d and 3d animated explainer video service, 3D animation services in india" />
            </Helmet>
            <main id="animation_3d">
                <section className="banner">
                    <div className="container-fluid">
                        <div className="container bannerCont">
                            <div className="row justify-content-between">
                                <div className="col-lg-7 bannerLeft">
                                    <div>
                                        <h5 className="text-violet fw-bold mb-0">Elevate Your Imagination</h5>
                                    </div>
                                    <div>
                                        <h1 className="text-white">
                                            Leading 3D Animation Company in India
                                        </h1>
                                    </div>
                                    <div>
                                        <p className="h6 text-bannerpara nunitoMedium">
                                            Searching for a 3D animation production company in India? We specialize in crafting beloved, high-quality 3D animation videos.
                                        </p>
                                    </div>
                                    <div className="animation-bannerBtn">
                                        <a href="/contact"  target='_blank'>
                                            <button className="whiteBtn bg-whiteButton text-smallHeading fw-bold">
                                                Request Quote
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-5 bannerRightImage">
                                    <p className="animation-banner">
                                        <img src={BannerImg} alt="" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <Container fluid className='mt-5 mb-5 video-explain'>
                    <div className="container">
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-lg-10">
                                <h3 className="fw-bold BringHeading">
                                    Styles Of 3D Animated Videos
                                </h3>
                                <p className="animation-text-para nunitoRegular">
                                    Whenever we work on a 3D animation project, it's like telling a different story with lifelike movements and realism. Our skilled animators ensure each project stands out with its unique style.
                                </p>
                            </div>
                        </div>
                    </div>
                    <Container>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row className='d-flex flex-column justify-content-center align-items-center' >
                                <Col lg={9} className="row text-center d-flex justify-content-center align-items-center video-explainer">
                                    <Nav variant="pills" className='d-flex justify-content-center align-items-center' >
                                        <Nav.Item className="col-md-4 video-explainer-title explainer">
                                            <Nav.Link eventKey="first" className="video-explainer-text">Interactive 3D Animation Videos</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="col-md-4 video-explainer-title whiteboard">
                                            <Nav.Link eventKey="second" className="video-explainer-text">Virtual Reality 3D Animation videos</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="col-md-4 video-explainer-title live">
                                            <Nav.Link eventKey="third" className="video-explainer-text">Stop Motion 3D Animation Videos</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col lg={10}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first" className="row justify-content-center video-cards">
                                            <div className="technologyCards">
                                                <div>
                                                    <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/KmSSQveJ258?si=4zC6ZAHjwH7xCitx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                </div>
                                                <div>
                                                    <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/8XST11KhL7k?si=5gimrlerw1JRIYWM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second" className="row justify-content-center video-cards">
                                            <div className="technologyCards">
                                                <div>
                                                    <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/4xwQpzeiHLE?si=G80kQjXKgZ2gWGO-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                </div>
                                                <div>
                                                    <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/FDZVGJIM0W8?si=G_TcVgsO77bNWKEV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <div className="technologyCards">
                                                <div>
                                                    <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/AEEWRfU4Er8?si=Dc3T4uw07ufaCUOb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                </div>
                                                <div>
                                                    <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/bBP4HskFDBg?si=3ckJAehRW0FvB2_8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </Container>

                <section className="container-fluid animation hireLabelTag pt-4 pb-4">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-5 col-lg-5 HireHeading text-right">
                                <h4 className="text-white">Craft Outstanding 3D Animations For Your Business</h4>
                                <h4 className="text-white" style={{ display: "none" }}>
                                    Craft Outstanding 3D Animations For Your Business </h4>
                            </div>
                            <div className="col-md-3 col-lg-3 hire-arrowImg text-center">
                                <img src={arrow} alt="arrow" />
                            </div>
                            <div className="col-md-4 col-lg-4 hirePosition text-center">
                                <img src="assets/image/animation/btnbgimg.svg" alt="" style={{ display: "none" }} />
                                <div className="hirebtnbg hirePositionRelatbutton">
                                    <a href="/contact"  target='_blank'>
                                        <button className="whiteBtn bg-whiteButton text-smallHeading fw-bold text-center hireBtn">
                                            Have Questions?
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}


                <section className="container-fluid mt-5 mb-5 process-section">
                    <div className="container">
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-md-10">
                                <div className="process-title">
                                    <p>Why Choose Our 3D Animation Services In India?</p>
                                </div>
                            </div>
                            <div className="col-md-10 process-description nunitoRegular">
                                <div className="">
                                    <p>
                                        Akkenna Animations and Technologies is the best 3D animation company in india.' Our core expertise revolves around creating realism, simplifying complexity, and maintaining audience engagement. This proficiency enables us to excel in storytelling, conveying intricate concepts, and presenting products with creativity and impact.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row experience-details d-flex justify-content-center align-items-center mb-3">
                            <div className="col-lg-11 processCard">
                                <Slider {...settings} className="row ">
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle search">
                                                <img src={ProcessIcon1} alt="" />
                                            </p>
                                            <label>Visual Appeal</label>
                                            <label style={{ display: "none" }}>Visual Appeal</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle bulb">
                                                <img src={ProcessIcon2} alt="" />
                                            </p>
                                            <label>Realism</label>
                                            <label style={{ display: "none" }}>Realism</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle people">
                                                <img src={ProcessIcon3} alt="" />
                                            </p>
                                            <label>Simplifying Complexity</label>
                                            <label style={{ display: "none" }}>Simplifying Complexity</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle time">
                                                <img src={ProcessIcon4} alt="" />
                                            </p>
                                            <label>Creativity</label>
                                            <label style={{ display: "none" }}>Creativity</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle settings">
                                                <img src={ProcessIcon5} alt="" />
                                            </p>
                                            <label>Consistency</label>
                                            <label style={{ display: "none" }}>Consistency</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle settings">
                                                <img src={ProcessIcon6} alt="" />
                                            </p>
                                            <label>Cost-Effective</label>
                                            <label style={{ display: "none" }}>Cost-Effective</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle search">
                                                <img src={ProcessIcon7} alt="" />
                                            </p>
                                            <label>Ability to access</label>
                                            <label style={{ display: "none" }}>Ability to access</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle bulb">
                                                <img src={ProcessIcon8} alt="" />
                                            </p>
                                            <label>Enhanced Learning</label>
                                            <label style={{ display: "none" }}>Enhanced Learning</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle people">
                                                <img src={ProcessIcon9} alt="" />
                                            </p>
                                            <label>Brand Image</label>
                                            <label style={{ display: "none" }}>Brand Image</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle time">
                                                <img src={ProcessIcon10} alt="" />
                                            </p>
                                            <label>Efficient Prototyping</label>
                                            <label style={{ display: "none" }}>Efficient Prototyping</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle settings">
                                                <img src={ProcessIcon11} alt="" />
                                            </p>
                                            <label>Emotional Impact</label>
                                            <label style={{ display: "none" }}>Emotional Impact</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle settings">
                                                <img src={ProcessIcon12} alt="" />
                                            </p>
                                            <label>Data Clarity</label>
                                            <label style={{ display: "none" }}>Data Clarity</label>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container-fluid pt-3 pb-3 mt-5 bgVoilet experience-section">
                    <div className="container">
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-md-10 experience-title">
                                <p>Why Are 3D Animation Videos Essential For Your Business?</p>
                            </div>
                        </div>
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-md-10 experience-description">
                                <p className='text-white'>
                                    Companies need 3D animated movies for a lot of reasons. Akenna, one of the best 3D animation companies in India, can make your brand stand out, better engage your audience, and get more people interested in what you have to offer. It's time to talk about why you need them and how they can help your business.
                                </p>
                            </div>
                        </div>
                        <div className="row experience-image nunitoRegular pt-3 mb-3 ">
                            <div className="col-md-5">
                                <div className="experience-banner box-3d">
                                    <img src={animation3D1} alt="" />
                                </div>
                            </div>
                            <div className="col-md-7 pb-3">
                                <div className="row experience-content">
                                    <div className="col-md-12 pb-3">
                                        <p className="experience-subtitle">
                                            Elevate Your Brand
                                        </p>
                                        <p className="experience-description">
                                            Captivating and memorable 3D videos help establish and promote your brand.
                                        </p>
                                    </div>
                                    <div className="col-md-12 pb-3">
                                        <p className="experience-subtitle">Content That Keeps Giving</p>
                                        <p className="experience-description">
                                            Your marketing will have a bigger impact if you make 3D images that are easy to share and use again.
                                        </p>
                                    </div>
                                    <div className="col-md-12 pb-3">
                                        <p className="experience-subtitle">Broaden Your Reach</p>
                                        <p className="experience-description">
                                            When you want to reach more people with your business message, animated movies are better than still pictures.
                                        </p>
                                    </div>
                                    <div className="col-md-12 pb-3">
                                        <p className="experience-subtitle">Break Through the Noise</p>
                                        <p className="experience-description">
                                            Create unique 3D animated movies that stand out from the rest with Wow-How.
                                        </p>
                                    </div>
                                    <div className="col-md-12 pb-3">
                                        <p className="experience-subtitle">Drive Online Engagement </p>
                                        <p className="experience-description">
                                            Utilize animated videos to boost website conversions and foster meaningful customer interactions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="container-fluid pt-3 experience2-section">
                    <div className="container">
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-md-9 experience-title">
                                <p>What Is The Length Of Time It Takes To Make A 3D Cartoon Video?</p>
                            </div>
                        </div>
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-md-8 circularBook experience-description">
                                <p>
                                    3D animation is a meticulous creative process that relies on the expertise of our animators and designers.
                                </p>
                            </div>
                        </div>
                        <div className="row experience-image pt-3 mb-1 circularBook">
                            <div className="col-md-7">
                                <div className="row experience-content">
                                    <div className="col-md-12">
                                        <ul>
                                            <li className="experience-description">Typically, crafting a dynamic 3D animation with a simple background and music overlay takes around 4-8 weeks.</li>
                                            <li className="experience-description">For product videos featuring intricate details and environmental modeling, you can expect a timeframe of roughly 6-10 weeks. </li>
                                            <li className="experience-description">When it comes to cartoons with complicated models and long stories, the production time can be 10 to 12 weeks. </li>
                                        </ul>
                                        
                                    </div>
                                    <div className='px-5'>
                                    <p className="experience-description">
                                            The length of time it will take to complete the project is also impacted by a number of other factors, including the size and experience level of the production crew, the application of specialized software and technology, as well as the level of difficulty of the narrative and the visual effects. The procedure can be streamlined with the help of an experienced animation studio or professionals, and the end result can be guaranteed to be of a high quality. </p>
                                        <p className="experience-description">
                                            No matter what you have in mind, our skilled 3D animation team is committed to making it happen, making sure the end result not only meets but also exceeds your expectations.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="experience-banner box-3d">
                                    <img src={animation3D2} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section className="container-fluid pt-3 pb-3 mt-5 explainer-section">
                    <div className="container-fluid">
                        <div className="row text-center d-flex justify-content-center align-item-center">
                            <div className="col-md-10 explainer-title">
                                <p>Our Services In 3D Animations
                                </p>
                            </div>
                        </div>
                        <div className="row text-center d-flex justify-content-center align-item-center">
                            <div className="col-md-10 nunitoRegular explainer-description">
                                <p>
                                    To make an NFT (Non-Fungible Token) store, you need to set up a website where people can buy, sell, and
                                    trade NFTs. Different NFT platforms have different features, blockchain technology, and ways for users to
                                    interact with them. Here are some types of changes in the NFT market.

                                </p>
                            </div>
                        </div>
                        <div className="row text-center d-flex justify-content-center align-item-center">
                            <div className="col-md-12 explain-carousel">
                                <OwlCarousel className='owl-theme ' loop margin={10} nav {...options}>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={Explain} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Animated Explanation Videos</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Inform your customers comprehensively about your product or service through captivating explainer animations that effectively convey your message.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='3dAnimation'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={Product} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Product Presentation Videos</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Show off every beautiful and useful part of your goods. With our 3D product animation services, you can make your product stands out.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='3dAnimation'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image item-img-width">
                                            <img src={animation3d} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Promotion Clips</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Our talented 3D animation production team can make promotional videos just for your business that will overcome your competitors and keep people's interest.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='3dAnimation'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={Presentation} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Crowdfunding Campaign Videos</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Attract investors with attention-grabbing videos designed to showcase your business endeavors in the best possible light. With our services.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='3dAnimation'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={Advertising} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Advertising Montages</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Generate thousands of impressions with our memorable and standout montages. Our 3D animation services company is results-driven and efficient.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='3dAnimation'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={FilmEditing} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Cartoon and Film Editing</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Create captivating animation series or refine films to achieve perfection. With a reliable 3D animation agency as your partner.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" alt='3dAnimation'/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </OwlCarousel>

                            </div>

                        </div>
                    </div>
                </section>


                <Container fluid className='mt-5 mb-5'>
                    <Container>
                        <Row className='d-flex justify-content-center'>
                            <div className="row text-center d-flex justify-content-center align-item-center">
                                <div className="col-md-10 guide-title">
                                    <p>Why Akkenna For 3D Animations?</p>
                                </div>
                            </div>
                            <div className="row text-center d-flex justify-content-center align-item-center">
                                <div className="col-md-10 nunitoMedium guide-description">
                                    <p>
                                        For Akkenna Animations and Technologies, being the greatest 3D animation company in India is our goal. We make captivating animations that resonate. We combine creativity and technology in every project. We constantly experiment with 3D cartoons, so your visual material meets and exceeds industry requirements.
                                    </p>
                                </div>
                            </div>

                            <Col lg={12} className='d-flex mt-3'>
                                <Col lg={4} className="guide-menu">
                                    <div className="guide-topics">
                                        <p>Guide Topics</p>
                                    </div>
                                    <ul className="nav" id="guideNav">
                                        <li onClick={(e) => handleClick(e, "question-1")} className="active">
                                            <a href="#question-1">What defines 3D animation?</a>
                                        </li>
                                        <li onClick={(e) => handleClick(e, "question-2")}>
                                            <a href="#question-2">What sets apart 2D and 3D videos?</a>
                                        </li>
                                        <li onClick={(e) => handleClick(e, "question-3")}>
                                            <a href="#question-3">What are the benefits of 3D animation videos?</a>
                                        </li>
                                        <li onClick={(e) => handleClick(e, "question-4")}>
                                            <a href="#question-4">How is a 3D animated video created?</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col lg={8} className="guide-answers">
                                    <div id="question-1" className="guide-details">
                                        <p className="guide-sub-question">
                                            What defines 3D animation?
                                        </p>
                                        <p className="guide-sub-answer">
                                            3D animation is a digital technique used to create the illusion of movement and depth within a three-dimensional space. It involves the creation and manipulation of 3D models and environments, allowing for the generation of lifelike animations. This process typically includes designing characters, objects, or entire scenes in a virtual 3D space. Animators then apply textures, lighting, and motion to these elements to bring them to life.
                                        </p>

                                        <p className="guide-sub-answer">
                                            Industrial 3D animation services are used a lot in many fields, like film, games, advertising, building, education, and more, because they make it possible to create visually engaging and immersive experiences.. It offers a level of realism and complexity that is often challenging to achieve with traditional 2D animation techniques.
                                        </p>

                                        <p className="guide-sub-answer">
                                            In essence, 3D animation provides a powerful means of storytelling and communication by leveraging the multidimensional nature of digital space. It has completely changed the way visual media is made, making it possible to make worlds that are alive and real, captivating, and involving viewers on a lot of different apps and platforms.
                                        </p>
                                    </div>
                                    <div id="question-2" className="guide-details">
                                        <p className="guide-sub-question">
                                            What sets apart 2D and 3D videos?
                                        </p>
                                        <p className="guide-sub-answer">
                                            The primary difference between 2D and 3D videos lies in dimensionality. 2D videos are flat and lack depth, while 3D videos offer depth perception, creating a more immersive experience.
                                        </p>

                                        <p className="guide-sub-answer">
                                            When making 2D videos, things are drawn or moved on a flat surface. For 3D videos, modeling software is used in three dimensions.
                                        </p>

                                        <p className="guide-sub-answer">
                                            3D movies are more realistic and have more depth because they are 3D.
                                        </p>
                                        <p className="guide-sub-answer">
                                            2D is often used for hand-drawn animations and some movies. 3D, on the other hand, is best for modern movies, video games, architecture, and simulations because it makes the watching experience more real. The choice depends on the intended use and desired immersion level.
                                        </p>
                                    </div>
                                    <div id="question-3" className="guide-details">
                                        <p className="guide-sub-question">What are the benefits of 3D animation videos? </p>
                                        <p className="guide-sub-answer">
                                            Industrial 3D animation services provide a range of significant advantages. They excel at achieving a remarkable level of realism, making them ideal for industries requiring lifelike visual representation. These animations simplify intricate concepts and offer unparalleled creative freedom, unbound by the limitations of the physical world.
                                        </p>

                                        <p className="guide-sub-answer">
                                            In addition, they make sure that the presentation is the same every time, which improves company recognition and image. There may be some costs at first, but 3D cartoons are worth it in the long run because they can be used over and over again. They are readily accessible in digital formats, enhance learning experiences, and evoke strong emotional responses.
                                        </p>

                                        <p className="guide-sub-answer">
                                            Furthermore, 3D animations are invaluable in the realm of data visualization, simplifying complex information and aiding data-driven decision-making. In marketing, education, entertainment, or product development, 3D animation videos have become a strong and flexible tool that greatly improves how stories, ideas, and concepts are told and understood.
                                        </p>
                                    </div>
                                    <div id="question-4" className="guide-details">
                                        <p className="guide-sub-question">
                                            How is a 3D animated video created?
                                        </p>
                                        <p className="guide-sub-answer">
                                            Creating a 3D animated video is a multi-stage process that begins with concept development and scriptwriting. It then progresses to the creation of 3D models, texturing, and rigging, which breathe life into characters and objects.
                                        </p>

                                        <p className="guide-sub-answer">
                                            Lighting artists set the scene, animators carefully plan the moves, and then the animation is rendered. In the final stages, sound design, editing, and quality control ensure a polished and engaging end product. The timeline varies based on complexity but results in a captivating visual and auditory experience.
                                        </p>

                                        <p className="guide-sub-answer">
                                            Collaboration among artists, modelers, animators, and specialists is crucial throughout the process, ensuring that the animation aligns with the initial concept. It's possible to change the final 3D animated movie for different platforms, adding effects, better audio, and an interesting story. This makes it a useful tool for teaching, marketing, telling stories, and more.
                                        </p>
                                    </div>
                                </Col>
                            </Col>
                        </Row>
                    </Container>

                </Container>



                {

<ContactForm/>

}





                <section>
                    <div className="homeFaq">
                        <div className="container">
                            <div className="genaral-title">
                                <div className="row d-flex justify-content-center align-item-center">
                                    <div className="col-md-10 text-center">
                                        <h3 className="fw-bold">Elevating 3D Animation</h3>
                                        <p>
                                            We unite creativity and technology, crafting the most innovative 3D animations together. Together, we shape the future of visual storytelling.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion accordion-faq" id="accordionfaqExample">
                                <div className="row d-flex justify-content-center align-item-center">
                                    <div className="col-md-6">
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                                                    What are the primary stages in 3D animation production?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseOne" className="accordion-collapse collapse" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    Modeling, texturing and shaping, rigging, animation, lighting, rendering, composition, sound design, cutting and Post-Production, and quality control are some of the steps that are usually taken to make a 3D animation.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
                                                    What industries can benefit from 3D animation services?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseThree" className="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    3D animation is versatile and applicable to various industries, including entertainment, advertising, architecture, education, healthcare, product design, and more. It's used to tell stories, show how a product works, run simulations, and sell products.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
                                                    Can I customize 3D animations to suit my specific needs?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseTwo" className="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    Yes, 3D animations are highly customizable. You can tailor them to convey your unique message, brand identity, and style. Whether it's character design, product modeling, or storytelling, customization is key.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseSeven" aria-expanded="false" aria-controls="faq-collapseSeven">
                                                    What is the cost of 3D animation services?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseSeven" className="accordion-collapse collapse" aria-labelledby="faq-headingSeven" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    The cost of 3D animation services varies based on factors like the complexity of the project, duration, and quality expectations. It's best to discuss your project with a professional animation studio to get a tailored quote.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseSix" aria-expanded="false" aria-controls="faq-collapseSix">
                                                    Do I need to provide a script or concept for my 3D animation project?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseSix" className="accordion-collapse collapse" aria-labelledby="faq-headingSix" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    Professional 3D animation companies often have scriptwriters and concept developers on staff who can help you improve your ideas and make them come to life. You can bring in a script or idea if you already have one.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFour" aria-expanded="false" aria-controls="faq-collapseFour">
                                                    How can 3D animations enhance my marketing efforts?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseFour" className="accordion-collapse collapse" aria-labelledby="faq-headingFour" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    3D animations can make your marketing content more engaging and memorable. They can explain complex products or concepts in a visually appealing way and capture your audience's attention effectively.
                                                </div>
                                            </div>
                                        </div>


                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingEight">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseEight" aria-expanded="false" aria-controls="faq-collapseEight">
                                                    What types of 3D animation services are available?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseEight" className="accordion-collapse collapse" aria-labelledby="faq-headingEight" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    There are various types of 3D animation services, including explainer videos, product demos, promotional videos, architectural visualizations, medical animations, and more. The choice depends on your specific goals and needs.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFive" aria-expanded="false" aria-controls="faq-collapseFive">
                                                    Is it possible to update a 3D animation video?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseFive" className="accordion-collapse collapse" aria-labelledby="faq-headingFive" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    Yes, many animation studios offer revision and update services to accommodate changes or improvements to the animation. It's important to discuss the terms of revisions with your chosen studio beforehand.
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </HelmetProvider>
    )
}


export default Animation3D;