import React, { useState, useEffect } from "react";
import Banner1 from "../../Assets/Image/blog/blog-banner-1.png";
import "../../Style/blog.css";
import axios from "axios";
import filterIcon from "../../Assets/Image/blog/filterIcon.svg";
import Dropdown from 'react-bootstrap/Dropdown';
import fb from '../../Assets/Image/blog/fb.svg'
import insta from '../../Assets/Image/blog/insta.svg'
import twitter from '../../Assets/Image/blog/twitter.svg'
import share from '../../Assets/Image/blog/share.svg'
import pin from '../../Assets/Image/blog/pin.svg'
import youtube from '../../Assets/Image/blog/youtube.svg'
import Linkedin from '../../Assets/Image/blog/linkedin.svg'
import ContactForm from '../../Components/ContactForm'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Row } from "react-bootstrap";
import BlogTab from "./BlogTab";

function Bloglist() {




  const [blogData, setBlogData] = useState([]);
  const [BlogAppSection, setBlogAppSection] = useState([]);
  const [BlogDesignSection, setBlogDesignSection] = useState([]);
  const [BlogDevopsSection, setBlogDevopsSection] = useState([]);
  const [BlogMarketingSection, setBlogMarketingSection] = useState([]);
  const [BlogWebSection, setBlogWebSection] = useState([]);
  const [DropDownItems, setDropDownItem] = useState([]);
  const [DropDownApiItems, setDropDownApiItem] = useState([]);
  const [SaveCategory, setSaveCategory] = useState("");
  const [RecentApp, setRecentApp] = useState("");
  const [RecentDesign, setRecentDesign] = useState("");
  const [RecentMarketing, setRecentMarketing] = useState("");
  const [RecentWeb, setRecentWeb] = useState("");
  const [RecentDevop, setRecentDevop] = useState("");

  const [collection, setCollection] = useState([])

  // const Apiurl = "https://api.akkenna.com";

  const Apiurl = "http://localhost:1337";

  const [key, setKey] = useState();


  const apidataget = async () => {

    try {


      let CollectionList = await axios.get('http://localhost:1337/api/content-type-builder/content-types')

      console.log("CollectionListCollectionList",CollectionList);

      if (CollectionList.status == 200) {
        setCollection(CollectionList?.data.data?.filter(obj => obj.uid.startsWith('api') && obj.uid !== 'api::career.career' && obj.uid !== 'api::contact.contact').map(obj => ({
          uid: obj.uid,
          displayName: obj.schema ? obj.schema.displayName : undefined,
          api: obj.schema.pluralName
        })));
      }

      let RecentBlogdata = await axios.get(
        `${Apiurl}/api/getall-recent-blogs/getall-recent-blogs`
      );



      if (RecentBlogdata.status === 200) {
        setBlogData(RecentBlogdata?.data);
      }


      // const BlogAppSection = await axios.get(
      //   `${Apiurl}/api/app-developments?populate=*`
      // );

      // if (BlogAppSection.status === 200) {
      //   if (BlogAppSection.data.data.length > 0) {
      //     setBlogAppSection(BlogAppSection?.data.data);
      //   }
      // }

      // const BlogDesignSection = await axios.get(
      //   `${Apiurl}/api/designs?populate=*`
      // );

      // if (BlogDesignSection.status === 200) {
      //   if (BlogDesignSection.data.data.length > 0) {
      //     setBlogDesignSection(BlogDesignSection?.data.data);
      //   }
      // }

      // const BlogDevopsSection = await axios.get(
      //   `${Apiurl}/api/devops?populate=*`
      // );

      // if (BlogDevopsSection.status === 200) {
      //   if (BlogDevopsSection.data.data.length > 0) {
      //     setBlogDevopsSection(BlogDevopsSection?.data.data);
      //   }
      // }

      // const BlogMarketingSection = await axios.get(
      //   `${Apiurl}/api/digital-marketings?populate=*`
      // );

      // if (BlogMarketingSection.status === 200) {
      //   if (BlogMarketingSection.data.data.length > 0) {
      //     setBlogMarketingSection(BlogMarketingSection?.data.data);
      //   }
      // }

      // const BlogWebSection = await axios.get(
      //   `${Apiurl}/api/developements?populate=*`
      // );

      // if (BlogWebSection.status === 200) {
      //   if (BlogWebSection.data.data.length > 0) {
      //     setBlogWebSection(BlogWebSection?.data.data);
      //   }
      // }

      // const DropDownItems = await axios.get(
      //   `${Apiurl}/api/filtering/getAllCategory`
      // );


      // if (DropDownItems.status === 200) {
      //   setDropDownItem(DropDownItems.data);
      // }

      // const RecentApp = await axios.get(
      //   `${Apiurl}/api/filtering/getRecentApp`
      // );

      // if (RecentApp.status === 200) {
      //   setRecentApp(RecentApp.data);
      // }

      // const RecentDesign = await axios.get(
      //   `${Apiurl}/api/filtering/getRecentDesign`
      // );

      // if (RecentDesign.status === 200) {
      //   setRecentDesign(RecentDesign.data);
      // }

      // const RecentMarketing = await axios.get(
      //   `${Apiurl}/api/filtering/getRecentMarketing`
      // );

      // if (RecentMarketing.status === 200) {
      //   setRecentMarketing(RecentMarketing.data);
      // }

      // const RecentWeb = await axios.get(
      //   `${Apiurl}/api/filtering/getRecentWeb`
      // );

      // if (RecentWeb.status === 200) {
      //   setRecentWeb(RecentWeb.data);
      // }

      // const RecentDevops = await axios.get(
      //   `${Apiurl}/api/filtering/getRecentDevops`
      // );

      // if (RecentDevops.status === 200) {
      //   setRecentDevop(RecentDevops.data);
      // }

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    apidataget();
  }, []);

  function TimeFormat(val) {
    const date = new Date(val);
    const dateFormat = new Date(date);
    const DateValue = dateFormat.toDateString();


    return DateValue;
  }

  function Blogurl(department, url) {
    window.location.href = `https://akkenna.com/blogs/${department}/${url}`;
  }

  const handleSelect = async (value, department) => {

    setSaveCategory(value);
    if (department === "app-developments") {
      const Data = await axios.get(
        `${Apiurl}/api/${department}?filters[Category][$eq]=${value}&populate=*`
      );

      setBlogAppSection(Data.data.data);
    }
    if (department === "devop") {
      const Data = await axios.get(
        `${Apiurl}/api/${department}?filters[Category][$eq]=${value}&populate=*`
      );

      setBlogDevopsSection(Data.data.data);
    }
    if (department === "designs") {
      const Data = await axios.get(
        `${Apiurl}/api/${department}?filters[Category][$eq]=${value}&populate=*`
      );

      setBlogDesignSection(Data.data.data);
    }
    if (department === "digital-marketings") {
      const Data = await axios.get(
        `${Apiurl}/api/${department}?filters[Category][$eq]=${value}&populate=*`
      );

      setBlogMarketingSection(Data.data.data);
    }
    if (department === "developements") {
      const Data = await axios.get(
        `${Apiurl}/api/${department}?filters[Category][$eq]=${value}&populate=*`
      );

      setBlogWebSection(Data.data.data);
    }
  };


  function handleCopyFn(e) {
    e.preventDefault()
    const val = window.location.href
    navigator.clipboard.writeText(val)
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Copy
    </Tooltip>
  );

  console.log("Collection", collection?.filter(obj => obj.uid.startsWith('api') && obj.uid !== 'api::career.career' && obj.uid !== 'api::contact.contact').map(obj => ({
    uid: obj.uid,
    displayName: obj.schema ? obj.schema.displayName : undefined
  })))

  return (
    <div id="blogList">
      <div className="container-fluid mt-80 mb-5">
        <div className="container">
          <div className="blogFlexbannerLeft">
            <div
              className="col-lg-6 col-md-12 col-sm-12 blogLeft hover-now"
              onClick={() => {
                Blogurl("app-developments", blogData[0].seoUrl);
              }}
            >
              <div className="blogBanner">
                <img
                  src={`${Apiurl}${blogData[0]?.BannerImage?.url}`}
                  alt=""
                />
              </div>
              <div className="blogBanner-content pt-3">
                <div className="blogBanner-button backgroundMidGray">
                  <p className="FontVoilet circularBook">App development</p>
                </div>
                <div className="description pt-3">
                  <h5 className="FontBoldDark circularBook">{blogData[0]?.Title}</h5>
                  <hr />
                  <p className="nunitoRegular">{blogData[0]?.Description}</p>
                </div>
                <div>
                  <h6 className="FontBoldDark pt-2">
                    {TimeFormat(blogData[0]?.createdAt)}
                    <span className="pl-1">
                      {" "}
                      &#9679;{blogData[0]?.Author}
                    </span>{" "}
                    <span className="pl-3">
                      {" "}
                      &#9679;{blogData[0]?.Readtime}
                    </span>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-6 blogFlexbannerRight blogLeft ">
              <div
                className="d-flex gap-4 flex-wrap hover-now"
                onClick={() => {
                  Blogurl("devops", blogData[1].seoUrl);
                }}
              >
                <div className="bannerRight-img">
                  <img
                    src={`${Apiurl}${blogData[1]?.BannerImage?.url}`}
                    alt=""
                  />
                </div>
                <div className="blogRight">
                  <div className="blogBanner-button backgroundMidGray">
                    <p className="FontVoilet circularBook">Devops</p>
                  </div>
                  <div className="description">
                    <h5 className="FontBoldDark circularBook pt-3">{blogData[1]?.Title}</h5>
                    <hr />
                    <p className="FontBoldDark">
                      {TimeFormat(blogData[1]?.createdAt)}
                      <span className="pl-1">
                        {" "}
                        &#9679;{blogData[1]?.Author}
                      </span>{" "}
                      <span className="pl-3">
                        {" "}
                        &#9679;{blogData[1]?.Readtime}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="d-flex gap-4 flex-wrap hover-now"
                onClick={() => {
                  Blogurl("digital-marketings", blogData[2].seoUrl);
                }}
              >
                <div className="bannerRight-img">
                  <img
                    src={`${Apiurl}${blogData[2]?.BannerImage?.url}`}
                    alt=""
                  />
                </div>
                <div className="blogRight">
                  <div className="blogBanner-button backgroundMidGray">
                    <p className="FontVoilet circularBook">Technologies</p>
                  </div>
                  <div className="description">
                    <h5 className="FontBoldDark circularBook pt-3">{blogData[2]?.Title}</h5>
                    <hr />
                    <p className="FontBoldDark">
                      {TimeFormat(blogData[2]?.createdAt)}
                      <span className="pl-1">
                        {" "}
                        &#9679;{blogData[2]?.Author}
                      </span>{" "}
                      <span className="pl-3">
                        {" "}
                        &#9679;{blogData[2]?.Readtime}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="d-flex gap-4 flex-wrap hover-now"
                onClick={() => {
                  Blogurl("developements", blogData[3]?.seoUrl);
                }}
              >
                <div className="bannerRight-img">
                  <img
                    src={`${Apiurl}${blogData[3]?.BannerImage?.url}`}
                    alt=""
                  />
                </div>
                <div className="blogRight">
                  <div className="blogBanner-button backgroundMidGray">
                    <p className="FontVoilet circularBook">Web Development</p>
                  </div>
                  <div className="description">
                    <h5 className="FontBoldDark circularBook pt-3">
                      {blogData[3]?.Title}
                    </h5>
                    <hr />
                    <p className="FontBoldDark">
                      {TimeFormat(blogData[3]?.createdAt)}
                      <span className="pl-1">
                        {" "}
                        &#9679;{blogData[3]?.Author}
                      </span>{" "}
                      <span className="pl-3">
                        {" "}
                        &#9679;{blogData[3]?.Readtime}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid  blogCategory-main background-lightViolet pt-5 pb-5">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <h3 className="circularMedium">Blog Category</h3>
            </div>
          </div>
          <Row>
            <Tabs
              id="justify-tab-example"
              className="mb-3"
              justify
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              {Array.from(collection.entries())?.map(([key, value]) => (
                <Tab key={key} eventKey={key} title={value?.displayName}>
                  <BlogTab list={value} />
                </Tab>
              ))}
            </Tabs>
          </Row>
        </div>
      </div>
      {
        <ContactForm />
      }
    </div>
  );
}

export default Bloglist;
