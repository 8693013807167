import '../../../Style/animation.css'
// import '../../../Style/NewAnimation.css'
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Slider from "react-slick";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import BannerImg from '../../../Assets/Image/animation/threeDBanner.svg'
import arrow from "../../../Assets/Image/animation/arrow.png"
import animation3D1 from '../../../Assets/Image/animation/threeDWhy.svg'
import ProcessIcon1 from '../../../Assets/Image/service-page/1-visual-appeal-3d.svg'
import ProcessIcon2 from '../../../Assets/Image/service-page/2-realism-3d.svg'
import ProcessIcon3 from '../../../Assets/Image/service-page/3-complexity-3d.svg'
import ProcessIcon4 from '../../../Assets/Image/service-page/4-creativity-3d.svg'
import ProcessIcon5 from '../../../Assets/Image/service-page/5-consistensy-3d.svg'
import ProcessIcon6 from '../../../Assets/Image/service-page/6-cost-3d.svg'
import ProcessIcon7 from '../../../Assets/Image/service-page/7-ability-3d.svg'
import ProcessIcon8 from '../../../Assets/Image/service-page/8-learning-3d.svg'
import ProcessIcon9 from '../../../Assets/Image/service-page/9-brand-3d.svg'
import ProcessIcon10 from '../../../Assets/Image/service-page/10-prototyping-3d.svg'
import ProcessIcon11 from '../../../Assets/Image/service-page/11-impact-3d.svg'
import ProcessIcon12 from '../../../Assets/Image/service-page/12-data-3d.svg'
import Explain from '../../../Assets/Image/service-page/explain.gif'
import Product from '../../../Assets/Image/service-page/product.gif'
import animation3d from '../../../Assets/Image/service-page/3d-animation.gif'
import Presentation from '../../../Assets/Image/service-page/presentation-gif-11.gif'
import Advertising from '../../../Assets/Image/service-page/advertising.gif'
import FilmEditing from '../../../Assets/Image/service-page/film editing.gif'
import ContactForm from '../../../Components/ContactForm';
import Longterm from '../../../Assets/Image/animation/threeDBrand.svg'
import AnimeVideo from '../../../Assets/video/Anime.mp4'
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik';
import '../../../Style/NewContact.css'
import axios from 'axios';
import Swal from 'sweetalert2'
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';


function MydModalWithGrid({ show, onHide }) {

    const [Loading,SetLoading]=useState(false)

    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            company: '',
            service: '',
            budget: '',
            message: '',
        },
        validate: (values) => {
            const errors = {}

            if (!values.name) {
                errors.name = 'Required*';
            }
            if (!values.phone) {
                errors.phone = 'Required*';
            } else if (!/^\d{10}$/.test(values.phone)) {
                errors.phone = 'Must be exactly 10 digits';
            }
            if (!values.email) {
                errors.email = 'Required*';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Must be valid e-mail';
            }
            if (!values.company) {
                errors.company = 'Required*';
            }

            if (!values.service) {
                errors.service = 'Required*';
            }
            if (!values.budget) {
                errors.budget = 'Required*';
            }
            if (!values.message) {
                errors.message = 'Required*';
            } else if (values.message.length > 5000) {
                errors.message = 'Must be 5000 characters or less';
            }

            if (Object.keys(errors).length === 0) {
                formik.setStatus({ isSubmitting: true });
            }

            return errors;
        },
        onSubmit:async (values, { setSubmitting, resetForm }) => {

            SetLoading(true);
            onHide(false);
            const res = await axios.post('https://api.akkenna.com/api/mailing/contact',values)
            if(res.status == 200){
                
                SetLoading(false)
                Swal.fire({
                    text: "We appreciate your interest! Our company will be in touch with you soon.",
                    imageUrl: "https://akkenna.com/static/media/logo.527ce9569c515d13529371370e18d69f.svg",
                    imageWidth: 200,
                    imageHeight: 20,
                    imageAlt: "Custom image",
                    showCloseButton: true,
                    showConfirmButton: true,
                    confirmButtonText: "Thank You",
                  })
              }
            setTimeout(() => {
                setSubmitting(false);
            }, 2000);
            resetForm()
        },
    });



    return (
        <Modal id='animation_3dForm' show={show} size='lg' onHide={onHide} aria-labelledby="contained-modal-title-vcenter">
            
            <Modal.Header closeButton onHide={onHide}>
                <Modal.Title id="contained-modal-title-vcenter" >
                <h2 className="frmTitle circularBook">Get an Idea? Let’s Talk</h2>
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body >
            <main id="contactFormPage">
            <div className="container-fluid FormbackgroundBg marginTop pt-5 pb-5">
                <div className="container">
                    <div className="row formRow">
                        <div className="col-lg-9">
                            <div className='FormHead'>
                                <p className="frmDesc circularBook text-center">Our technical expert will schedule a call to discuss your idea
                                   
                                    depth following the execution of a <br/> non-disclosure agreement once we have
                                    received your
                                    request.Every detail <br/> will be maintained in strict confidence.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-3">
                        <div className="col-lg-9 contact-form">
                            <form onSubmit={formik.handleSubmit} >
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-lg-6 col-md-7 col-sm-10 mt-1">
                                        <input type="text" name="name" id="name"
                                            placeholder='Enter Your Name'
                                            onBlur={formik.handleChange}
                                            onChange={formik.handleChange}
                                            value={formik.values.name} />
                                        <p className='text-danger'>{formik.errors.name}</p>
                                    </div>
                                    <div className="col-lg-6 col-md-7 col-sm-10 mt-1">
                                        <input type="email" name="email" id="email"
                                            placeholder='Enter Your E-mail'
                                            onBlur={formik.handleChange}
                                            onChange={formik.handleChange}
                                            value={formik.values.email} />
                                        <p className='text-danger'>{formik.errors.email}</p>
                                    </div>
                                    <div className="col-lg-6 col-md-7 col-sm-10 mt-4">
                                        <input type="text" name="phone" id="phone"
                                            onBlur={formik.handleChange}
                                            onChange={formik.handleChange}
                                            placeholder='Enter Your Phone Number'
                                            value={formik.values.phone} />
                                        <p className='text-danger'>{formik.errors.phone}</p>
                                    </div>
                                    <div className="col-lg-6 col-md-7 col-sm-10 mt-4">
                                        <input type="text" name="company" id="company"
                                            onBlur={formik.handleChange}
                                            onChange={formik.handleChange}
                                            placeholder='Enter Your Company Name'
                                            value={formik.values.company} />
                                        <p className='text-danger'>{formik.errors.company}</p>
                                    </div>
                                    <div className="col-lg-6 col-md-7 col-sm-10 mt-4">
                                        <select name="service" id="service"
                                            onBlur={formik.handleChange}
                                            onChange={formik.handleChange}
                                            placeholder='What service you want from us'
                                            value={formik.values.service}
                                        >
                                            <option value="">What service you want from us</option>
                                            <option value="Digital Marketing">Digital Marketing</option>
                                            <option value="Ecommerce Development">Ecommerce Development</option>
                                            <option value="Explainer Video Creation">Explainer VideoCreation </option>
                                            <option value="Hire Dedicated Development">Hire DedicatedDevelopment</option>
                                            <option value="Logo Design">Logo Design</option>
                                            <option value="Mobile App Development">Mobile App Development</option>
                                            <option value="Pay Per Click Advertising">Pay Per Click Advertising</option>
                                            <option value="Search Engine Optimization">Search Engine Optimization</option>
                                            <option value="Shopify Website Development">Shopify WebsiteDevelopment</option>
                                            <option value="Social Media Marketing">Social Media Marketing</option>
                                            <option value="UI UX Design">UI UX Design</option>
                                            <option value="Web App Development">Web App Development</option>
                                            <option value="Website Design and Development">Website Design and Development</option>
                                            <option value="Website Redesigning">Website Redesigning</option>
                                            <option value="Wordpress Development">Wordpress Development</option>
                                        </select>
                                        <p className='text-danger'>{formik.errors.service}</p>
                                    </div>
                                    <div className="col-lg-6 col-md-7 col-sm-10 mt-4">
                                        <select name="budget" id="budget"
                                            onBlur={formik.handleChange}
                                            onChange={formik.handleChange}
                                            placeholder='Select Your budget'
                                            value={formik.values.budget}
                                           
                                        >
                                            <option value="">Select your budget</option>
                                            <option value="10K-25K USD">10K-25K USD</option>
                                            <option value="25K-50K USD">25K-50K USD</option>
                                            <option value="50K-100K USD">50K-100K USD</option>
                                            <option value="Less than 10K USD">Less than 10K USD</option>
                                            <option value="More than 100K USD">More than 100K USD</option>
                                        </select>
                                        <p className='text-danger'>{formik.errors.budget}</p>
                                    </div>
                                    <div className="col-lg-12 col-md-7 col-sm-10 mt-4">
                                        <textarea name="message" id="message"
                                            onBlur={formik.handleChange}
                                            onChange={formik.handleChange}
                                            placeholder='Enter Your Message'
                                            cols="30" rows="10"
                                        ></textarea>
                                        <p className='text-danger'>{formik.errors.message}</p>
                                    </div>
                                    <div className='submit-btn d-flex justify-content-center mt-4'>
                                        <button type='button' className='darkBtn' onClick={formik.handleSubmit} name="submit">
                                            {
                                                Loading ? <Spinner/> : "Enquiry Now"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
            </Modal.Body>
        </Modal>
    );
}















const options = {
    loop: true,
    dots: false,
    margin: 10,
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
        0: {
            items: 1,
            nav: true,
            loop: false
        },
        600: {
            items: 1,
            nav: true,
            loop: false
        },
        900: {
            items: 1.5,
            nav: true,
            loop: false
        },
        1000: {
            items: 2.5,
            nav: true,
            loop: false
        },
        1300: {
            items: 3,
            nav: true,
            loop: false
        }
    }
}


const settings = {
    centerMode: true,
    speed: 500,
    arrows: true,
    autoplay: true,
    autoplayTimeout: 3000,
    slidesToShow: 5,
    responsive: [{
        breakpoint: 800,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            infinite: true,
        },
    },
    {
        breakpoint: 450,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
        },
    },
    {
        breakpoint: 300,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
        },
    },
    ],
}


const Animation3D = () => {

    const [modalShow, setModalShow] = useState(false);






    return (
        <HelmetProvider>
            <Helmet>
                <title>Professional 2D and 3D Explainer Video Services in India | Akkenna</title>
                <meta name="description"
                    content="Transform your ideas into captivating visuals with Akkenna's expert 2D and 3D explainer video services in India. Elevate your brand and more" />
                <meta name="keywords"
                    content="explainer video services in india, 2d and 3d animation service,  2d and 3d animated explainer video service, 3D animation services in india" />
            </Helmet>
            <main id="animation_3d">
                <section className="banner">
                    <div className="container-fluid">
                        <div className="container bannerCont">
                            <div className="row justify-content-between">
                                <div className="col-lg-6 bannerLeft">
                                    <div>
                                        <h5 className="text-violet fw-bold mb-0">Elevate Your Imagination</h5>
                                    </div>
                                    <div>
                                        <h1 className="text-white">
                                            Leading 2D and 3D
                                        </h1>
                                        <h1 className="text-white">
                                            Animation Company in India
                                        </h1>
                                    </div>
                                    <div>
                                        <p className="h6 text-bannerpara nunitoMedium">
                                            Innovative video and animation to elevate your brand presence!
                                        </p>
                                    </div>
                                    <div className="animation-bannerBtn">
                                        
                                            <button className="whiteBtn circularBold bg-whiteButton text-smallHeading fw-bold" onClick={(e)=>{ setModalShow(true)}}>
                                                LET’S TALK
                                            </button>
                                        
                                    </div>
                                </div>
                                <div className="col-lg-6 bannerRightImage">
                                    <p className="animation-banner">
                                        <img src={BannerImg} alt="" loading='lazy' />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container-fluid my-4">
                    <div className="container">
                        <div className="row d-flex flex-wrap" >
                            <div className="col-lg-6 d-flex justify-content-center align-items-center animation3dImg ">
                                <img src={Longterm} alt="long-term" />
                            </div>
                            <div className="col-lg-6 d-flex justify-content-center flex-column align-items-center animation3dContent">
                                <div>
                                    <h3 className="text-smallHeading circularBook pb-2">Your Brand, Our Canvas: <span className='fw-bold'>Akkenna Animation's Journey</span>  of Creative Exploration</h3>
                                </div>
                                <div>
                                    <p className="pb-2">Akkenna Animation and Technologies stands at the forefront of creativity, innovation, and visual excellence, weaving a tapestry of captivating narratives through our animated creations. As dedicated storytellers, artists, and inventors, we engage in collaborative partnerships to bring your ideas to life. Whether it's animated explanation films, compelling product presentations, dynamic marketing clips, or other visual masterpieces, our team is committed to transforming concepts into immersive and memorable experiences.</p>
                                </div>
                                <div>
                                    <p className="pb-2">In the dynamic world of animation we pride ourselves on leading the industry in both 2D and 3D services. Our commitment goes beyond mere visuals; it's about crafting narratives that resonate with your audience, leaving a lasting imprint on their minds. Akkenna Animation and Technologies is not just a service provider; we are your creative allies, working tirelessly to elevate your brand to new heights.</p>
                                </div>
                                {/* <p className="pb-2">With an unwavering focus on pushing the boundaries of what's possible, we strive to magnify your message and ensure that your visual content stands out in a crowded digital landscape. Your brand is not just a logo; it's a story waiting to be told, and we are here to tell it in the most compelling and visually stunning way possible. Partner with us to embark on a journey of creative exploration, where innovation meets visual brilliance, and your brand becomes an unforgettable presence in the hearts and minds of your audience.</p> */}
                            </div>
                        </div>
                    </div>
                </section>


                <Container fluid className='mt-5 mb-5 video-explain'>
                    <div className="container">
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-lg-8">
                                <h3 className="fw-bold BringHeading">
                                    Styles Of 2D and 3D Animated Videos
                                </h3>
                                <p className="animation-text-para nunitoRegular mt-2">
                                    Whenever we work on a 2D and 3D animation project, it's like telling a different story with lifelike movements and realism. Our skilled animators ensure each project stands out with its unique style.
                                </p>
                            </div>
                        </div>
                    </div>
                    <Container>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row className='d-flex flex-column justify-content-center align-items-center' >
                                <Col lg={9} className="row text-center d-flex justify-content-center align-items-center video-explainer">
                                    <Nav variant="pills" className='d-flex justify-content-center align-items-center' >
                                        {/* <Nav.Item className="col-md-6 video-explainer-title explainer">
                                            <Nav.Link eventKey="first" className="video-explainer-text">Interactive 3D Animation Videos</Nav.Link>
                                        </Nav.Item> */}
                                        {/* <Nav.Item className="col-md-4 video-explainer-title whiteboard">
                                            <Nav.Link eventKey="second" className="video-explainer-text">Virtual Reality 3D Animation videos</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="col-md-4 video-explainer-title live">
                                            <Nav.Link eventKey="third" className="video-explainer-text">Stop Motion 3D Animation Videos</Nav.Link>
                                        </Nav.Item> */}
                                    </Nav>
                                </Col>
                                <Col lg={10}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first" className="row justify-content-center video-cards">
                                            <div className="technologyCards">
                                                <div>
                                                    <video autoPlay muted controls>
                                                        <source src={AnimeVideo} type="video/mp4" />
                                                    </video>
                                                    {/* <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/KmSSQveJ258?si=4zC6ZAHjwH7xCitx" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
                                                </div>
                                                {/* <div>
                                                    <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/8XST11KhL7k?si=5gimrlerw1JRIYWM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                                </div> */}
                                            </div>
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="second" className="row justify-content-center video-cards">
                                            <div className="technologyCards">
                                                <div>
                                                    <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/4xwQpzeiHLE?si=G80kQjXKgZ2gWGO-" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                                </div>
                                                <div>
                                                    <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/FDZVGJIM0W8?si=G_TcVgsO77bNWKEV" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <div className="technologyCards">
                                                <div>
                                                    <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/AEEWRfU4Er8?si=Dc3T4uw07ufaCUOb" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                                </div>
                                                <div>
                                                    <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/bBP4HskFDBg?si=3ckJAehRW0FvB2_8" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                                </div>
                                            </div>
                                        </Tab.Pane> */}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </Container>

                <section className="container-fluid animation hireLabelTag pt-4 pb-4">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-5 col-lg-5 HireHeading text-right">
                                <h4 className="text-white">Craft Outstanding 2D and 3D Animations For Your Business</h4>
                                <h4 className="text-white" style={{ display: "none" }}>
                                    Craft Outstanding 2D and 3D Animations For Your Business </h4>
                            </div>
                            <div className="col-md-3 col-lg-3 hire-arrowImg text-center">
                                <img src={arrow} alt="arrow" />
                            </div>
                            <div className="col-md-4 col-lg-4 hirePosition text-center">
                                <img src="assets/image/animation/btnbgimg.svg" alt="" style={{ display: "none" }} />
                                <div className="hirebtnbg hirePositionRelatbutton">
                                    <a href="/contact" target='_blank'>
                                        <button className="whiteBtn bg-whiteButton circularBold text-smallHeading fw-bold text-center hireBtn">
                                            Have Questions?
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="container-fluid mt-5 mb-5 process-section">
                    <div className="container">
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-md-10">
                                <div className="process-title">
                                    <p>Why Are 3D and 2D Animation Videos Essential For Your Business?</p>
                                </div>
                            </div>
                            <div className="col-md-10 process-description nunitoRegular">
                                <div className="mt-2">
                                    <p>
                                        Companies need 3D animated movies for a lot of reasons. Akenna, one of the best 3D animation companies in India, can make your brand stand out, better engage your audience, and get more people interested in what you have to offer. It's time to discuss why you need them and how they can help your business.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row experience-details d-flex justify-content-center align-items-center mb-3">
                            <div className="col-lg-11 processCard">
                                <Slider {...settings} className="row ">
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle search">
                                                <img src={ProcessIcon1} alt="" />
                                            </p>
                                            <label>Engagement</label>
                                            <label style={{ display: "none" }}>Engagement</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle bulb">
                                                <img src={ProcessIcon2} alt="" />
                                            </p>
                                            <label>Memorability</label>
                                            <label style={{ display: "none" }}>Memorability</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle people">
                                                <img src={ProcessIcon3} alt="" />
                                            </p>
                                            <label>Clarity</label>
                                            <label style={{ display: "none" }}>Clarity</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle time">
                                                <img src={ProcessIcon4} alt="" />
                                            </p>
                                            <label>Versatility</label>
                                            <label style={{ display: "none" }}>Versatility</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle settings">
                                                <img src={ProcessIcon5} alt="" />
                                            </p>
                                            <label>Brand Identity</label>
                                            <label style={{ display: "none" }}>Brand Identity</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle settings">
                                                <img src={ProcessIcon6} alt="" />
                                            </p>
                                            <label>Impact</label>
                                            <label style={{ display: "none" }}>Impact</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle search">
                                                <img src={ProcessIcon7} alt="" />
                                            </p>
                                            <label>Storytelling</label>
                                            <label style={{ display: "none" }}>Storytelling</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle bulb">
                                                <img src={ProcessIcon8} alt="" />
                                            </p>
                                            <label>Competitive Edge</label>
                                            <label style={{ display: "none" }}>Competitive Edge</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle people">
                                                <img src={ProcessIcon9} alt="" />
                                            </p>
                                            <label>Audience Reach</label>
                                            <label style={{ display: "none" }}>Audience Reach</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle time">
                                                <img src={ProcessIcon10} alt="" />
                                            </p>
                                            <label>Conversion</label>
                                            <label style={{ display: "none" }}>Conversion</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle settings">
                                                <img src={ProcessIcon11} alt="" />
                                            </p>
                                            <label>Innovation</label>
                                            <label style={{ display: "none" }}>Innovation</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle settings">
                                                <img src={ProcessIcon12} alt="" />
                                            </p>
                                            <label>Professionalism</label>
                                            <label style={{ display: "none" }}>Professionalism</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle people">
                                                <img src={ProcessIcon9} alt="" />
                                            </p>
                                            <label>Adaptability</label>
                                            <label style={{ display: "none" }}>Adaptability</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle search">
                                                <img src={ProcessIcon7} alt="" />
                                            </p>
                                            <label>Modernization</label>
                                            <label style={{ display: "none" }}>Modernization</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 centered-div">
                                        <div className="content">
                                            <p className="rounded-circle bulb">
                                                <img src={ProcessIcon8} alt="" />
                                            </p>
                                            <label>Educational Value</label>
                                            <label style={{ display: "none" }}>Educational Value</label>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container-fluid pt-4 pb-4 bgVoilet experience-section">
                    <div className="container">
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-md-10 experience-title">
                                <p>Why choose our Explainer 2D and 3D animation services in India?</p>
                            </div>
                        </div>
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-md-10 experience-description">
                                <p className='text-white mt-2'>
                                    Select Akkenna for 2D and 3D animation services in India, where innovation meets artistic excellence. Our animations transcend expectations, offering a unique blend of creativity and precision to amplify your brand's visual narrative
                                </p>
                            </div>
                        </div>
                        <div className="row experience-image nunitoRegular pt-3">
                            <div className="col-md-5">
                                <div className="experience-banner box-3d">
                                    <img src={animation3D1} alt="" />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="row experience-content">
                                    <div className="col-md-12 ">
                                        <p className="experience-subtitle">
                                            Cost Savings
                                        </p>
                                        <p className="experience-description">
                                            Because we have direct connections, we can offer options that are both cheap and good. Get the most for your money while still getting high-quality film production services.
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <p className="experience-subtitle">Diverse Expertise</p>
                                        <p className="experience-description">
                                            Akkenna has experience in all kinds of industries, genres, and budgets, from 2D, 2.5D, and 3D animations to business video shoots, TV commercials, and digital Companies.
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <p className="experience-subtitle">Quality Assurance</p>
                                        <p className="experience-description">
                                            You can rest easy knowing that our service providers have been checked out and have a lot of experience. Your projects are being worked on by experts in the field who are dedicated to doing a great job.
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <p className="experience-subtitle">Hassle-Free Process</p>
                                        <p className="experience-description">
                                            Streamlined from idea to execution for a hassle-free process We make the whole process of making a video easier, from coming up with ideas to delivering the finished result. You should focus on your goal while we take care of the details.
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <p className="experience-subtitle">Trust & Security</p>
                                        <p className="experience-description">
                                            Ask each firm for examples of their past work, references, and showreels. Don't worry- our platform guarantees 100% payment security, giving you peace of mind and openness.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>





                <section className="container-fluid pt-3 pb-3  explainer-section">
                    <div className="container-fluid">
                        <div className="row text-center d-flex justify-content-center align-item-center">
                            <div className="col-md-10 explainer-title">
                                <p>Our Services In 2D and 3D Animations
                                </p>
                            </div>
                        </div>
                        {/* <div className="row text-center d-flex justify-content-center align-item-center">
                            <div className="col-md-10 nunitoRegular explainer-description">
                                <p>
                                    To make an NFT (Non-Fungible Token) store, you need to set up a website where people can buy, sell, and
                                    trade NFTs. Different NFT platforms have different features, blockchain technology, and ways for users to
                                    interact with them. Here are some types of changes in the NFT market.

                                </p>
                            </div>
                        </div> */}
                        <div className="row text-center d-flex justify-content-center align-item-center">
                            <div className="col-md-12 explain-carousel">
                                <OwlCarousel className='owl-theme ' loop margin={10} nav {...options}>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={Explain} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Animated Explanation Videos</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Akkenna Animation crafts dynamic and compelling animated explanation videos that breathe life into your ideas. With innovative storytelling and cutting-edge animation, we simplify complex concepts, ensuring your message resonates.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" />
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={Product} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Product Presentation Videos</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                            Where visualization meets innovation, Akkenna Animation and Technologies produces product presentation videos. Creating dynamic and impactful product presentations in both 2D and 3D, our skilled team elevates your brand and displays your products.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" />
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={animation3d} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Promotion Clips</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Akkenna Animation excels in crafting promotion clips that resonate and captivate. Our videos bring your promotions to life with dynamic visuals and compelling storytelling.Elevate your promotional strategy with Akkenna Animation's expertise.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" />
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={Presentation} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Crowdfunding Campaign Videos</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Akkenna Animation specializes in creating impactful crowdfunding campaign videos that breathe life into your project. Our videos skillfully convey your message, evoke emotion, and inspire support. From compelling storytelling to dynamic visuals.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" />
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={Advertising} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Advertising Montages</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Akkenna Animation excels in crafting dynamic advertising montages that elevate your brand's visual presence. Our videos seamlessly blend creativity and messaging to showcase your products or services in a captivating narrative.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" />
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-image">
                                            <img src={FilmEditing} alt="" />
                                        </div>
                                        <div className="item-title">
                                            <p>Cartoon and Film Editing</p>
                                        </div>
                                        <div className="item-description">
                                            <p>
                                                Akkenna Animation is your expert partner in cartoon and film editing, infusing creativity and precision into every frame. From charming cartoons to cinematic brilliance. Trust us to enhance captivates audiences and brings stories to life.
                                            </p>
                                        </div>
                                        <div className="item-get-started">
                                            <p>
                                                Get Started
                                                <span>
                                                    <img src="assets/image/animation/GetArrow.svg" />
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </OwlCarousel>

                            </div>

                        </div>
                    </div>
                </section>






                {

                    <ContactForm />

                }


                <section>
                    <div className="homeFaq">
                        <div className="container">
                            <div className="genaral-title">
                                <div className="row d-flex justify-content-center align-item-center">
                                    <div className="col-md-10 text-center">
                                        <h3 className="fw-bold">FAQs </h3>

                                    </div>
                                </div>
                            </div>
                            <div className="accordion accordion-faq" id="accordionfaqExample">
                                <div className="row d-flex justify-content-center align-item-center">
                                    <div className="col-md-6">
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                                                    What services does Akkenna Animation and Technologies offer?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseOne" className="accordion-collapse collapse" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    At Akkenna, we specialize in a range of services including 2D and 3D animated explanation videos, product presentation videos, promotion clips, crowdfunding campaign videos, advertising montages, cartoon series creation, film editing, and more. Our goal is to bring creativity and innovation to your visual projects.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
                                                    How does the animation process work?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseThree" className="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    The animation process typically involves conceptualization, script development, storyboarding, design, animation, and final delivery. We collaborate closely with clients throughout each stage to ensure the final product aligns with their vision.
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
                                                    Can you provide services for both 2D and 3D animation?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseTwo" className="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    Absolutely! Akkenna Animation and Technologies excels in both 2D and 3D animation. Whether you prefer the simplicity of 2D graphics or the depth of 3D animation, we have the expertise to meet your specific requirements.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseSix" aria-expanded="false" aria-controls="faq-collapseSix">
                                                    What sets Akkenna apart from other animation agencies?
                                                </button>
                                            </h3>
                                            <div id="faq-collapseSix" className="accordion-collapse collapse" aria-labelledby="faq-headingSix" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body">
                                                    Akkenna stands out through its innovative storytelling, versatility in both 2D and 3D animation, attention to client input, and commitment to delivering high-quality results. We bring a fresh and creative approach to each project, ensuring that your visual content is distinctive and impactful.
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {

<MydModalWithGrid show={modalShow} onHide={setModalShow} />
}
            </main>
        </HelmetProvider>
    )
}


export default Animation3D;