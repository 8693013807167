import React from 'react'

const BlogTabView = ({ item }) => {


    // const Apiurl = "https://api.akkenna.com";

    const Apiurl = "http://localhost:1337";

    function TimeFormat(val) {
        const date = new Date(val);
        const dateFormat = new Date(date);
        const DateValue = dateFormat.toDateString();


        return DateValue;
    }

    function Blogurl(department, url) {
        window.location.href = `https://akkenna.com/blogs/${department}/${url}`;
    }

    return (
        <div
            className="row d-flex hover-now"
            onClick={() => {
                Blogurl(
                    "app-developments",
                    item.attributes.seoUrl
                );
            }}
        >
            <div className="col-lg-6">
                <div className="blogCategory">
                    <div className=" backgroundMidGray FontVoilet categoryBtn">
                        {item?.attributes?.Category}
                    </div>
                    <div className="description">
                        <h5 className="FontBoldDark circularBook pt-3">
                            {item?.attributes?.Title}
                        </h5>
                        <p className="nunitoRegular pt-2">{item?.attributes?.Description}</p>
                        <p className="date FontBoldDark pt-2">
                            {TimeFormat(item?.attributes?.createdAt)}{" "}
                            <span className="pl-1 pt-3">
                                {" "}
                                &#9679;{item?.attributes?.Author}{" "}
                            </span>{" "}
                            <span className="pl-3">
                                {" "}
                                &#9679;{item?.attributes?.Readtime}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
                <div className="blogCategory-img">
                    <img
                        src={`${Apiurl}${item?.attributes?.BannerImage.data.attributes.url}`}
                        alt=""
                    />
                </div>
            </div>
        </div>
    )
}

export default BlogTabView