import {BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import './App.css'
import Header from './Components/Header';
import Footer from './Components/Footer';
// import Home from './Pages/Home/Home';
import Bloglist from './Pages/Blogs/Bloglist';
import BlogDetail from './Pages/Blogs/BlogDetails';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import WebDevelopment from './Pages/Services/WebDevelopment';
import ThreeDAnimation from './Pages/Animation/3DAnimation/3DAnimation';
import ExplainerVideo from './Pages/Animation/ExplainerVideo/explainerVideo';
import MotionGraphics from './Pages/Animation/MotionGraphics/MotionGraphics';
import NFT from './Pages/Animation/NFT/NFT';
import Aboutus from './Pages/About/Aboutus';
import Career from './Pages/Career/Career';
import ContactForm from './Components/ContactForm';
import AppDevelopment from './Pages/Services/AppDevelopment';
import CareerForm from './Pages/Career/CareerForm';
import ThreeTwoDimenAnimations from './Pages/Animation/2D3DAnimation/2D3DAnimations';
import WebCookies from './Components/WebCookies';
import Privacypolicy from './Components/Privacypolicy';
import TermCondition from './Components/TermCondition';
import SassDevelopment from './Pages/NewService/SaaSDevelopment';
import NewHome from './Pages/Home/NewHome';
import SoftwareDevelopment from './Pages/NewService/SoftwareDevelopment';
import GenerativeAI from './Pages/NewService/GenerativeAI';
import DataEngineeringService from './Pages/NewService/DataEngineeringService';
import DataAnalytics from './Pages/NewService/DataAnalytics';
import NewFooter from './Components/NewFooter';
import './Assets/font/CircularStd-Bold.otf'
import './Assets/font/CircularStd-Book.otf'
import './Assets/font/CircularStd-Medium.otf'
import './Assets/font/DMSans-Bold.ttf'
import './Assets/font/DMSans-Medium.ttf'
import './Assets/font/DMSans-Regular.ttf'
import './Assets/font/Nunito-Bold.ttf'
import './Assets/font/Nunito-ExtraBold.ttf'
import './Assets/font/Nunito-Medium.ttf'
import './Assets/font/Nunito-Regular.ttf'



function App() {



  
  return (
    <>
      <Router>
        <Header/>
        <Routes>
          <Route path='/' element={<NewHome/>}/>
          <Route path='/about' element={<Aboutus/>}></Route>
          <Route  path='/services/website-development-company-coimbatore' element={<WebDevelopment/>}/>
          <Route  path='/services/app-development-company-coimbatore' element={<AppDevelopment/>}/>
          <Route  path='/services/sass-development-company-coimbatore' element={<SassDevelopment/>}/>
          <Route  path='/nft-marketplace-development-company' element={<NFT/>}/>
          <Route  path='/motion-graphics-company-india' element={<MotionGraphics/>}/>
          <Route  path='/explainervideo-animation-company-india' element={<ExplainerVideo/>}/>
          <Route  path='/3d-animation-company-india' element={<ThreeDAnimation/>}/>
          <Route  path='/2d-and-3d-explainer-video-service-in-india' element={<ThreeTwoDimenAnimations/>}/>
          <Route  path='/services/software-development-company-in-india' element={<SoftwareDevelopment/>}/>
          <Route  path='/services/genai-development-company-in-india' element={<GenerativeAI/>}/>
          <Route  path='/services/data-enggineering-development-company-in-india' element={<DataEngineeringService/>}/>
          <Route  path='/services/data-analytics-development-company-in-india' element={<DataAnalytics/>}/>
          <Route  path='/career' element={<Career/>}/>
          <Route  path='/jobapply' element={<CareerForm/>}/>
          <Route  path='/contact' element={<ContactForm/>}/>
          <Route path='/bloglist' element={<Bloglist/>}/>
          <Route path='/blogs/:department/:url' element={<BlogDetail/>}/>
          <Route path='/privacy-policy' element={<Privacypolicy/>}/>
          <Route path='/term-condition' element={<TermCondition/>}/>
          {/* <Route path='/newversion' element={<Home/>}/> */}
          <Route  path='*' element={<NewHome/>}/>
        </Routes>
        {/* <WebCookies/> */}
        <NewFooter/>
      </Router>
    </>
  );
}

export default App;
