import React from 'react'
import CareerBanner from './CareerBanner'
import CareerJoin from './CareerJoin'
import CareerTab from './CareerTab'
import '../../Style/NewCareer.css'
import '../../Style/NewCareerResponsive.css'
import CareerForm from './CareerForm'
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Career = () => {
  
  return (
    <HelmetProvider>
    <Helmet>

<meta charset="utf-8" />
<meta http-equiv="X-UA-Compatible" content="IE=edge" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta name="author" content="Akkenna Animation and Technologies" />
<title>Akkenna: Custom Software Development Company USA & India</title>
<meta name="description" data-rh="true"  content="Our custom software development services offer you everything needed to create a unicorn. Based in New Jersey Agile developers with experience work on complex Full-Stack, Mobile, and Web enterprise software." />
<meta name="keywords" data-rh="true"  content="software development company in new jersey, custom software development new jersey, custom software development company new jersey, enterprise software development new jersey" />
<meta name="language" content="en" />
<meta name="revisit-after" content="7 days" />
<meta name="Expires" content="never" />
<meta name="Distribution" content="Global" />
<meta name="Rating" content="general" />
<meta name="search engines" content="ALL" />
<meta name="copyright" content="https://www.akkenna.com" />
<meta name="email" content="info@akkenna.com" />
<link rel="canonical" href="https://www.akkenna.com/" />
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.akkenna.com/" />
<meta property="og:site_name" content="https://www.akkenna.com" />
<meta property="og:image" content="https://www.clickboxagency.com/images-new/Akkenna%20OG-01-01.jpg" />
<meta property="og:title" content="Akkenna: Custom Software Development Company USA & India" />
<meta property="og:description" content="Our custom software development services offer you everything needed to create a unicorn. Based in New Jersey Agile developers with experience work on complex Full-Stack, Mobile, and Web enterprise software." />
<meta name="twitter:card" content="summary" />
<meta name="twitter:title" content="Akkenna: Custom Software Development Company USA & India" />
<meta name="twitter:description" content="Our custom software development services offer you everything needed to create a unicorn. Based in New Jersey Agile developers with experience work on complex Full-Stack, Mobile, and Web enterprise software." />
<meta name="twitter:image" content="https://www.clickboxagency.com/images-new/Akkenna%20OG-01-01.jpg" />

  </Helmet>
    <main id="career">
    <CareerBanner/>
    <CareerJoin/>
    <CareerTab/>
    <CareerForm/>
    </main>
    </HelmetProvider>
  )
}

export default Career