import React from 'react'
import Arrow from '../Assets/Image/arrow.png'
import BackgroundBtn from '../Assets/Image/btnbgimg.svg'




const CallToAction = ({ bgGradient, head, headTwo, btn }) => {

  return (

    <section className={`container-fluid hireLabelTag ${bgGradient} pt-2 pb-2`}>
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-11">
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-lg-5 HireHeading">
                <h4 className="text-white">{`${head}`}</h4>
                {
                  headTwo && (
                    <h4 className="text-white">{`${headTwo}`}</h4>
                  )
                }
              </div>
              <div className="col-lg-4 hire-arrowImg">
                <img src={Arrow} alt="arrow" />
              </div>
              <div className="col-lg-3 hirePosition">
                <img src={BackgroundBtn} alt="Backgroundarrow" />
                <div className="hirebtnbg hirePositionRelatbutton nunitoExtraBold">
                  <a href="/contact" target="_blank"><button className="whiteBtn bg-whiteButton text-smallHeading fw-bold text-center hireBtn">{`${btn}`}</button></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default CallToAction