import React, { useState, useEffect } from "react";
import axios from "axios";
import BlogTabView from "./BlogTabView";


const BlogTab = ({ list }) => {


    // const Apiurl = "https://api.akkenna.com";

    const Apiurl = "http://localhost:1337";

    const [tablist, setTabList] = useState([])


    console.log("list?.api",list);

    useEffect(() => {
        axios.get(`${Apiurl}/api/${list?.api}?populate=*`)
            .then((res) => {
                setTabList(res?.data?.data)
            })
    }, [list])




    return (
        <div className="d-flex flex-column gap-5">
            {tablist.length > 0 ? (
                tablist?.map((item,index) => (
                    <BlogTabView item={item} key={index+1} />
                ))
            ) : (
                <h4>No search data</h4>
            )}
        </div>
    )
}

export default BlogTab