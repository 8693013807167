import React from 'react'
import Location from '../../Assets/Image/career/location-icon.svg'
import job from '../../Assets/Image/career/job-type.svg'
import exprience from '../../Assets/Image/career/exprience.svg'
import time from '../../Assets/Image/career/time.svg'
import days from '../../Assets/Image/career/days.svg'
import vacancy from '../../Assets/Image/career/vacancy.svg'
import Facebook from '../../Assets/Image/career/Facebook.svg'
import Twiter from '../../Assets/Image/career/Twiter.svg'
import Linkedin from '../../Assets/Image/career/Linkedin.svg'
import Instagram from '../../Assets/Image/career/Instagram.svg'
import Whatsapp from '../../Assets/Image/career/Whatsapp.svg'
import upArrow from '../../Assets/Image/about/up-arrow.svg'




const CareerTab = () => {
    return (
        <section className="container-fluid careerOpening mt-40 pt-5 pb-5">
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center text-center">
                    <div className="col-lg-6">
                        <h3 className="text-smallHeading">Career Openings</h3>
                        <p>We’re always looking for creative, talented self-starters to join the Akkenna family. Check out our open roles below and fill out an application.</p>
                        <p>Contact HR <b>+91 74185 55204</b></p>
                    </div>
                </div>
                <div className="tech-navs align-items-start pt-4">
                    <div className="nav flex-column nav-pills circularBook " id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button className="nav-link tech-button active" id="v-pills-hr-tab" data-bs-toggle="pill" data-bs-target="#v-pills-hr" type="button" role="tab" aria-controls="v-pills-hr" aria-selected="true">HR & ADMIN(1)</button>
                        <button className="nav-link tech-button" id="v-pills-development-tab" data-bs-toggle="pill" data-bs-target="#v-pills-development" type="button" role="tab" aria-controls="v-pills-development" aria-selected="false">DEVELOPMENT(1)</button>
                        <button className="nav-link tech-button" id="v-pills-support-tab" data-bs-toggle="pill" data-bs-target="#v-pills-support" type="button" role="tab" aria-controls="v-pills-support" aria-selected="false">SUPPORT(0)</button>
                        <button className="nav-link tech-button" id="v-pills-design-tab" data-bs-toggle="pill" data-bs-target="#v-pills-design" type="button" role="tab" aria-controls="v-pills-design" aria-selected="false">DESIGN(1)</button>
                        <button className="nav-link tech-button" id="v-pills-digital-tab" data-bs-toggle="pill" data-bs-target="#v-pills-digital" type="button" role="tab" aria-controls="v-pills-digital" aria-selected="false">DIGITAL MARKETING(3)</button>

                    </div>
                    <div className="tab-content " id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-hr" role="tabpanel" aria-labelledby="v-pills-hr-tab">
                            <div className="accordion accordion-faq" id="accordionfaqExample">
                                <div className="row d-flex justify-content-center align-item-center">
                                    <div className="col-md-12">
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                                                    <div className="col-lg-3 career-heading">
                                                        <h5>Business Development Executive</h5>
                                                    </div>
                                                    <div className="col-lg-2 career-content">
                                                        <p>Experience</p>
                                                        <h5>0-2 Years</h5>
                                                    </div>
                                                    <div className="col-lg-2 career-content">
                                                        <p>Job Type</p>
                                                        <h5>Full Time</h5>
                                                    </div>
                                                    <div className="col-lg-2 career-content bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                        <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                            <div className="darkBtn">APPLY NOW</div>
                                                        </a>
                                                    </div>
                                                </button>
                                            </h3>
                                            <div id="faq-collapseOne" className="accordion-collapse collapse show" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body ">
                                                    <div className="JobSummaryCard ">
                                                        <div className="col-lg-7">
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job description</h6>
                                                                <p className="">Looking for IT sales to assist in various stages of the sales funnel, including creating awareness of new offerings, and generating leads.</p>
                                                            </div>
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Duties and Responsibilities</h6>
                                                                <ul>
                                                                    <li>Research and generate lists of potential customers</li>
                                                                    <li>To nurture the database and ensure maximum customer progression</li>
                                                                    <li>Provide input on customer briefs, presentations, and sales literature</li>
                                                                    <li>Help develop client relationships.</li>
                                                                    <li>Learn and apply sales techniques</li>
                                                                    <li>Handle CRM and maintain sales records</li>
                                                                    <li>Rotating Shift depending on region requirements</li>
                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Key Skills</h6>

                                                                <ul>
                                                                    <li>Any Graduate or MBA</li>
                                                                    <li>Ability to work in a fast-paced team environment</li>
                                                                    <li>Excellent written and verbal communication skills are required.</li>
                                                                    <li>Strong interest in the IT industry</li>
                                                                    <li>Highly organized and able to manage multiple priorities</li>
                                                                    <li>Self-motivated with a strong work ethic and a passion to make a difference</li>

                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job Type :</h6>

                                                                <ul>
                                                                    <li>Full-time</li>
                                                                </ul>
                                                            </div>


                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Experience:</h6>

                                                                <p>0 - 2 years experience in the industry</p>
                                                            </div>

                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="job-summary p-4">
                                                                <div>
                                                                    <h6 className="text-smallHeading jobSummary-heading">Job Summary</h6>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                            <img src={Location} alt="" />
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-grey">Location</p>
                                                                            <h6 className="text-smallHeading">Coimbatore</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                            <img src={job} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Job Type</p>
                                                                            <h6 className="text-smallHeading">Full Time</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                            <img src={exprience} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Experience</p>
                                                                            <h6 className="text-smallHeading">Experience: 0-2 year</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                            <img src={time} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Working Hours</p>
                                                                            <h6 className="text-smallHeading">9 AM - 6 PM</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                            <img src={days} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Working Days</p>
                                                                            <h6 className="text-smallHeading">Weekly: 6days</h6>
                                                                            <h6 className="text-smallHeading">Weekend:Saturday(alternate days leave), Sunday</h6>

                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                            <img src={vacancy} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Vacancy</p>
                                                                            <h6 className="text-smallHeading">No.of Vacancy: 01</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="shareIcon mt-5">
                                                                <h6>Share this:</h6>
                                                                <div>
                                                                    <img src={Facebook} alt="" />
                                                                    <img src={Twiter} alt="" />
                                                                    <img src={Linkedin} alt="" />
                                                                    <img src={Instagram} alt="" />
                                                                    <img src={Whatsapp} alt="" />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="col-lg-12 JobSummaryCard2">
                                                        <div className="bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                            <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                                <button className="whiteBtn bg-BlueButton  text-smallHeading fw-bold">
                                                                    APPLY NOW
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="tab-pane fade show ml-30 " id="v-pills-development" role="tabpanel" aria-labelledby="v-pills-development-tab">
                            <div className="accordion accordion-faq" id="accordionfaqExample">
                                <div className="row d-flex justify-content-center align-item-center">
                                    <div className="col-md-12">
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                                                    <div className="col-lg-3 career-heading">
                                                        <h5>Full Stack Web Developer</h5>
                                                    </div>
                                                    <div className="col-lg-2 career-content">
                                                        <p>Experience</p>
                                                        <h5>0-2 Years</h5>
                                                    </div>
                                                    <div className="col-lg-2 career-content">
                                                        <p>Job Type</p>
                                                        <h5>Full Time</h5>
                                                    </div>
                                                    <div className="col-lg-2 career-content bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                        <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                            <div className="darkBtn">APPLY NOW</div>
                                                        </a>
                                                    </div>
                                                </button>
                                            </h3>
                                            <div id="faq-collapseOne" className="accordion-collapse collapse show" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body ">
                                                    <div className="JobSummaryCard ">
                                                        <div className="col-lg-7">
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Who Are We Looking For</h6>

                                                                <p className="">We are looking for a Full Stack Web Developer who is motivated to combine the art of design with the art of programming.</p>
                                                                <p>Responsibilities will include translation of the Ul/UX design wireframes to actual code that will produce visual elements of the application. You will work with the Ul/UX designer and bridge the gap between graphical design and technical implementation, taking an active role on both sides and defining how the application looks as well as how it works.</p>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Key Requirement</h6>

                                                                <ul>
                                                                    <li>Exceptional coding skills in JavaScript (both frontend and backend)</li>
                                                                    <li>Decent proficiency in HTMLNodeJS, MongoDB/MySQL, ReactJS</li>
                                                                    <li>Proficiency and hands-on experience with one of more of the following: Backbone / ReactJS / AngularJS</li>
                                                                    <li>Implementing an elegant, intuitive user experience using latest front-end and back-end technologies</li>
                                                                    <li>Thorough knowledge of software development best practices, including coding standards, code reviews, source control management, build process, continuous integration and continuous delivery.</li>
                                                                    <li>Exceptional verbal, written and listening skills.</li>
                                                                    <li>Excellent programming skills with good understanding of Object oriented programming</li>

                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Concepts</h6>

                                                                <ul>
                                                                    <li>Share project updates in Sprint Reviews.</li>
                                                                    <li>Ability to learn new technologies quickly.</li>
                                                                    <li>Build reusable code and libraries for future use.</li>
                                                                    <li>Ensure the technical feasibility of Ul/UX designs</li>
                                                                    <li>Optimize application for maximum speed and scalability</li>

                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job Type :</h6>

                                                                <ul>
                                                                    <li>Full-time</li>
                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job Category:</h6>

                                                                <ul>
                                                                    <li>Frontend Developer</li>
                                                                </ul>
                                                            </div>
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Experience:</h6>

                                                                <p>0 - 2 years experience in the industry</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="job-summary p-4">
                                                                <div>
                                                                    <h6 className="text-smallHeading jobSummary-heading">Job Summary</h6>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={Location} alt="" />
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-grey">Location</p>
                                                                            <h6 className="text-smallHeading">Coimbatore</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={job} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Job Type</p>
                                                                            <h6 className="text-smallHeading">Full Time</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={exprience} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Experience</p>
                                                                            <h6 className="text-smallHeading">Experience: 0-2 year</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={time} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Working Hours</p>
                                                                            <h6 className="text-smallHeading">9 AM - 6 PM</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={days} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Working Days</p>
                                                                            <h6 className="text-smallHeading">Weekly: 6days</h6>
                                                                            <h6 className="text-smallHeading">Weekend:Saturday(alternate days leave), Sunday</h6>

                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={vacancy} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Vacancy</p>
                                                                            <h6 className="text-smallHeading">No.of Vacancy: 01</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="shareIcon mt-5">
                                                                <h6>Share this:</h6>
                                                                <div>
                                                                    <img src={Facebook} alt="" />
                                                                    <img src={Twiter} alt="" />
                                                                    <img src={Linkedin} alt="" />
                                                                    <img src={Instagram} alt="" />
                                                                    <img src={Whatsapp} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="col-lg-12 JobSummaryCard2">
                                                        <div className="bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                            <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                                <button className="whiteBtn bg-BlueButton  text-smallHeading fw-bold">
                                                                    APPLY NOW
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show ml-30 " id="v-pills-support" role="tabpanel" aria-labelledby="v-pills-support-tab">
                            <div style={{backgroundColor:"#ffffff",color:"red"}} className="pt-5 pb-5">
                                <h4 style={{paddingLeft:"10px"}}>At Akkenna Animation and Technologies there are no support positions available at this time. </h4>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div className="tab-pane fade show ml-30 " id="v-pills-design" role="tabpanel" aria-labelledby="v-pills-design-tab">
                            <div className="accordion accordion-faq" id="designExample">
                                <div className="row d-flex justify-content-center align-item-center">
                                    <div className="col-md-12">
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                                                    <div className="col-lg-3 career-heading">
                                                        <h5>UI UX Designer</h5>
                                                    </div>
                                                    <div className="col-lg-2 career-content">
                                                        <p>Experience</p>
                                                        <h5>2 Years</h5>
                                                    </div>
                                                    <div className="col-lg-2 career-content">
                                                        <p>Job Type</p>
                                                        <h5>Full Time</h5>
                                                    </div>
                                                    <div className="col-lg-2 career-content bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                        <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                            <div className="darkBtn">APPLY NOW</div>
                                                        </a>
                                                    </div>
                                                </button>
                                            </h3>
                                            <div id="faq-collapseOne" className="accordion-collapse collapse show" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body ">
                                                    <div className="JobSummaryCard ">
                                                        <div className="col-lg-7">
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Who Are We Looking For</h6>

                                                                <p>A strong portfolio of illustrations or graphics will be preferred Familiarity with design software and technologies (such as Figma, Invision, Sketch, InDesign, Illustrator, Adobe XD, Photoshop)</p>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>A keen eye for aesthetics and details</h6>

                                                                <ul>
                                                                    <li>Excellent communication skills</li>
                                                                    <li>Ability to work me thodically and meet deadlines</li>
                                                                    <li>Layout skills</li>
                                                                    <li>Analytical skills</li>
                                                                    <li>Creativity</li>
                                                                    <li>Flexibility</li>
                                                                    <li>Attention to detail</li>
                                                                    <li>Deadline-oriented</li>
                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Responsibilities</h6>

                                                                <ul>
                                                                    <li>Develop design solutions for various platforms</li>
                                                                    <li>Establish consistent brand and creative designs</li>
                                                                    <li>Communicate ideas with project managers using mock-up and look books</li>
                                                                    <li>Collect feedback from project managers and adjust approach to cater to criteria</li>
                                                                    <li>Build sitemaps, wireframes and prototypes to outline structure</li>
                                                                    <li>Passionate with UI UX design and professional digital, sensitive with the latest design trends and understand how to apply on the business</li>
                                                                    <li>Familiar with Adobe design software, especially in XD, AE, InDesign, Illustrator and Photoshop</li>
                                                                    <li>Have learning ability, team spirit, and the sense of responsibility</li>
                                                                </ul>
                                                            </div>
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job Type :</h6>

                                                                <ul>
                                                                    <li>Full-time</li>
                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Experience:</h6>

                                                                <ul>
                                                                    <li>Minimum 0-2 Years</li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="job-summary p-4">
                                                                <div>
                                                                    <h6 className="text-smallHeading jobSummary-heading">Job Summary</h6>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={Location} alt="" />
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-grey">Location</p>
                                                                            <h6 className="text-smallHeading">Coimbatore</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={job} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Job Type</p>
                                                                            <h6 className="text-smallHeading">Full Time</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={exprience} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Experience</p>
                                                                            <h6 className="text-smallHeading">Experience: 0-2 year</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={time} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Working Hours</p>
                                                                            <h6 className="text-smallHeading">9 AM - 6 PM</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={days} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Working Days</p>
                                                                            <h6 className="text-smallHeading">Weekly: 6days</h6>
                                                                            <h6 className="text-smallHeading">Weekend:Saturday(alternate days leave), Sunday</h6>

                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={vacancy} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Vacancy</p>
                                                                            <h6 className="text-smallHeading">No.of Vacancy: 01</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="shareIcon mt-5">
                                                                <h6>Share this:</h6>
                                                                <div>
                                                                <img src={Facebook} alt="" />
                                                                    <img src={Twiter} alt="" />
                                                                    <img src={Linkedin} alt="" />
                                                                    <img src={Instagram} alt="" />
                                                                    <img src={Whatsapp} alt="" />

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="col-lg-12 JobSummaryCard2">
                                                        <div className="bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                            <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                                <button className="whiteBtn bg-BlueButton  text-smallHeading fw-bold">
                                                                    APPLY NOW
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show ml-30" id="v-pills-digital" role="tabpanel" aria-labelledby="v-pills-digital-tab">
                            <div className="accordion accordion-faq" id="accordionfaqExample">
                                <div className="row d-flex justify-content-center align-item-center">
                                    <div className="col-md-12">
                                        <div className="accordion-item">
                                            <h3 className="accordion-header" id="faq-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                                                    <div className="col-lg-3 career-heading">
                                                        <h5 >Digital Marketing Manager</h5>
                                                    </div>
                                                    <div className="col-lg-2 career-content">
                                                        <p>Experience</p>
                                                        <h5>2-3 Years</h5>
                                                    </div>
                                                    <div className="col-lg-2 career-content">
                                                        <p>Job Type</p>
                                                        <h5>Full Time</h5>
                                                    </div>
                                                    <div className="col-lg-2 career-content bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                        <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                            <div className="darkBtn">APPLY NOW</div>
                                                        </a>
                                                    </div>
                                                </button>
                                            </h3>
                                            <div id="faq-collapseOne" className="accordion-collapse collapse show" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                                                <div className="accordion-body ">
                                                    <div className="JobSummaryCard">
                                                        <div className="col-lg-7">
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Who Are We Looking For</h6>

                                                                <p>The ideal candidate will expand the company's brand presence by finding the target audience to distribute marketing content. You will create and implement the marketing strategy using both offline and online methods to gain new customers. The ideal candidate is both a self-motivated individual and a positive team player</p>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Responsibilities</h6>

                                                                <ul>
                                                                    <li>Plan and execute all digital marketing strategies, including campaigns for email, social media, SEO/SEM, and display advertising.</li>
                                                                    <li>building, constructing, and maintaining our social media presence</li>
                                                                    <li>Every digital marketing campaign is measured, reported, and assessed based on predetermined goals (ROI and KPIs)</li>
                                                                    <li>Uncover trends and insights, then modify expenditures and output based on the acquired information.</li>
                                                                    <li>Develop fresh and creative growth strategies</li>
                                                                    <li>Plan, execute, and evaluate scientific studies and conversion tests.</li>
                                                                    <li>Develop landing pages with internal departments that enhance the user experience.</li>
                                                                    <li>Using your exceptional analytic abilities, evaluate the whole client experience across all channels and touchpoints.</li>
                                                                    <li>Improve funnels and conversion points for users</li>
                                                                    <li>collaborate with companies and other vendor partners</li>
                                                                    <li>Evaluate innovative technologies. If applicable, exhibit thought leadership and an adoption viewpoint.</li>

                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Qualifications</h6>

                                                                <ul>
                                                                    <li>2 - 3 years of marketing experience</li>
                                                                    <li>Content creation skills</li>
                                                                    <li>Excellent communication and organizational skills</li>
                                                                </ul>

                                                                <div className="pb-3 JobSummaryCard-content">
                                                                    <h6>Job Type :</h6>

                                                                    <ul>
                                                                        <li>Full-time</li>
                                                                    </ul>
                                                                </div>

                                                                <div className="pb-3 JobSummaryCard-content">
                                                                    <h6>Experience:</h6>

                                                                    <ul>
                                                                        <li>Minimum 2-3 Years</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="job-summary p-4">
                                                                <div>
                                                                    <h6 className="text-smallHeading jobSummary-heading">Job Summary</h6>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={Location} alt="" />
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-grey">Location</p>
                                                                            <h6 className="text-smallHeading">Coimbatore</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={job} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Job Type</p>
                                                                            <h6 className="text-smallHeading">Full Time</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={exprience} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Experience</p>
                                                                            <h6 className="text-smallHeading">Experience: 0-2 year</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={time} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Working Hours</p>
                                                                            <h6 className="text-smallHeading">9 AM - 6 PM</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={days} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Working Days</p>
                                                                            <h6 className="text-smallHeading">Weekly: 6days</h6>
                                                                            <h6 className="text-smallHeading">Weekend:Saturday(alternate days leave), Sunday</h6>

                                                                        </div>
                                                                    </div>
                                                                    <div className="summary-content mt-3">
                                                                        <div>
                                                                        <img src={vacancy} alt="" />
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="text-grey">Vacancy</p>
                                                                            <h6 className="text-smallHeading">No.of Vacancy: 01</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="shareIcon mt-5">
                                                                <h6>Share this:</h6>
                                                                <div>
                                                                <img src={Facebook} alt="" />
                                                                    <img src={Twiter} alt="" />
                                                                    <img src={Linkedin} alt="" />
                                                                    <img src={Instagram} alt="" />
                                                                    <img src={Whatsapp} alt="" />

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="col-lg-12 JobSummaryCard2">
                                                        <div className="bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                            <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                                <button className="whiteBtn bg-BlueButton  text-smallHeading fw-bold">
                                                                    APPLY NOW
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header" id="faq-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
                                                <div className="col-lg-3 career-heading">
                                                    <h5 >Digital Marketing Executive</h5>
                                                </div>
                                                <div className="col-lg-2 career-content">
                                                    <p>Experience</p>
                                                    <h5>1-2 Years</h5>
                                                </div>
                                                <div className="col-lg-2 career-content">
                                                    <p>Job Type</p>
                                                    <h5>Full Time</h5>
                                                </div>
                                                <div className="col-lg-2 career-content bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                    <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                        <div className="darkBtn">APPLY NOW</div>
                                                    </a>
                                                </div>
                                            </button>
                                        </h3>
                                        <div id="faq-collapseTwo" className="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#accordionfaqExample">
                                            <div className="accordion-body ">
                                                <div className="JobSummaryCard ">
                                                    <div className="col-lg-7">
                                                        <div className="pb-3 JobSummaryCard-content">
                                                            <h6>Who Are We Looking For</h6>

                                                            <p>he ideal candidate will oversee the online marketing strategy for the organization by planning and executing digital marketing campaigns. This candidate will launch advertisements and create content to increase brand awareness. This candidate will have previous marketing experience and be able to monitor the company's social media presence.</p>
                                                        </div>

                                                        <div className="pb-3 JobSummaryCard-content">
                                                            <h6>Responsibilities</h6>

                                                            <ul>
                                                                <li>Plan and monitor the ongoing company presence on social media (LinkedIn, Facebook, etc. ).</li>
                                                                <li>Launch optimized online adverts through Google Adwords, Facebook, etc. to increase company and brand awareness.</li>
                                                                <li>Be actively involved in SEO efforts (keyword, image optimization, etc. ).</li>
                                                                <li>Prepare online newsletters and promotional emails and organize their distribution through various channels.</li>
                                                                <li>Provide creative ideas for content marketing and update the website.</li>
                                                                <li>Collaborate with designers to improve user experience.</li>
                                                                <li>Measure the performance of digital marketing efforts using a variety of Web analytics tools (Google Analytics, WebTrends, etc. ).</li>
                                                                <li>Acquire insight into online marketing trends and keep strategies up-to-date.</li>
                                                            </ul>
                                                        </div>

                                                        <div className="pb-3 JobSummaryCard-content">
                                                            <h6>Requirements</h6>

                                                            <ul>
                                                                <li>1 + years of Digital Marketing Executive or similar role.</li>
                                                                <li>Excellent understanding of digital marketing concepts and best practices.</li>
                                                                <li>Experience with B2B social media, Google Adwords and email campaigns, and SEO/SEM.</li>
                                                                <li>Perfect knowledge of web analytics tools (e. g. Google Analytics, NetInsight, Web Trends, etc. ).</li>
                                                                <li>Skills and experience in creative content writing</li>
                                                                <li>Analytical mindset and critical thinking.</li>
                                                                <li>Excellent communication and interpersonal skills.</li>

                                                            </ul>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job Type :</h6>

                                                                <ul>
                                                                    <li>Full-time</li>
                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Experience:</h6>

                                                                <ul>
                                                                    <li>Minimum 1-2 Years</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="job-summary p-4">
                                                            <div>
                                                                <h6 className="text-smallHeading jobSummary-heading">Job Summary</h6>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={Location} alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-grey">Location</p>
                                                                        <h6 className="text-smallHeading">Coimbatore</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={job} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Job Type</p>
                                                                        <h6 className="text-smallHeading">Full Time</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={exprience} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Experience</p>
                                                                        <h6 className="text-smallHeading">Experience: 0-2 year</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={time} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Hours</p>
                                                                        <h6 className="text-smallHeading">9 AM - 6 PM</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={days} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Days</p>
                                                                        <h6 className="text-smallHeading">Weekly: 6days</h6>
                                                                        <h6 className="text-smallHeading">Weekend:Saturday(alternate days leave), Sunday</h6>

                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={vacancy} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Vacancy</p>
                                                                        <h6 className="text-smallHeading">No.of Vacancy: 01</h6>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="shareIcon mt-5">
                                                            <h6>Share this:</h6>
                                                            <div>
                                                            <img src={Facebook} alt="" />
                                                                    <img src={Twiter} alt="" />
                                                                    <img src={Linkedin} alt="" />
                                                                    <img src={Instagram} alt="" />
                                                                    <img src={Whatsapp} alt="" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="col-lg-12 JobSummaryCard2">
                                                    <div className="bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                        <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                            <button className="whiteBtn bg-BlueButton  text-smallHeading fw-bold">
                                                                APPLY NOW
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header" id="faq-headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
                                                <div className="col-lg-3 career-heading">
                                                    <h5>Content Writer</h5>
                                                </div>
                                                <div className="col-lg-2 career-content">
                                                    <p>Experience</p>
                                                    <h5>0-2 Years</h5>
                                                </div>
                                                <div className="col-lg-2 career-content">
                                                    <p>Job Type</p>
                                                    <h5>Full Time</h5>
                                                </div>
                                                <div className="col-lg-2 career-content bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                    <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                        <div className="darkBtn">APPLY NOW</div>
                                                    </a>
                                                </div>
                                            </button>
                                        </h3>
                                        <div id="faq-collapseThree" className="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#accordionfaqExample">
                                            <div className="accordion-body ">
                                                <div className="JobSummaryCard ">
                                                    <div className="col-lg-7">
                                                        <div className="pb-3 JobSummaryCard-content">
                                                            <h6>Who Are We Looking For</h6>

                                                            <p>We are seeking a Content Writer to join our editorial team and contribute fresh blog entries, tutorials, and marketing copy to our websites.</p>
                                                            <p>Content Writer duties involve performing extensive research on industry-related subjects, producing ideas for new content categories, and proofreading pieces before publishing. If you have experience creating web material and an eye for detail, we would like to meet you. Provide samples of your published work or a portfolio of your published papers with your application.</p>
                                                            <p>You will ultimately produce high-quality content that appeals to our audiences, attracts consumers, and increases brand recognition.</p>

                                                        </div>

                                                        <div className="pb-3 JobSummaryCard-content">
                                                            <h6>Duties and Responsibilities</h6>

                                                            <ul>
                                                                <li>Investigate industry-related subjects (combining online sources, interviews and studies)</li>
                                                                <li>Create marketing text that effectively promotes our products/services.</li>
                                                                <li>With Content Management Systems, create well-structured draughts.</li>
                                                                <li>Edit and proofread blog entries before publishing.</li>
                                                                <li>Submit work for feedback and approval to editors</li>
                                                                <li>Collaborate with marketing and design teams to illustrate content. Perform simple keyword analysis and use SEO best practices to enhance website traffic.</li>
                                                                <li>Promote information on social media</li>
                                                                <li>Assess client demands and content gaps and suggest new subjects.</li>
                                                                <li>Verify global consistencies (style, fonts, images and tone)</li>
                                                                <li>Refresh website content as necessary</li>

                                                            </ul>
                                                        </div>

                                                        <div className="pb-3 JobSummaryCard-content">
                                                            <h6>Requirements and skills</h6>

                                                            <ul>
                                                                <li>Proven track record of success in the field as a Content Writer, Copywriter, or related role.</li>
                                                                <li>The ability to do research from several sources</li>
                                                                <li>Familiarity with posting on the web</li>
                                                                <li>Expertise in writing and editing in English</li>
                                                                <li>Technical expertise in content management systems (e.g. WordPress)</li>
                                                                <li>Having the ability to consistently meet deadlines</li>
                                                                <li>Excellent communication and interpersonal skills.</li>

                                                            </ul>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job Type :</h6>

                                                                <ul>
                                                                    <li>Full-time</li>
                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Experience:</h6>

                                                                <ul>
                                                                    <li>Minimum 1-2 Years</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="job-summary p-4">
                                                            <div>
                                                                <h6 className="text-smallHeading jobSummary-heading">Job Summary</h6>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={Location} alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-grey">Location</p>
                                                                        <h6 className="text-smallHeading">Coimbatore</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={job} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Job Type</p>
                                                                        <h6 className="text-smallHeading">Full Time</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={exprience} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Experience</p>
                                                                        <h6 className="text-smallHeading">Experience: 0-2 year</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={time} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Hours</p>
                                                                        <h6 className="text-smallHeading">9 AM - 6 PM</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={days} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Days</p>
                                                                        <h6 className="text-smallHeading">Weekly: 6days</h6>
                                                                        <h6 className="text-smallHeading">Weekend:Saturday(alternate days leave), Sunday</h6>

                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={vacancy} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Vacancy</p>
                                                                        <h6 className="text-smallHeading">No.of Vacancy: 01</h6>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="shareIcon mt-5">
                                                            <h6>Share this:</h6>
                                                            <div>
                                                            <img src={Facebook} alt="" />
                                                                    <img src={Twiter} alt="" />
                                                                    <img src={Linkedin} alt="" />
                                                                    <img src={Instagram} alt="" />
                                                                    <img src={Whatsapp} alt="" />
                                                                    
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="col-lg-12 JobSummaryCard2">
                                                        <div className="bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                            <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                                <button className="whiteBtn bg-BlueButton  text-smallHeading fw-bold">
                                                                    APPLY NOW
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container tech-mobile mt-20">

                <div data-bs-spy="scroll" className="align-items-start  ">
                    <div className="nav flex-column circularBook nav-pills mx-50 techMobile-Nav" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button className="nav-link tech-button active" id="v-pills-hr-tab" data-bs-toggle="pill" data-bs-target="#v-pills-hr-res" type="button" role="tab" aria-controls="v-pills-hr" aria-selected="true">
                            <p>HR & ADMIN {" "} (1)</p>
                            <div>
                                <img src={upArrow} />
                            </div>
                        </button>
                        <button className="nav-link tech-button" id="v-pills-development-tab" data-bs-toggle="pill" data-bs-target="#v-pills-development-res" type="button" role="tab" aria-controls="v-pills-development" aria-selected="false">
                            <p>DEVELOPMENT {" "} (1) </p>
                            <div>
                                <img src={upArrow} />
                            </div>
                        </button>
                        <button className="nav-link tech-button" id="v-pills-support-tab" data-bs-toggle="pill" data-bs-target="#v-pills-support-res" type="button" role="tab" aria-controls="v-pills-support" aria-selected="false">
                            <p>SUPPORT {" "} (0)</p>
                            <div>
                                <img src={upArrow} />
                            </div>
                        </button>
                        <button className="nav-link tech-button" id="v-pills-design-tab" data-bs-toggle="pill" data-bs-target="#v-pills-design-res" type="button" role="tab" aria-controls="v-pills-design" aria-selected="false">
                            <p>DESIGN {" "} (1)</p>
                            <div>
                                <img src={upArrow} />
                            </div>
                        </button>
                        <button className="nav-link tech-button" id="v-pills-digital-tab" data-bs-toggle="pill" data-bs-target="#v-pills-digital-res" type="button" role="tab" aria-controls="v-pills-digital" aria-selected="false">
                            DIGITAL MARKETING{" "}(3)
                            <div>
                                <img src={upArrow} />
                            </div>
                        </button>

                    </div>
                    <div className="tab-content tabScroll mt-50 circularMedium" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-hr-res" role="tabpanel" aria-labelledby="v-pills-hr-tab">
                            <div className="col-lg-6">
                                <div className="accordion accordion-faq" id="accordionfaqExample">
                                    <div className="row d-flex justify-content-center align-item-center">
                                        <div className="col-lg-6">
                                            <div className="accordion-item">
                                                <h3 className="accordion-header" id="faq-headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                                                        <div>
                                                            <div className="col-lg-3 career-heading">
                                                                <h5>Business Development Executive</h5>
                                                            </div>
                                                            <div className="d-flex  justify-content-between">
                                                                <div className="col-lg-2 career-content">
                                                                    <p>Experience</p>
                                                                    <h5>0-2 Years</h5>
                                                                </div>
                                                                <div className="col-lg-2 career-content">
                                                                    <p>Job Type</p>
                                                                    <h5>Full Time</h5>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </button>
                                                </h3>
                                                <div id="faq-collapseOne" className="accordion-collapse collapse" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                                                    <div className="accordion-body ">
                                                        <div className="res-JobSummaryCard ">
                                                            <div>
                                                                <h6 className="jobSummary-heading">Job Summary</h6>
                                                            </div>
                                                            <div className="jobSummary-content">
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={Location} alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-grey">Location</p>
                                                                        <h6>Coimbatore</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={job} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Job Type</p>
                                                                        <h6>Full Time</h6>
                                                                    </div>
                                                                </div>

                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={exprience} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Experience</p>
                                                                        <h6>Experience: 1-3 year</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={time} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Hours</p>
                                                                        <h6>9 AM - 6 PM</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={days} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Vacancy</p>
                                                                        <h6>No.of Vacancy: 03</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={vacancy} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Days</p>
                                                                        <h6>Weekly: 6days</h6>
                                                                        <h6>Weekend:Saturday(alternate days leave), Sunday</h6>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="mt-5">
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job description</h6>

                                                                <p className="">Looking for IT sales to assist in various stages of the sales funnel, including creating awareness of new offerings, and generating leads.</p>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Duties and Responsibilities</h6>

                                                                <ul>
                                                                    <li>Research and generate lists of potential customers</li>
                                                                    <li>To nurture the database and ensure maximum customer progression</li>
                                                                    <li>Provide input on customer briefs, presentations, and sales literature</li>
                                                                    <li>Help develop client relationships.</li>
                                                                    <li>Learn and apply sales techniques</li>
                                                                    <li>Handle CRM and maintain sales records</li>
                                                                    <li>Rotating Shift depending on region requirements</li>

                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Key Skills</h6>

                                                                <ul>
                                                                    <li>Any Graduate or MBA</li>
                                                                    <li>Ability to work in a fast-paced team environment</li>
                                                                    <li>Excellent written and verbal communication skills are required.</li>
                                                                    <li>Strong interest in the IT industry</li>
                                                                    <li>Highly organized and able to manage multiple priorities</li>
                                                                    <li>Self-motivated with a strong work ethic and a passion to make a difference</li>

                                                                </ul>
                                                            </div>
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job Type :</h6>

                                                                <ul>
                                                                    <li>Full-time</li>
                                                                </ul>
                                                            </div>


                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Experience:</h6>

                                                                <p>0 - 2 years experience in the industry</p>
                                                            </div>


                                                        </div>
                                                        <hr />

                                                        <div className="mt-5">

                                                            <div className="bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                                <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                                    <button className="whiteBtn bg-BlueButton  text-smallHeading fw-bold">
                                                                        APPLY NOW
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="shareIcon mt-5">
                                                            <h6>Share this:</h6>
                                                            <div>
                                                            <img src={Facebook} alt="" />
                                                                    <img src={Twiter} alt="" />
                                                                    <img src={Linkedin} alt="" />
                                                                    <img src={Instagram} alt="" />
                                                                    <img src={Whatsapp} alt="" />

                                                            </div>
                                                        </div>

                                                        <div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show ml-30 circularMedium" id="v-pills-development-res" role="tabpanel" aria-labelledby="v-pills-development-tab">
                            <div className="col-lg-6">
                                <div className="accordion accordion-faq" id="developmentExample">
                                    <div className="row d-flex justify-content-center align-item-center">
                                        <div className="col-lg-6">
                                            <div className="accordion-item">
                                                <h3 className="accordion-header" id="faq-headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                                                        <div>
                                                            <div className="col-lg-3 career-heading">
                                                                <h5 >Frond-end Developer</h5>
                                                            </div>
                                                            <div className="d-flex  justify-content-between">
                                                                <div className="col-lg-2 career-content">
                                                                    <p>Experience</p>
                                                                    <h5>0-2 Years</h5>
                                                                </div>
                                                                <div className="col-lg-2 career-content">
                                                                    <p>Job Type</p>
                                                                    <h5>Full Time</h5>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </button>
                                                </h3>
                                                <div id="faq-collapseOne" className="accordion-collapse collapse" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                                                    <div className="accordion-body ">
                                                        <div className="res-JobSummaryCard ">
                                                            <div>
                                                                <h6 className="jobSummary-heading">Job Summary</h6>
                                                            </div>
                                                            <div className="jobSummary-content">
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                       <img src={Location} alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-grey">Location</p>
                                                                        <h6>Coimbatore</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                         <img src={job} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Job Type</p>
                                                                        <h6>Full Time</h6>
                                                                    </div>
                                                                </div>

                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                        <img src={exprience} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Experience</p>
                                                                        <h6>Experience: 0-2 year</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                                                                                            <img src={time} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Hours</p>
                                                                        <h6>9 AM - 6 PM</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                        <img src={days} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Vacancy</p>
                                                                        <h6>No.of Vacancy: 03</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                       <img src={vacancy} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Days</p>
                                                                        <h6>Weekly: 6days</h6>
                                                                        <h6>Weekend:Saturday(alternate days leave), Sunday</h6>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="mt-5">
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Who Are We Looking For</h6>

                                                                <p className="">We are looking for a Full Stack Web Developer who is motivated to combine the art of design with the art of programming.</p>
                                                                <p>Responsibilities will include translation of the Ul/UX design wireframes to actual code that will produce visual elements of the application. You will work with the Ul/UX designer and bridge the gap between graphical design and technical implementation, taking an active role on both sides and defining how the application looks as well as how it works.</p>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Key Requirement</h6>

                                                                <ul>
                                                                    <li>Exceptional coding skills in JavaScript (both frontend and backend)</li>
                                                                    <li>Decent proficiency in HTMLNodeJS, MongoDB/MySQL, ReactJS</li>
                                                                    <li>Proficiency and hands-on experience with one of more of the following: Backbone / ReactJS / AngularJS</li>
                                                                    <li>Implementing an elegant, intuitive user experience using latest front-end and back-end technologies</li>
                                                                    <li>Thorough knowledge of software development best practices, including coding standards, code reviews, source control management, build process, continuous integration and continuous delivery.</li>
                                                                    <li>Exceptional verbal, written and listening skills.</li>
                                                                    <li>Excellent programming skills with good understanding of Object oriented programming</li>

                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Concepts</h6>

                                                                <ul>
                                                                    <li>Share project updates in Sprint Reviews.</li>
                                                                    <li>Ability to learn new technologies quickly.</li>
                                                                    <li>Build reusable code and libraries for future use.</li>
                                                                    <li>Ensure the technical feasibility of Ul/UX designs</li>
                                                                    <li>Optimize application for maximum speed and scalability</li>

                                                                </ul>
                                                            </div>
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job Type :</h6>

                                                                <ul>
                                                                    <li>Full-time</li>
                                                                </ul>
                                                            </div>
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job Category:</h6>

                                                                <ul>
                                                                    <li>Frontend Developer</li>
                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Experience:</h6>

                                                                <p>0 - 2 years experience in the industry</p>
                                                            </div>


                                                        </div>
                                                        <hr />

                                                        <div className="mt-5">
                                                            <div className="bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                                <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                                    <button className="whiteBtn bg-BlueButton  text-smallHeading fw-bold">
                                                                        APPLY NOW
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="shareIcon mt-5">
                                                            <h6>Share this:</h6>
                                                            <div>
                                                            <img src={Facebook} alt="" />
                                                                    <img src={Twiter} alt="" />
                                                                    <img src={Linkedin} alt="" />
                                                                    <img src={Instagram} alt="" />
                                                                    <img src={Whatsapp} alt="" />

                                                            </div>
                                                        </div>

                                                        <div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show ml-30 circularMedium" id="v-pills-support-res" role="tabpanel" aria-labelledby="v-pills-support-tab">

                            <div style={{backgroundColor:"#ffffff",color:"red"}} className="pt-5 pb-5">
                                <h4 style={{paddingLeft:"10px"}}>At Akkenna Animation and Technologies there are no support positions available at this time. </h4>

                            </div>
                        </div>
                        <div className="tab-pane fade show ml-30 circularMedium" id="v-pills-design-res" role="tabpanel" aria-labelledby="v-pills-design-tab">
                            <div className="col-lg-6">
                                <div className="accordion accordion-faq" id="developmentExample">
                                    <div className="row d-flex justify-content-center align-item-center">
                                        <div className="col-lg-6">
                                            <div className="accordion-item">
                                                <h3 className="accordion-header" id="faq-headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                                                        <div>
                                                            <div className="col-lg-3 career-heading">
                                                                <h5>UI UX Designer</h5>
                                                            </div>
                                                            <div className="d-flex  justify-content-between">
                                                                <div className="col-lg-2 career-content">
                                                                    <p>Experience</p>
                                                                    <h5>0-2 Years</h5>
                                                                </div>
                                                                <div className="col-lg-2 career-content">
                                                                    <p>Job Type</p>
                                                                    <h5>Full Time</h5>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </button>
                                                </h3>
                                                <div id="faq-collapseOne" className="accordion-collapse collapse" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                                                    <div className="accordion-body ">
                                                        <div className="res-JobSummaryCard ">
                                                            <div>
                                                                <h6 className="jobSummary-heading">Job Summary</h6>
                                                            </div>
                                                            <div className="jobSummary-content">
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                       <img src={Location} alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-grey">Location</p>
                                                                        <h6>Coimbatore</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                         <img src={job} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Job Type</p>
                                                                        <h6>Full Time</h6>
                                                                    </div>
                                                                </div>

                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                        <img src={exprience} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Experience</p>
                                                                        <h6>Experience: 0-2 year</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                                                                                            <img src={time} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Hours</p>
                                                                        <h6>9 AM - 6 PM</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                        <img src={days} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Vacancy</p>
                                                                        <h6>No.of Vacancy: 03</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                       <img src={vacancy} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Days</p>
                                                                        <h6>Weekly: 6days</h6>
                                                                        <h6>Weekend:Saturday(alternate days leave), Sunday</h6>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="mt-5">
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Who Are We Looking For</h6>

                                                                <p>A strong portfolio of illustrations or graphics will be preferred Familiarity with design software and technologies (such as Figma, Invision, Sketch, InDesign, Illustrator, Adobe XD, Photoshop)</p>

                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>A keen eye for aesthetics and details</h6>

                                                                <ul>
                                                                    <li>Excellent communication skills</li>
                                                                    <li>Ability to work me thodically and meet deadlines</li>
                                                                    <li>Layout skills</li>
                                                                    <li>Analytical skills</li>
                                                                    <li>Creativity</li>
                                                                    <li>Flexibility</li>
                                                                    <li>Attention to detail</li>
                                                                    <li>Deadline-oriented</li>
                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Responsibilities</h6>

                                                                <ul>
                                                                    <li>Develop design solutions for various platforms</li>
                                                                    <li>Establish consistent brand and creative designs</li>
                                                                    <li>Communicate ideas with project managers using mock-up and look books</li>
                                                                    <li>Collect feedback from project managers and adjust approach to cater to criteria</li>
                                                                    <li>Build sitemaps, wireframes and prototypes to outline structure</li>
                                                                    <li>Passionate with UI UX design and professional digital, sensitive with the latest design trends and understand how to apply on the business</li>
                                                                    <li>Familiar with Adobe design software, especially in XD, AE, InDesign, Illustrator and Photoshop</li>
                                                                    <li>Have learning ability, team spirit, and the sense of responsibility</li>
                                                                </ul>
                                                            </div>
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job Type :</h6>

                                                                <ul>
                                                                    <li>Full-time</li>
                                                                </ul>
                                                            </div>


                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Experience:</h6>

                                                                <p>0 - 2 years experience in the industry</p>
                                                            </div>


                                                        </div>
                                                        <hr />

                                                        <div className="mt-5">
                                                            <div className="bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                                <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                                    <button className="whiteBtn bg-BlueButton  text-smallHeading fw-bold">
                                                                        APPLY NOW
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="shareIcon mt-5">
                                                            <h6>Share this:</h6>
                                                            <div>
                                                                <img src="./assets/image/career/Facebook.svg" alt="" />
                                                                <img src="./assets/image/career/Twiter.svg" alt="" />
                                                                <img src="./assets/image/career/Linkedin.svg" alt="" />
                                                                <img src="./assets/image/career/Instagram.svg" alt="" />
                                                                <img src="./assets/image/career/Whatsapp.svg" alt="" />

                                                            </div>
                                                        </div>

                                                        <div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show ml-30 circularMedium" id="v-pills-digital-res" role="tabpanel" aria-labelledby="v-pills-digital-tab">
                            <div className="col-lg-6">
                                <div className="accordion accordion-faq" id="developmentExample">
                                    <div className="row d-flex justify-content-center align-item-center">
                                        <div className="col-lg-6">
                                            <div className="accordion-item">
                                                <h3 className="accordion-header" id="faq-headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                                                        <div>
                                                            <div className="col-lg-3 career-heading">
                                                                <h5 >Digital Marketing Manager</h5>
                                                            </div>
                                                            <div className="d-flex  justify-content-between">
                                                                <div className="col-lg-2 career-content">
                                                                    <p>Experience</p>
                                                                    <h5>2-3 Years</h5>
                                                                </div>
                                                                <div className="col-lg-2 career-content">
                                                                    <p>Job Type</p>
                                                                    <h5>Full Time</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h3>
                                                <div id="faq-collapseOne" className="accordion-collapse collapse" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                                                    <div className="accordion-body ">
                                                        <div className="res-JobSummaryCard ">
                                                            <div>
                                                                <h6 className="jobSummary-heading">Job Summary</h6>
                                                            </div>
                                                            <div className="jobSummary-content">
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                       <img src={Location} alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-grey">Location</p>
                                                                        <h6>Coimbatore</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                         <img src={job} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Job Type</p>
                                                                        <h6>Full Time</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                        <img src={exprience} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Experience</p>
                                                                        <h6>Experience: 0-2 year</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                                                                                            <img src={time} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Hours</p>
                                                                        <h6>9 AM - 6 PM</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                        <img src={days} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Vacancy</p>
                                                                        <h6>No.of Vacancy: 03</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                       <img src={vacancy} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Days</p>
                                                                        <h6>Weekly: 6days</h6>
                                                                        <h6>Weekend:Saturday(alternate days leave), Sunday</h6>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="mt-5">
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Who Are We Looking For</h6>

                                                                <p>The ideal candidate will expand the company's brand presence by finding the target audience to distribute marketing content. You will create and implement the marketing strategy using both offline and online methods to gain new customers. The ideal candidate is both a self-motivated individual and a positive team player</p>

                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Responsibilities</h6>

                                                                <ul>
                                                                    <li>Plan and execute all digital marketing strategies, including campaigns for email, social media, SEO/SEM, and display advertising.</li>
                                                                    <li>building, constructing, and maintaining our social media presence</li>
                                                                    <li>Every digital marketing campaign is measured, reported, and assessed based on predetermined goals (ROI and KPIs)</li>
                                                                    <li>Uncover trends and insights, then modify expenditures and output based on the acquired information.</li>
                                                                    <li>Develop fresh and creative growth strategies</li>
                                                                    <li>Plan, execute, and evaluate scientific studies and conversion tests.</li>
                                                                    <li>Develop landing pages with internal departments that enhance the user experience.</li>
                                                                    <li>Using your exceptional analytic abilities, evaluate the whole client experience across all channels and touchpoints.</li>
                                                                    <li>Improve funnels and conversion points for users</li>
                                                                    <li>collaborate with companies and other vendor partners</li>
                                                                    <li>Evaluate innovative technologies. If applicable, exhibit thought leadership and an adoption viewpoint.</li>

                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Qualifications</h6>

                                                                <ul>
                                                                    <li>2 - 3 years of marketing experience</li>
                                                                    <li>Content creation skills</li>
                                                                    <li>Excellent communication and organizational skills</li>
                                                                </ul>
                                                            </div>
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job Type :</h6>

                                                                <ul>
                                                                    <li>Full-time</li>
                                                                </ul>
                                                            </div>


                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Experience:</h6>

                                                                <p>2-3 years experience in the industry</p>
                                                            </div>


                                                        </div>
                                                        <hr />

                                                        <div className="mt-5">
                                                            <div className="bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                                <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                                    <button className="whiteBtn bg-BlueButton  text-smallHeading fw-bold">
                                                                        APPLY NOW
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="shareIcon mt-5">
                                                            <h6>Share this:</h6>
                                                            <div>
                                                                <img src="./assets/image/career/Facebook.svg" alt="" />
                                                                <img src="./assets/image/career/Twiter.svg" alt="" />
                                                                <img src="./assets/image/career/Linkedin.svg" alt="" />
                                                                <img src="./assets/image/career/Instagram.svg" alt="" />
                                                                <img src="./assets/image/career/Whatsapp.svg" alt="" />

                                                            </div>
                                                        </div>

                                                        <div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="accordion-item">
                                                <h3 className="accordion-header" id="faq-headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
                                                        <div>
                                                            <div className="col-lg-3 career-heading">
                                                                <h5>Digital Marketing Executive</h5>
                                                            </div>
                                                            <div className="d-flex  justify-content-between">
                                                                <div className="col-lg-2 career-content">
                                                                    <p>Experience</p>
                                                                    <h5>1-2 Years</h5>
                                                                </div>
                                                                <div className="col-lg-2 career-content">
                                                                    <p>Job Type</p>
                                                                    <h5>Full Time</h5>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </button>
                                                </h3>
                                                <div id="faq-collapseTwo" className="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#accordionfaqExample">
                                                    <div className="accordion-body ">
                                                        <div className="res-JobSummaryCard ">
                                                            <div>
                                                                <h6 className="jobSummary-heading">Job Summary</h6>
                                                            </div>
                                                            <div className="jobSummary-content">
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                       <img src={Location} alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-grey">Location</p>
                                                                        <h6>Coimbatore</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                         <img src={job} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Job Type</p>
                                                                        <h6>Full Time</h6>
                                                                    </div>
                                                                </div>

                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                        <img src={exprience} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Experience</p>
                                                                        <h6>Experience: 0-2 year</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                    <img src={time} alt="" />                                                                   <img src={time} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Hours</p>
                                                                        <h6>9 AM - 6 PM</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                        <img src={days} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Vacancy</p>
                                                                        <h6>No.of Vacancy: 03</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                       <img src={vacancy} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Days</p>
                                                                        <h6>Weekly: 6days</h6>
                                                                        <h6>Weekend:Saturday(alternate days leave), Sunday</h6>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="mt-5">
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Who Are We Looking For</h6>

                                                                <p>he ideal candidate will oversee the online marketing strategy for the organization by planning and executing digital marketing campaigns. This candidate will launch advertisements and create content to increase brand awareness. This candidate will have previous marketing experience and be able to monitor the company's social media presence.</p>

                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Responsibilities</h6>

                                                                <ul>
                                                                    <li>Plan and monitor the ongoing company presence on social media (LinkedIn, Facebook, etc. ).</li>
                                                                    <li>Launch optimized online adverts through Google Adwords, Facebook, etc. to increase company and brand awareness.</li>
                                                                    <li>Be actively involved in SEO efforts (keyword, image optimization, etc. ).</li>
                                                                    <li>Prepare online newsletters and promotional emails and organize their distribution through various channels.</li>
                                                                    <li>Provide creative ideas for content marketing and update the website.</li>
                                                                    <li>Collaborate with designers to improve user experience.</li>
                                                                    <li>Measure the performance of digital marketing efforts using a variety of Web analytics tools (Google Analytics, WebTrends, etc. ).</li>
                                                                    <li>Acquire insight into online marketing trends and keep strategies up-to-date.</li>
                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Requirements</h6>

                                                                <ul>
                                                                    <li>1 + years of Digital Marketing Executive or similar role.</li>
                                                                    <li>Excellent understanding of digital marketing concepts and best practices.</li>
                                                                    <li>Experience with B2B social media, Google Adwords and email campaigns, and SEO/SEM.</li>
                                                                    <li>Perfect knowledge of web analytics tools (e. g. Google Analytics, NetInsight, Web Trends, etc. ).</li>
                                                                    <li>Skills and experience in creative content writing</li>
                                                                    <li>Analytical mindset and critical thinking.</li>
                                                                    <li>Excellent communication and interpersonal skills.</li>

                                                                </ul>
                                                            </div>
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job Type :</h6>

                                                                <ul>
                                                                    <li>Full-time</li>
                                                                </ul>
                                                            </div>


                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Experience:</h6>

                                                                <p>1-2 years experience in the industry</p>
                                                            </div>


                                                        </div>
                                                        <hr />

                                                        <div className="mt-5">
                                                            <div className="bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                                <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                                    <button className="whiteBtn bg-BlueButton  text-smallHeading fw-bold">
                                                                        APPLY NOW
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="shareIcon mt-5">
                                                            <h6>Share this:</h6>
                                                            <div>
                                                            <img src={Facebook} alt="" />
                                                                    <img src={Twiter} alt="" />
                                                                    <img src={Linkedin} alt="" />
                                                                    <img src={Instagram} alt="" />
                                                                    <img src={Whatsapp} alt="" />

                                                            </div>
                                                        </div>

                                                        <div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="accordion-item">
                                                <h3 className="accordion-header" id="faq-headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
                                                        <div>
                                                            <div className="col-lg-3 career-heading">
                                                                <h5>Content Writer</h5>
                                                            </div>
                                                            <div className="d-flex  justify-content-between">
                                                                <div className="col-lg-2 career-content">
                                                                    <p>Experience</p>
                                                                    <h5>0-2 Years</h5>
                                                                </div>
                                                                <div className="col-lg-2 career-content">
                                                                    <p>Job Type</p>
                                                                    <h5>Full Time</h5>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </button>
                                                </h3>
                                                <div id="faq-collapseThree" className="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#accordionfaqExample">
                                                    <div className="accordion-body ">
                                                        <div className="res-JobSummaryCard ">
                                                            <div>
                                                                <h6 className="jobSummary-heading">Job Summary</h6>
                                                            </div>
                                                            <div className="jobSummary-content">
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                       <img src={Location} alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-grey">Location</p>
                                                                        <h6>Coimbatore</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                         <img src={job} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Job Type</p>
                                                                        <h6>Full Time</h6>
                                                                    </div>
                                                                </div>

                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                        <img src={exprience} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Experience</p>
                                                                        <h6>Experience: 0-2 year</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                                                                                            <img src={time} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Hours</p>
                                                                        <h6>9 AM - 6 PM</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                        <img src={days} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Vacancy</p>
                                                                        <h6>No.of Vacancy: 03</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="summary-content mt-3">
                                                                    <div>
                                                                       <img src={vacancy} alt="" />
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-grey">Working Days</p>
                                                                        <h6>Weekly: 6days</h6>
                                                                        <h6>Weekend:Saturday(alternate days leave), Sunday</h6>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="mt-5">
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Who Are We Looking For</h6>

                                                                <p>We are seeking a Content Writer to join our editorial team and contribute fresh blog entries, tutorials, and marketing copy to our websites.</p>
                                                                <p>Content Writer duties involve performing extensive research on industry-related subjects, producing ideas for new content categories, and proofreading pieces before publishing. If you have experience creating web material and an eye for detail, we would like to meet you. Provide samples of your published work or a portfolio of your published papers with your application.</p>
                                                                <p>You will ultimately produce high-quality content that appeals to our audiences, attracts consumers, and increases brand recognition.</p>

                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Duties and Responsibilities</h6>

                                                                <ul>
                                                                    <li>Investigate industry-related subjects (combining online sources, interviews and studies)</li>
                                                                    <li>Create marketing text that effectively promotes our products/services.</li>
                                                                    <li>With Content Management Systems, create well-structured draughts.</li>
                                                                    <li>Edit and proofread blog entries before publishing.</li>
                                                                    <li>Submit work for feedback and approval to editors</li>
                                                                    <li>Collaborate with marketing and design teams to illustrate content. Perform simple keyword analysis and use SEO best practices to enhance website traffic.</li>
                                                                    <li>Promote information on social media</li>
                                                                    <li>Assess client demands and content gaps and suggest new subjects.</li>
                                                                    <li>Verify global consistencies (style, fonts, images and tone)</li>
                                                                    <li>Refresh website content as necessary</li>

                                                                </ul>
                                                            </div>

                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Requirements and skills</h6>

                                                                <ul>
                                                                    <li>Proven track record of success in the field as a Content Writer, Copywriter, or related role.</li>
                                                                    <li>The ability to do research from several sources</li>
                                                                    <li>Familiarity with posting on the web</li>
                                                                    <li>Expertise in writing and editing in English</li>
                                                                    <li>Technical expertise in content management systems (e.g. WordPress)</li>
                                                                    <li>Having the ability to consistently meet deadlines</li>
                                                                    <li>Excellent communication and interpersonal skills.</li>

                                                                </ul>
                                                            </div>
                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Job Type :</h6>

                                                                <ul>
                                                                    <li>Full-time</li>
                                                                </ul>
                                                            </div>


                                                            <div className="pb-3 JobSummaryCard-content">
                                                                <h6>Experience:</h6>

                                                                <p>1-2 years experience in the industry</p>
                                                            </div>


                                                        </div>
                                                        <hr />

                                                        <div className="mt-5">
                                                            <div className="bannerBtn mt-20 d-flex justify-content-center pt-3 pb-3">
                                                                <a href="https://www.akkenna.com/jobapply" target="_blank">
                                                                    <button className="whiteBtn bg-BlueButton  text-smallHeading fw-bold">
                                                                        APPLY NOW
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="shareIcon mt-5">
                                                            <h6>Share this:</h6>
                                                            <div>
                                                            <img src={Facebook} alt="" />
                                                                    <img src={Twiter} alt="" />
                                                                    <img src={Linkedin} alt="" />
                                                                    <img src={Instagram} alt="" />
                                                                    <img src={Whatsapp} alt="" />

                                                            </div>
                                                        </div>

                                                        <div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CareerTab