import React from 'react'
import Bannerimg from '../../Assets/Image/about/banner-image.svg'
import Longterm from '../../Assets/Image/about/long-term.svg'
import Video from '../../Assets/video/AkkennaVideo.mp4'
import Benefit1 from '../../Assets/Image/about/team.svg'
import Benefit2 from '../../Assets/Image/about/increase.svg'
import Benefit3 from '../../Assets/Image/about/workspace.svg'
import Benefit4 from '../../Assets/Image/about/knowledge.svg'
import Benefit01 from '../../Assets/Image/about/team-blue.svg'
import Benefit02 from '../../Assets/Image/about/impact-blue.svg'
import Benefit03 from '../../Assets/Image/about/workspace-blue.svg'
import Benefit04 from '../../Assets/Image/about/knowledge-blue.svg'
import tech1 from '../../Assets/Image/Nodejs/Logo/react.svg'
import tech2 from '../../Assets/Image/Nodejs/Logo/express.svg'
import tech3 from '../../Assets/Image/Nodejs/Logo/socket.svg'
import tech4 from '../../Assets/Image/Nodejs/Logo/babel.svg'
import tech5 from '../../Assets/Image/Nodejs/Logo/hapi.svg'
import tech6 from '../../Assets/Image/Nodejs/Logo/axios.svg'
import tech7 from '../../Assets/Image/Nodejs/Logo/webpack.svg'
import tech2_1 from '../../Assets/Image/Nodejs/Logo/nodejs.svg'
import tech2_2 from '../../Assets/Image/Nodejs/Logo/javascript.svg'
import tech2_3 from '../../Assets/Image/Nodejs/Logo/typescript.svg'
import tech2_4 from '../../Assets/Image/Nodejs/Logo/ECMAscript.svg'
import tech2_5 from '../../Assets/Image/Nodejs/Logo/php.svg'
import tech3_1 from '../../Assets/Image/Nodejs/Logo/html5.svg'
import tech3_2 from '../../Assets/Image/Nodejs/Logo/css3.svg'
import tech3_3 from '../../Assets/Image/Nodejs/Logo/react.svg'
import tech3_4 from '../../Assets/Image/Nodejs/Logo/angular.svg'
import tech3_5 from '../../Assets/Image/Nodejs/Logo/bootstrap.svg'
import tech3_6 from '../../Assets/Image/Nodejs/Logo/jquery.svg'
import tech4_1 from '../../Assets/Image/Nodejs/Logo/redux.svg'
import tech4_2 from '../../Assets/Image/Nodejs/Logo/reactrouter.svg'
import tech5_1 from '../../Assets/Image/Nodejs/Logo/flutter.svg'
import tech5_2 from '../../Assets/Image/Nodejs/Logo/reactnative.svg'
import tech6_1 from '../../Assets/Image/Nodejs/Logo/aws.svg'
import tech6_2 from '../../Assets/Image/Nodejs/Logo/azure.svg'
import tech7_1 from '../../Assets/Image/Nodejs/Logo/cicd.svg'
import tech8_1 from '../../Assets/Image/Nodejs/Logo/mongodb.svg'
import tech8_2 from '../../Assets/Image/Nodejs/Logo/mysql.svg'
import tech8_3 from '../../Assets/Image/Nodejs/Logo/postgressql.svg'
import tech9_1 from '../../Assets/Image/Nodejs/Logo/razorpay.svg'
import tech9_2 from '../../Assets/Image/Nodejs/Logo/facebook.svg'
import tech9_3 from '../../Assets/Image/Nodejs/Logo/openwhether.svg'
import tech9_4 from '../../Assets/Image/Nodejs/Logo/instagram.svg'
import tech10_1 from '../../Assets/Image/Nodejs/Logo/jira.svg'
import profile1 from '../../Assets/Image/about/James.webp'
import profile2 from '../../Assets/Image/about/Pradeep.webp'
import profile3 from '../../Assets/Image/about/Insamam.webp'
import profile4 from '../../Assets/Image/about/Muthukumar.webp'
import profile5 from '../../Assets/Image/about/Hari.webp'
import profile6 from '../../Assets/Image/about/Dinesh.webp'
import profile7 from '../../Assets/Image/about/Karthick.webp'
import profile8 from '../../Assets/Image/about/Chandru.webp'
import profile9 from '../../Assets/Image/about/Vinoth.webp'
import festivBlog from '../../Assets/Image/home/festiv.webp'
import sigurBlog from '../../Assets/Image/home/sigur.webp'
import graphicsImgBlog from '../../Assets/Image/home/graphicsImg.webp'
import webRMSBlog from '../../Assets/Image/home/webRMS.webp'
import sarvamBlog from '../../Assets/Image/home/sarvam.webp'
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../Style/NewAbout.css'
import '../../Style/NewAboutResponsive.css'
import CallToAction from '../../Components/CallToAction'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ContactForm from '../../Components/ContactForm'
import { Helmet, HelmetProvider } from 'react-helmet-async';




const AboutIndex = () => {


  const [selectedOption, setSelectedOption] = useState('Framework');

  const showHide = (selectedValue) => {
    setSelectedOption(selectedValue);
  };
  
  var settings = {
    dots: false,
    infinite: false,
    speed: 500, 
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const options = {
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
        loop: false
      },
      600: {
        items: 2,
        nav: true,
        loop: false
      },
      800: {
        items: 2,
        nav: true,
        loop: false
      },
      1000: {
        items: 3,
        nav: true,
        loop: false
      }
    }
  }
  
  return (
<HelmetProvider>
      <Helmet>
<meta charset="utf-8" />
<meta http-equiv="X-UA-Compatible" content="IE=edge" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta name="author" content="Akkenna Animation and Technologies" />
<title>Akkenna: Custom Software Development Company USA & India</title>
<meta name="description" data-rh="true" content="Our custom software development services offer you everything needed to create a unicorn. Based in New Jersey Agile developers with experience work on complex Full-Stack, Mobile, and Web enterprise software." />
<meta name="keywords" data-rh="true" content="software development company in new jersey, custom software development new jersey, custom software development company new jersey, enterprise software development new jersey" />
<meta name="language" content="en" />
<meta name="revisit-after" content="7 days" />
<meta name="Expires" content="never" />
<meta name="Distribution" content="Global" />
<meta name="Rating" content="general" />
<meta name="search engines" content="ALL" />
<meta name="copyright" content="https://www.akkenna.com" />
<meta name="email" content="info@akkenna.com" />
<link rel="canonical" href="https://www.akkenna.com/" />
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.akkenna.com/" />
<meta property="og:site_name" content="https://www.akkenna.com" />
<meta property="og:image" content="https://www.clickboxagency.com/images-new/Akkenna%20OG-01-01.jpg" />
<meta property="og:title" content="Akkenna: Custom Software Development Company USA & India" />
<meta property="og:description" content="Our custom software development services offer you everything needed to create a unicorn. Based in New Jersey Agile developers with experience work on complex Full-Stack, Mobile, and Web enterprise software." />
<meta name="twitter:card" content="summary" />
<meta name="twitter:title" content="Akkenna: Custom Software Development Company USA & India" />
<meta name="twitter:description" content="Our custom software development services offer you everything needed to create a unicorn. Based in New Jersey Agile developers with experience work on complex Full-Stack, Mobile, and Web enterprise software." />
<meta name="twitter:image" content="https://www.clickboxagency.com/images-new/Akkenna%20OG-01-01.jpg" />

</Helmet>


    <main id="about">
      <section className='main-banner'>
        <div className="container-fluid ">
          <div className="container">
            <div className="row ">
              <div className="col-lg-5 aboutbannerLeft">
                <div>
                  <h6 className="text-violet fw-bold nunitoRegular">About Akkenna</h6>
                </div>
                <div>
                  <h1 className="text-smallHeading circularBook">
                    Problems? <span className="fw-bold">We’ll Help</span> You Solve...
                  </h1>
                </div>
                <div>
                  <p className="h6 nunitoRegular">Akkenna is the partner of choice for many of the world's corporations, small and medium-sized businesses, and technology challengers. We assist businesses in increasing their value via custom software development, product design, product engineering, animation, marketing intelligence, and campaign management.</p>
                </div>
                <div className="bannerBtn mt-2">
                  <a href="/contact" target="_blank">
                    <button className="whiteBtn circularMedium bg-BlueButton text-white text-smallHeading fw-bold">
                      Contact Us
                    </button>
                  </a>
                </div>
              </div>
              <div className="col-lg-7 aboutbanner">
                <img src={Bannerimg} alt="bannerImage" className='about-bnrimg' />
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="container-fluid animation-background mt-4">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center text-center">
            <div className="col-lg-10 text-white">
              <h3 className='circularMedium'>Akkenna Animation And Technologies</h3>
              <p className="text-grey pt-2 nunitoMedium">Where we offer fore-front IT services and Animation to assist businesses in establishing an appealing online presence and enhancing their bottom line. Our objective is to be contemporary, responsive, and user-friendly by utilizing the most recent technologies and tools. We make an effort to provide individualized solutions that cater to the specific requirements and preferences of each client, while ensuring on-time delivery and superior customer service.</p>
              <p className="text-grey nunitoMedium"> essence of our brand is innovation, progress, and dedication to excellence. At Akkenna, we value decency, skill, and collaboration. Our team of experienced professionals is constantly innovating to remain ahead of the curve in the digital landscape's swift evolution. We believe that collaboration is the key to success, and we work closely with our clients to ensure that we exceed their expectations.</p>
            </div>
          </div>
        </div>
      </section>
     
      <section className="container-fluid my-4">
        <div className="container">
          <div className="row d-flex flex-wrap">
            <div className="col-lg-6 aboutlongtermImg d-flex justify-content-center align-items-center">
              <img src={Longterm} alt="long-term" />
            </div>
            <div className="col-lg-6 aboutlongterm">
              <h3 className="text-smallHeading circularBook">Why Do People <span className="fw-bold">Love Akkenna</span></h3>
              <h3 className="text-smallHeading circularBook pb-2">for <span className="fw-bold">Long-Term Businesses?</span></h3>

              <p className="pb-2 nunitoRegular">The journey of Akkenna began with our inspiration from the earliest Tamil literature in India. Our brand name, Akkeṉṉa is derived from the Tamil letter Akku ஃ which is widely used in poetry. Our logo, which was inspired by the phrase represents our commitment to working with clients over the long term, being anxious to meet their requirements, and never giving up.</p>
              <p className="pb-2 nunitoRegular">Akkenna’s team is composed of industry leaders with extensive knowledge and experience. They are committed to delivering the finest results and align with the company's demeanor and values. Our approach to delivering products or services is distinctive, setting us apart from others in the industry.</p>
              <p className="pb-2 nunitoRegular">Our intent is to become a leading provider of modern IT services, offering solutions that meet and exceed the expectations of our customers. We intend to accomplish this by continuously innovating, collaborating, and providing superior customer service.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="technology pt-4">
        <div className="container tech-lap">
          <div className="row mx-auto text-white text-center mt-40">
            <div className="col-md-10 mx-auto techContent">
              <h3>Technologies Our Experts Use</h3>
              <p>Our talented developers have been assisting prominent firms in a variety of industries with their projects. We understand that the knowledge, talents, and mindset of any devoted development team are critical to their success.</p>
            </div>
          </div>

          <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
            <Row className="tech-navs">
              <Col md={3} sm={12} className='width-25'>
                <Nav variant="pills" className="flex-column nav-pills">
                  <Nav.Item>
                    <Nav.Link eventKey="tab-1" className='tech-button'>Framework & Libraries</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab-2" className='tech-button'>Languages</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab-3" className='tech-button'>Front-end</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab-4" className='tech-button'>Front-end Libraries</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab-5" className='tech-button'>Mobile</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab-6" className='tech-button'>Deployment</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab-7" className='tech-button'>Deployment Process</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab-8" className='tech-button'>Database Management</Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="tab-9" className='tech-button'>3rd Party API Integration</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab-10" className='tech-button'>Project Management Tools</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col md={6} sm={12} >
                <Tab.Content>
                  <Tab.Pane eventKey="tab-1">
                    <img src={tech1} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech2} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech3} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech4} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech5} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech6} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech7} className='ml-30 mt-60 width-25' alt='profile'/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab-2">
                    <img src={tech2_1} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech2_2} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech2_3} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech2_4} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech2_5} className='ml-30 mt-60 width-25' alt='profile'/>

                  </Tab.Pane>
                  <Tab.Pane eventKey="tab-3">
                    <img src={tech3_1} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech3_2} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech3_3} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech3_4} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech3_5} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech3_6} className='ml-30 mt-60 width-25' alt='profile'/>

                  </Tab.Pane>


                  <Tab.Pane eventKey="tab-4">
                    <img src={tech4_1} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech4_2} className='ml-30 mt-60 width-25' alt='profile'/>

                  </Tab.Pane>
                  <Tab.Pane eventKey="tab-5">
                    <img src={tech5_1} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech5_2} className='ml-30 mt-60 width-25' alt='profile'/>

                  </Tab.Pane>
                  <Tab.Pane eventKey="tab-6">
                    <img src={tech6_1} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech6_2} className='ml-30 mt-60 width-25' alt='profile'/>

                  </Tab.Pane>
                  <Tab.Pane eventKey="tab-7">
                    <img src={tech7_1} className='ml-30 mt-60 width-25' alt='profile'/>

                  </Tab.Pane>
                  <Tab.Pane eventKey="tab-8">
                    <img src={tech8_1} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech8_2} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech8_3} className='ml-30 mt-60 width-25' alt='profile'/>

                  </Tab.Pane>
                  <Tab.Pane eventKey="tab-9">
                    <img src={tech9_1} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech9_2} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech9_3} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech9_4} className='ml-30 mt-60 width-25' alt='profile'/>

                  </Tab.Pane>
                  <Tab.Pane eventKey="tab-10">
                    <img src={tech10_1} className='ml-30 mt-60 width-25' alt='profile'/>

                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>


        </div>
        <div className="container tech-mobile pt-5 pb-2">
          <div className="row mx-auto text-white text-center ">
            <div className="col-md-10 mx-auto techContent">
              <h3>Technologies Our Experts Use</h3>
              <p>Our talented developers have been assisting prominent firms in a variety of industries with their projects. We understand that the knowledge, talents, and mindset of any devoted development team are critical to their success.</p>
            </div>

            <form action="#" method="post" id="hockey">

              <div className="dropdown-form">
                <select
                  name="hockeyList"
                  onChange={(e) => showHide(e.target.value)}
                  value={selectedOption}
                >
                  <option value="Framework">Framework</option>
                  <option value="Languages" >Languages</option>
                  <option value="Front-end">Front-end</option>
                  <option value="Front-end-Libraries">Front-end Libraries</option>
                  <option value="Mobile">Mobile</option>
                  <option value="Deployment">Deployment</option>
                  <option value="Deployment-Process"> Deployment Process</option>
                  <option value="Database-Management">Database Management</option>
                  <option value="Api">3rd Party API Integration</option>
                  <option value="Tools">Project Management Tools</option>
                </select>
              </div>

              <div className="dropdown-options pt-3">
                {selectedOption === 'Framework' && (
                  <div className="show-hide" id="Framework">
                    <div className="technologyCard">
                      <img src={tech1} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech2} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech3} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech4} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech5} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech6} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech7} className='ml-30 mt-60 width-25' alt='profile'/>
                    </div>
                  </div>
                )}
                {selectedOption === 'Languages' && (
                  <div className="show-hide" id="Languages">
                    <div className="technologyCard">
                      <img src={tech2_1} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech2_2} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech2_3} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech2_4} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech2_5} className='ml-30 mt-60 width-25' alt='profile'/>
                    </div>
                  </div>
                )}
                {selectedOption === 'Front-end' && (
                  <div className="show-hide" id="Front-end">
                    <div className="technologyCard">
                      <img src={tech3_1} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech3_2} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech3_3} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech3_4} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech3_5} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech3_6} className='ml-30 mt-60 width-25' alt='profile'/>
                    </div>
                  </div>
                )}
                {selectedOption === 'Front-end-Libraries' && (
                  <div className="show-hide" id="Front-end-Libraries">
                    <img src={tech4_1} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech4_2} className='ml-30 mt-60 width-25' alt='profile'/>

                  </div>
                )}
                {selectedOption === 'Mobile' && (
                  <div className="show-hide" id="Mobile">
                    <img src={tech5_1} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech5_2} className='ml-30 mt-60 width-25' alt='profile'/>
                  </div>
                )}
                {selectedOption === 'Deployment' && (

                  <div className="show-hide" id="Deployment">
                    <img src={tech6_1} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech6_2} className='ml-30 mt-60 width-25' alt='profile'/>

                  </div>
                )}
                {selectedOption === 'Deployment-Process' && (
                  <div className="show-hide" id="Deployment-Process">
                    <img src={tech7_1} className='ml-30 mt-60 width-25' alt='profile'/>

                  </div>
                )}
                {selectedOption === 'Database-Management' && (
                  <div className="show-hide" id="Database-Management">
                    <img src={tech8_1} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech8_2} className='ml-30 mt-60 width-25' alt='profile'/>
                    <img src={tech8_3} className='ml-30 mt-60 width-25' alt='profile'/>
                  </div>
                )}
                {selectedOption === 'Api' && (
                  <div className="show-hide" id="Api">
                    <div className="technologyCard">
                      <img src={tech9_1} className='ml-30 mt-60 width-25' alt='profile'/>
                      <img src={tech9_2} className='ml-30 mt-60 width-25' alt='profile' />
                      <img src={tech9_3} className='ml-30 mt-60 width-25' alt='profile' />
                      <img src={tech9_4} className='ml-30 mt-60 width-25' alt='profile'/>

                    </div>
                  </div>
                )}
                {selectedOption === 'Tools' && (
                  <div className="show-hide" id="Tools">
                    <div className="technologyCard">
                      <img src={tech10_1} className='ml-30 mt-60 width-25' alt='profile'/>

                    </div>
                  </div>
                )}

              </div>
            </form>
          </div>
        </div>
      </section >


      <section className="container-fluid my-4">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <h3 className="text-smallHeading text-bold"> Benefits Of Working With Us</h3>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 mt-2 benefitCards">
            <div className="col">
              <div className="benefitCard blue-gradient">
                <div>
                  <img src={Benefit1} alt="team" />
                </div>
                <div>
                  <h5>Great Team of Spirit</h5>
                </div>
                <div>
                  <p>Our team members are not only professionals in their industries, but they also have a strong desire to learn and improve. They are encouraged to take responsibility for their work and to advance professionally.</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="benefitCard skyblue-gradient">
                <div>
                  <img src={Benefit2} alt="increase" />
                </div>
                <div>
                  <h5>Real Impact on Business</h5>
                </div>
                <div>
                  <p>We want to have a genuine influence on your company. We collaborate closely with our customers to understand their particular difficulties and build personalized solutions to match their demands.</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="benefitCard darkblue-gradient">
                <div>
                  <img src={Benefit3} alt="workspace" />
                </div>
                <div>
                  <h5>Dynamic Workspaces</h5>
                </div>
                <div>
                  <p>We recognize the importance of a great workspace, and we have designed ours with our team's needs in mind. Our dynamic workspace features an open floor plan, breakout areas, and collaborative spaces.</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="benefitCard pink-gradient">
                <div>
                  <img src={Benefit4} alt="knowledge" />
                </div>
                <div>
                  <h5>Educational Knowledge</h5>
                </div>
                <div>
                  <p>We understand the importance of education and information in attaining personal and professional development. We support continual development and give our team members chances to broaden.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row responsive-benefitCards mt-2">
            <div className="response-benefitCard1 ">
              <div>
                <img src={Benefit01} alt="team" />
              </div>
              <div className="title">
                <h5>Great Team of Spirit</h5>
              </div>
            </div>
            <div className="response-benefitCard2 Card1">
              <div>
                <img src={Benefit02} alt="team" />
              </div>
              <div className="title">
                <h5>Real Impact on Business</h5>
              </div>
            </div>
            <div className="response-benefitCard2 Card2">
              <div>
                <img src={Benefit03} alt="team" />
              </div>
              <div className="title">
                <h5>Dynamic Workspaces</h5>
              </div>
            </div>
            <div className="response-benefitCard1">
              <div>
                <img src={Benefit04} alt="team" />
              </div>
              <div className="title">
                <h5>Educational Knowledge</h5>
              </div>
            </div>
          </div>
        </div>
      </section>



      {
        <CallToAction
      bgGradient={"bgVoilet"}
      head={"Have A Project In Mind? Reach Out To Our Experts"}
      btn={"Hire Akkennian"}
      />
      }


<section className="container-fluid mt-4 pt-3">
        <div className="container">
          <div className="row TeamMembers">
            <div className="col-lg-4 TeamMembersHead">
              <h3 className="text-Heading ">Distance Doesn’t Matters For<span className="text-bold"> OUR TEAM MEMBERS</span></h3>
              <p>With Akkenna nearshore software development team, you get access to a talent pool of multi- skilled specialists to plug any skill gaps within your in-house team. With our distributed team, our experts are positioned to a seamless working partnership.</p>
            </div>
            <div className="col-lg-8">
              <div className="whoweare-slider-container">
                <div className="whoweare-slider about-slider ">
                  <Slider {...settings} >

                    <div className="whoweare-slider-item memberCard">
                      <div className="member-img">
                        <img src={profile1} alt='companylogo' className='companylogo ' />
                      </div>
                      <div className="member-detail">
                        <h5>James Stephan</h5>
                        <p>Co-Founder</p>
                      </div>
                    </div>
                    <div className="whoweare-slider-item memberCard">
                      <div className="member-img">
                        <img src={profile2} alt='companylogo' className='companylogo' />
                      </div>
                      <div className="member-detail">
                        <h5>Pradeep</h5>
                        <p>Co-Founder</p>
                      </div>
                    </div>
                    <div className="whoweare-slider-item memberCard">
                      <div className="member-img">
                        <img src={profile3} alt='companylogo' className='companylogo' />
                      </div>
                      <div className="member-detail">
                        <h5>Insamam</h5>
                        <p>Digital Marketer</p>
                      </div>
                    </div>
                    <div className="whoweare-slider-item memberCard">
                      <div className="member-img">
                        <img src={profile4} alt='companylogo' className='companylogo ' />
                      </div>
                      <div className="member-detail">
                        <h5>Muthu Kumar</h5>
                        <p>Digital Marketer</p>
                      </div>
                    </div>
                    <div className="whoweare-slider-item memberCard">
                      <div className="member-img">
                        <img src={profile5} alt='companylogo' className='companylogo' />
                      </div>
                      <div className="member-detail">
                        <h5>Hari</h5>
                        <p>UI UX Designer</p>
                      </div>
                    </div>
                    <div className="whoweare-slider-item memberCard">
                      <div className="member-img">
                        <img src={profile6} alt='companylogo' className='companylogo' />
                      </div>
                      <div className="member-detail">
                        <h5>Dinesh Kumar</h5>
                        <p>Full Stack Developer</p>
                      </div>
                    </div>
                    <div className="whoweare-slider-item memberCard">
                      <div className="member-img">
                        <img src={profile7} alt='companylogo' className='companylogo ' />
                      </div>
                      <div className="member-detail">
                        <h5>Karthick Raja</h5>
                        <p>Full Stack Developer</p>
                      </div>
                    </div>
                    <div className="whoweare-slider-item memberCard">
                      <div className="member-img">
                        <img src={profile8} alt='companylogo' className='companylogo' />
                      </div>
                      <div className="member-detail">
                        <h5>Chandra Prakash</h5>
                        <p>Back End Developer</p>
                      </div>

                    </div>
                    <div className="whoweare-slider-item memberCard">
                      <div className="member-img">
                        <img src={profile9} alt='companylogo' className='companylogo' />
                      </div>
                      <div className="member-detail">
                        <h5>Vinoth Kumar</h5>
                        <p>Full Stack Developer</p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div >
      </section >

      <section className="container-fluid mt-4 blog pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 blogHead">
            <h3 className="text-Heading circularMedium">Blogs & Latest Works</h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="container">
              <div className="row">
                <div className="News-Blog">
                  <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
                    <div className="item">
                      <div className="BlogCardImg">
                        <img src={festivBlog} alt="festiv"/>
                      </div>
                      <div className="BlogCardFooter">
                        <div>
                          <h6 className='circularBook'>Gateway to Extraordinary Event Experience</h6>
                        </div>
                        
                      </div>
                    </div>
                    <div className="item">
                      <div className="BlogCardImg">
                        <img src={sigurBlog} alt="sigur"/>
                      </div>
                      <div className="BlogCardFooter">
                        <div>
                          <h6  className='circularBook'>Leads Your Brands To A Greater Reach</h6>
                        </div>
                      
                      </div>
                    </div>
                    <div className="item">
                      <div className="BlogCardImg">
                        <img src={graphicsImgBlog} alt="graphicsImg"/>
                      </div>
                      <div className="BlogCardFooter">
                        <div>
                          <h6  className='circularBook'>World-class Rehabilitation Centres</h6>
                        </div>
                       
                      </div>
                    </div>
                    <div className="item">
                      <div className="BlogCardImg">
                        <img src={webRMSBlog} alt="webRMS"/>
                      </div>
                      <div className="BlogCardFooter">
                        <div>
                          <h6  className='circularBook'>Structural Engineering & Steel Services</h6>
                        </div>
                       
                      </div>
                    </div>
                    <div className="item">
                      <div className="BlogCardImg">
                        <img src={sarvamBlog} alt="sarvam"/>
                      </div>
                      <div className="BlogCardFooter">
                        <div>
                          <h6  className='circularBook'>Global staffing solution</h6>
                        </div>
                        
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>







<section className='aboutSection'>
<ContactForm/>
</section>



    <section className="container-fluid mt-4 mb-4">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-6 youtube-heading">
              <h4 className="text-smallHeading circularMedium">Why Do People <span className="fw-bold">Love Akkenna</span></h4>
              <h4 className="text-smallHeading circularMedium">For <span className="fw-bold">Long-Term Businesses?</span></h4>
            </div>

            <div className="col-lg-6 d-flex justify-content-center align-items-center long-term2">
              <video src={Video} className='longterm-video' controls>
              </video>
            </div>
            <div className="col-lg-6 youtube-longterm">
              <h4 className="text-smallHeading circularMedium">Why Do People <span className="fw-bold">Love Akkenna</span></h4>
              <h4 className="text-smallHeading circularMedium">For <span className="fw-bold">Long-Term Businesses?</span></h4>

              <p className="text-para pt-2 nunitoMedium">The journey of Akkenna began with our inspiration from the earliest Tamil literature in India. Our brand name, 'Akkeṉṉa'' is derived from the Tamil letter 'Akku,' ‘ ஃ‘ which is widely used in poetry. Our logo, which was inspired by the phrase "Ūkkamatu kāy vīṭlē" represents our commitment to working with clients over the long term, being anxious to meet their requirements, and never giving up.</p>
              <p className="text-para pt-2 nunitoMedium">Akkenna’s team is composed of industry leaders with extensive knowledge and experience. They are committed to delivering the finest results and align with the company's demeanor and values. Our approach to delivering products or services is distinctive, setting us apart from others in the industry.</p>
              <p className="text-para pt-2 nunitoMedium">Our intent is to become a leading provider of modern IT services, offering solutions that meet and exceed the expectations of our customers. We intend to accomplish this by continuously innovating, collaborating, and providing superior customer service.</p>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className="homeFaq">
          <div className="container">
            <div className="genaral-title">
              <div className="row d-flex justify-content-center align-item-center">
                <div className="col-lg-8 text-center">
                  <h3 className="text-Heading circularMedium">Our Software Development FAQs</h3>
                  <p className='nunitoMedium'>Are you searching high and low for answers to your software development questions? Look no further because we've got the perfect solution for you!</p>
                </div>
              </div>
            </div>
            <div className="accordion accordion-faq nunitoMedium" id="accordionfaqExample">
              <div className="row d-flex justify-content-center align-item-center">
                <div className="col-md-6">
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingOne">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                        Do you offer complete software delivery?
                      </button>
                    </h3>
                    <div id="faq-collapseOne" className="accordion-collapse collapse" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">From design to development, implementation to integration, configuration to post-deployment support, Akkenna is your one-stop-shop for end-to-end delivery. We set up the 'correct' systems for you, ensure a minimum cost of ownership, incorporate the finest materials, and deliver infrastructure that meets industry standards. We reduce development burden, cost, resources, and time with our end-to-end solutions so that your business can keep up with the ever-changing market demands.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
                        What is the cost of custom software?
                      </button>
                    </h3>
                    <div id="faq-collapseTwo" className="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">Sincerely, there is no straightforward solution to this! The cost of custom software development is determined by a number of variables, including the type of architecture and technology employed, the expertise of engineers and seniority of required resources, and, most importantly, the specialized capabilities that the project requires to have a competitive advantage. However, we ensure that our prices are the most competitive on the market, just as our quality is!</div>
                    </div>
                  </div>
                  <div className=" accordion-item">
                    <h3 className="accordion-header" id="faq-headingSix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseSix" aria-expanded="false" aria-controls="faq-collapseSix">
                        Do you ensure software delivery quality?
                      </button>
                    </h3>
                    <div id="faq-collapseSix" className="accordion-collapse collapse" aria-labelledby="faq-headingSix" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">Continually integrating QA and testing after each phase of development ensures that all discrepancies are identified in a timely manner and corrected before moving on to the next phase. With Akkenna, our clients never have to fear quality!</div>
                    </div>
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFour" aria-expanded="false" aria-controls="faq-collapseFour">
                        How do we guarantee privacy and secrecy?
                      </button>
                    </h3>
                    <div id="faq-collapseFour" className="accordion-collapse collapse" aria-labelledby="faq-headingFour" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">As soon as you discuss your digital vision with us, we sign a non-disclosure agreement with you, ensuring that your ideas are secure with us. In addition, because Akkenna's development process adheres to industry standards, there is no possibility of data exposure on our end.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
                        Will you accommodate my schedule?
                      </button>
                    </h3>
                    <div id="faq-collapseThree" className="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">Our software development procedure is extremely straightforward. After the initial requirement-gathering process, we develop a plan that precisely outlines your needs and establishes a mutually acceptable schedule. We have an outstanding track record of adhering to deadlines and concluding projects on schedule with few obstacles.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFive" aria-expanded="false" aria-controls="faq-collapseFive">
                        What Software Development Methodology do you adhere to?
                      </button>
                    </h3>
                    <div id="faq-collapseFive" className="accordion-collapse collapse" aria-labelledby="faq-headingFive" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">As a software development company based in the United States and India that collaborates with tens of thousands of global businesses integrating modern technology, we favor the Agile Methodology because it facilitates the incorporation of the wondrous possibilities of new technologies. However, our engineers are also well-versed in waterfall technologies and can develop your software using any requested methodology.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    </HelmetProvider>
  )
}

export default AboutIndex