import React, { useEffect, useState } from 'react'
import AppBanner from '../../Assets/Image/service-page/mobile-app-develoment-banner.webp'
import '../../Style/NewAppDevelopment.css'
import '../../Style/NewAppDevelopmentResponsive.css'
import Accordion from 'react-bootstrap/Accordion';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick.js';
import Slider from "react-slick";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import abasha from '../../Assets/Image/service-page/abasha.webp'
import rehabpy from '../../Assets/Image/service-page/rehabpy.webp'
import CMC from '../../Assets/Image/service-page/CMC.webp'
import stanley from '../../Assets/Image/service-page/stanley.webp'
import sarvamgroup from '../../Assets/Image/service-page/sarvamgroup.webp'
import Clickbox from '../../Assets/Image/service-page/Clickbox.webp'
import Gocabxi from '../../Assets/Image/service-page/Gocabxi.webp'
import sigursolution from '../../Assets/Image/service-page/sigursolution.webp'
import BoomCabs from '../../Assets/Image/service-page/Boom Cabs.webp'
import akkennaAcademy from '../../Assets/Image/service-page/sigursolution.webp'
import FestivwebsiteImg from '../../Assets/Image/service-page/festiveticket.webp'
import Comprehensive from '../../Assets/Image/service-page/Comprehensive-Experience-app.svg'
import Centered from '../../Assets/Image/service-page/User-Centered-Design-app.svg'
import Agile from '../../Assets/Image/service-page/Agile-Development-Process-app.svg'
import Cutting from '../../Assets/Image/service-page/Cutting-Edge-Technologies-app.svg'
import CallToAction from '../../Components/CallToAction';
import processweb from '../../Assets/Image/service-page/process-of-akkenna-app.webp'
import BenefitIcon1 from '../../Assets/Image/service-page/Single-Window-Solution-app.svg'
import BenefitIcon2 from '../../Assets/Image/service-page/Customer-Interaction-app.svg'
import BenefitIcon3 from '../../Assets/Image/service-page/Brand-Presence-And-Loyalty-app.svg'
import BenefitLeft from '../../Assets/Image/webDevelopment/cus-app-solutions.svg'
import BestOption1 from '../../Assets/Image/service-page/Expertise-app.svg'
import BestOption2 from '../../Assets/Image/service-page/Tailored-Solutions-app.svg'
import BestOption3 from '../../Assets/Image/service-page/Timely-Delivery-app.svg'
import BestOption4 from '../../Assets/Image/service-page/Excellent-Customer-Support.svg'
import BestOptionRight from '../../Assets/Image/service-page/option-for-app-development.webp'
import Hybrid from '../../Assets/Image/service-page/hybrid.webp'
import wep from '../../Assets/Image/service-page/wep.webp'
import android from '../../Assets/Image/service-page/android.webp'
import ios from '../../Assets/Image/service-page/ios.webp'
import native from '../../Assets/Image/service-page/native.webp'
import xamarin from '../../Assets/Image/service-page/xamarin.webp'
import flutter from '../../Assets/Image/service-page/flutter.webp'
import ionic from '../../Assets/Image/service-page/ionic.webp'
import reactNative from '../../Assets/Image/service-page/react-native-2.webp'
import RightSvg from '../../Assets/Image/webDevelopment/Left.svg'
import LeftSvg from '../../Assets/Image/webDevelopment/slickdown-arrow.svg'
import ContactForm from '../../Components/ContactForm';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const AppDevelopment = () => {

  const [formSection, SetFormSection] = useState('dropdown-value-1')

  const techSetting = {
    centerMode: true,
    speed: 500,
    arrows: true,
    autoplay: true,
    autoplayTimeout: 3000,
    slidesToShow: 4,
    prevArrow: <div className="slick-next"><img src={RightSvg} alt="" /></div>,
    nextArrow: <div className="slick-prev"><img src={LeftSvg} alt="" /></div>,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }
  const settings = {
    centerMode: true,
    speed: 500,
    arrows: true,
    autoplay: true,
    autoplayTimeout: 3000,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }


  const listItem = document.querySelectorAll(".list");

  function activateLink() {
    listItem.forEach((item) => {
      item.classList.remove("active");
    });
    this.classList.add("active");
  }

  listItem.forEach((item) => {
    item.addEventListener("click", activateLink);
  });

  const [scrollToPos, setScrollToPos] = useState(0);

  const handleClick = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    const container = document.querySelector(".guide-answers");
    const containerTop = container.getBoundingClientRect().top;
    const currentScrollTop = container.scrollTop;
    const selectionTop = targetElement.getBoundingClientRect().top;

    setScrollToPos(selectionTop - containerTop + currentScrollTop);

    container.scrollTo({
      top: scrollToPos,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const container = document.querySelector(".guide-answers");
    container.scrollTo({
      top: scrollToPos,
      behavior: "smooth",
    });
  }, [scrollToPos]);


  useEffect(() => {
    const handleScroll = () => {
      let containerTop = document.getElementsByClassName("guide-answers")[0].getBoundingClientRect();
      let questionOne = document.getElementById("question-1").getBoundingClientRect();
      let questionTwo = document.getElementById("question-2").getBoundingClientRect();
      let questionThree = document.getElementById("question-3").getBoundingClientRect();
      let questionFour = document.getElementById("question-4").getBoundingClientRect();
      let questionFive = document.getElementById("question-5").getBoundingClientRect();
      let questionSix = document.getElementById("question-6").getBoundingClientRect();
      let questionSeven = document.getElementById("question-7").getBoundingClientRect();
      let questionEight = document.getElementById("question-8").getBoundingClientRect();
      let questionNine = document.getElementById("question-9").getBoundingClientRect();
      let topCondition = containerTop.top + containerTop.top / 2;

      if (questionOne.top < topCondition && questionOne.top > 0) {
        setActiveClass('#guideNav li a[href="#question-1"]');
      } else if (questionTwo.top < topCondition && questionTwo.top > 0) {
        setActiveClass('#guideNav li a[href="#question-2"]');
      } else if (questionThree.top < topCondition && questionThree.top > 0) {
        setActiveClass('#guideNav li a[href="#question-3"]');
      } else if (questionFour.top < topCondition && questionFour.top > 0) {
        setActiveClass('#guideNav li a[href="#question-4"]');
      } else if (questionFive.top < topCondition && questionFive.top > 0) {
        setActiveClass('#guideNav li a[href="#question-5"]');
      } else if (questionSix.top < topCondition && questionSix.top > 0) {
        setActiveClass('#guideNav li a[href="#question-6"]');
      } else if (questionSeven.top < topCondition && questionSeven.top > 0) {
        setActiveClass('#guideNav li a[href="#question-7"]');
      } else if (questionEight.top < topCondition && questionEight.top > 0) {
        setActiveClass('#guideNav li a[href="#question-8"]');
      } else if (questionNine.top < topCondition && questionNine.top > 0) {
        setActiveClass('#guideNav li a[href="#question-9"]');
      }
    };

    const setActiveClass = (selector) => {
      document.getElementById("guideNav").querySelectorAll('li').forEach((li) => {
        li.classList.remove("active");
      });
      document.querySelector(selector).parentNode.classList.add("active");
    };


    document.getElementsByClassName("guide-answers")[0].addEventListener("scroll", handleScroll);


    return () => {
      document.getElementsByClassName("guide-answers")[0].removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <HelmetProvider>
      <Helmet>
        <title>Mobile Application Development Company in Coimbatore | Akkenna</title>
        <meta name="description" content="Akkenna is a highly skilled Mobile Application Development company in Coimbatore. Our feature-rich mobile apps combine creative chaos with elegant user experience." />
        <meta name="keywords" content="mobile application development company coimbatore, mobile application development in coimbatore, mobile app development in coimbatore, mobile app development company in coimbatore, android app development company in coimbatore, cross-platform app development in coimbatore, react native mobile application development in coimbatore, android mobile app development company in coimbatore." />
        <link rel="canonical" href="https://www.akkenna.com/mobile-application-development-service-coimbatore" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.akkenna.com/mobile-application-development-service-coimbatore" />
        <meta property="og:site_name" content="https://www.akkenna.com/mobile-application-development-service-coimbatore" />
        <meta property="og:image" content="https://www.akkenna.com/assets/image/AkkennaOG.jpg" />
        <meta property="og:title" content="Mobile Application Development Company in Coimbatore | Akkenna" />
        <meta property="og:description" content="Akkenna is a highly skilled Mobile Application Development company in Coimbatore. Our feature-rich mobile apps combine creative chaos with elegant user experience." />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Mobile Application Development Company in Coimbatore | Akkenna" />
        <meta name="twitter:description" content="Akkenna is a highly skilled Mobile Application Development company in Coimbatore. Our feature-rich mobile apps combine creative chaos with elegant user experience." />
        <meta name="twitter:image" content="https://www.akkenna.com/assets/image/AkkennaOG.jpg" />
      </Helmet>
      <main id="Appdevelopement" page="Appdevelopement">
        <section className="banner">
          <div className="container-fluid">
            <div className="container bannerCont">
              <div className="row justify-content-between">
                <div className="col-lg-6 bannerLeft">
                  <div>
                    <h5 className="text-white fw-bold mb-0 banner-hint">
                      Top Mobile App Development Company In Coimbatore
                    </h5>
                  </div>
                  <div>
                    <h1 className="banner-title text-white circularMedium">
                      Get A Top-Notch, Extensively Innovative Mobile App Solution From Us
                    </h1>
                  </div>
                  <div>
                    <p className="h6 textbannerpara nunitoRegular text-white">
                      Our Mobile-First Approach Ranks Us Among The Top Mobile App Development Companies In Coimbatore, India, As We Deliver Complete Mobility Solutions That Meet The Needs Of Businesses, Industries, And End-Users.                  </p>
                  </div>
                  <div className="web-bannerBtn">
                    <a href="/contact" target='_blank'>
                      <button className="whiteBtn bg-whiteButton text-smallHeading fw-bold">
                        Contact Us
                      </button>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 bannerRightImage">
                  <img src={AppBanner} loading="lazy" alt="Description" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-3 latest-web-dev latestDesktop">
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-3 col-md-3"></div>
              <div className="col-lg-9 col-md-9 section-title mt-5">
                <div className="latest-web-dev-title">
                  <p>Our Web Development Team Has Completed Recent Works</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3"></div>
              <div className="col-lg-9 col-md-9 section-description">
                <div className="row latest-web-dev-description">
                  <div className="col-lg-10 col-md-12">
                    <div className="">
                      <p>
                        Akkenna Animation Technologies, an affordable web design company in coimbatore town, has developed 50+ websites. A better portfolio with happy clients
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center newest-section mb-2">
              <div>
                <Tab.Container id="left-tabs-example" defaultActiveKey="Abhasa">
                  <Row>
                    <Col sm={3}>
                      <Nav variant="pills" className="flex-column lastestWeb">
                        <Nav.Item>
                          <Nav.Link eventKey="Abhasa">Abhasa</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Rehabpy">Rehabpy</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="CMC">CMC</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Stanley">Stanley Estates</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Sarvam">Sarvam</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Clickbox">Clickbox Agency</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Gocabxi">Gocabxi</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Sigur">Sigur Solutions</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Festiv">Festiv Tickets</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Boom">Boom Cabs</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Academy">Akkenna Academy</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={9} className='latest-web-images'>
                      <Tab.Content className='latest-web-dev-images'>
                        <Tab.Pane eventKey="Abhasa">
                          <div id="Abhasa" className="site-images">
                            <img src={abasha} alt="Abhasa" />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Rehabpy">
                          <div id="Abhasa" className="site-images">
                            <img src={rehabpy} alt="Rehabpy" />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="CMC">
                          <div id="Abhasa" className="site-images">
                            <img src={CMC} />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Stanley">
                          <div id="Abhasa" className="site-images">
                            <img src={stanley} alt="Stanley Estates" />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Sarvam">
                          <div id="Abhasa" className="site-images">
                            <img src={sarvamgroup} alt="Sarvam" />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Clickbox">
                          <div id="Abhasa" className="site-images">
                            <img src={Clickbox} alt="Clickbox Agency" />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Gocabxi">
                          <div id="Abhasa" className="site-images">
                            <img src={Gocabxi} alt="Gocabxi" />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Sigur">
                          <div id="Abhasa" className="site-images">
                            <img src={sigursolution} alt="Sigur Solutions" />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Festiv">
                          <div id="Abhasa" className="site-images">
                            <img src={FestivwebsiteImg} alt="Boom-cabs" />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Boom">
                          <div id="Abhasa" className="site-images">
                            <img src={BoomCabs} alt="Boom-cabs" />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Academy">
                          <div id="Abhasa" className="site-images">
                            <img src={akkennaAcademy} alt="Boom-cabs" />
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </div>
        </section>


        <section className='latestMobile'>
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center text-center">
              <div className="col-lg-9 col-md-12 section-title mt-5">
                <div className="latest-web-dev-title">
                  <p className='text-center'>Our Web Development Team Has Completed Recent Works</p>
                </div>
              </div>
              <div className="col-lg-9 col-md-12 section-description">
                <div className="row latest-web-dev-description">
                  <div className="col-lg-10 col-md-12">
                    <div className="">
                      <p className='text-center'>
                        Akkenna Animation Technologies, an affordable web design company in coimbatore town, has developed 50+ websites. A better portfolio with happy clients
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center LatestFormMobile">
              <form action="#" method="post" id="hockey">
                <div className="dropdown-form">
                  <select name="hockeyList" onChange={(e) => { SetFormSection(e.target.value) }}>
                    <option value="dropdown-value-1" selected>Abhasa</option>
                    <option value="dropdown-value-2">Rehabpy</option>
                    <option value="dropdown-value-3">Alpha Informatrix</option>
                    <option value="dropdown-value-4">CMC</option>
                    <option value="dropdown-value-5">Stanley Estates</option>
                    <option value="dropdown-value-6">Sarvam</option>
                    <option value="dropdown-value-7">Clickbox Agency</option>
                    <option value="dropdown-value-8">Gocabxi</option>
                    <option value="dropdown-value-9">Sigur Solutions</option>
                    <option value="dropdown-value-10">Festiv Tickets</option>
                    <option value="dropdown-value-11">Boom Cabs</option>
                    <option value="dropdown-value-12">Akkenna Academy</option>

                  </select>
                </div>
                <div className="dropdown-options latest-web-images pt-3">
                  <div className={formSection == "dropdown-value-1" ? "site-images" : "show-hide "} id="dropdown-value-1">
                    <a href="https://abhasa.in/" target="_blank">
                      <img src={abasha} alt="Abhasa" />
                    </a>
                  </div>
                  <div className={formSection == "dropdown-value-2" ? "site-images" : "show-hide "} id="dropdown-value-2">
                    <img src={rehabpy} alt="Rehabpy" />
                  </div>
                  <div className={formSection == "dropdown-value-3" ? "site-images" : "show-hide "} id="dropdown-value-3">
                    <a href="#" target="_blank">
                      <img src={CMC} />
                    </a>
                  </div>
                  <div className={formSection == "dropdown-value-4" ? "site-images" : "show-hide "} id="dropdown-value-4">
                    <img src={stanley} alt="Stanley Estates" />
                  </div>
                  <div className={formSection == "dropdown-value-5" ? "site-images" : "show-hide "} id="dropdown-value-5">
                    <a href="#" target="_blank">
                      <img src={sarvamgroup} alt="Sarvam" />                    </a>
                  </div>
                  <div className={formSection == "dropdown-value-6" ? "site-images" : "show-hide "} id="dropdown-value-6">
                    <img src={Clickbox} alt="Clickbox Agency" />

                  </div>
                  <div className={formSection == "dropdown-value-7" ? "site-images" : "show-hide "} id="dropdown-value-7">
                    <a href="#" target="_blank">
                      <img src="./assets/image/service-page/Clickbox.webp" alt="Clickbox Agency" />
                    </a>
                  </div>
                  <div className={formSection == "dropdown-value-8" ? "site-images" : "show-hide "} id="dropdown-value-8">
                    <img src={Gocabxi} alt="Gocabxi" />

                  </div>
                  <div className={formSection == "dropdown-value-9" ? "site-images" : "show-hide "} id="dropdown-value-9">
                    <a href="#" target="_blank">
                      <img src={sigursolution} alt="Sigur Solutions" />
                    </a>
                  </div>
                  <div className={formSection == "dropdown-value-10" ? "site-images" : "show-hide "} id="dropdown-value-10">
                    <img src={FestivwebsiteImg} alt="Boom-cabs" />
                  </div>
                  <div className={formSection == "dropdown-value-11" ? "site-images" : "show-hide "} id="dropdown-value-11">
                    <img src={BoomCabs} alt="Boom-cabs" />
                  </div>
                  <div className={formSection == "dropdown-value-12" ? "site-images" : "show-hide "} id="dropdown-value-12">
                    <img src={akkennaAcademy} alt="Boom-cabs" />
                  </div>
                </div>

              </form>
            </div>
          </div>

        </section>

        <section className="container-fluid pb-3 experience-section">
          <div className="container">
            <div className="row text-center d-flex justify-content-center align-items-center mt-3">
              <div className="col-md-8 experience-title">
                <h3 className='circularMedium text-smallHeading'>Process We Follow</h3>
                <h3 className='circularMedium text-smallHeading'>How Our Experts Handle The Project Efficiently</h3>
              </div>
            </div>
            <div className="row experience-details d-flex justify-content-center align-items-center mb-3">
              <div className="col-lg-11 ExploreCards">
                <Slider {...settings} className="row ">
                  <div className='webdevsliderItem'>
                    <div className="experience-details-image">
                      <img src={Comprehensive} alt="Customization" />
                    </div>
                    <div className="experience-details-title">
                      <p>Comprehensive Experience</p>
                    </div>
                    <div className="experience-details-description">
                      <p>
                        Our team of developers and designers have extensive experience in building apps for a wide range of sectors. We deliver full-fledged solutions & meet the diverse needs of each of our clients.                    </p>
                    </div>
                  </div>
                  <div className='webdevsliderItem'>
                    <div className="experience-details-image">
                      <img src={Centered} alt="Vector" />
                    </div>
                    <div className="experience-details-title">
                      <p>User-Centered Design</p>
                    </div>
                    <div className="experience-details-description">
                      <p>
                        When we make apps, we think about who will use them. Designers and developers at our successful app and software creation company work together to create useful digital platforms that look good too.                    </p>
                    </div>
                  </div>
                  <div className='webdevsliderItem'>
                    <div className="experience-details-image">
                      <img src={Agile} alt="Editing" />
                    </div>
                    <div className="experience-details-title">
                      <p>Agile Development Process</p>
                    </div>
                    <div className="experience-details-description">
                      <p>
                        Our agile development process shows our capacity as a team that quickly responds to changing requirements. We endeavor to provide our clients with high-quality products at their chosen time.                       </p>
                    </div>
                  </div>
                  <div className='webdevsliderItem'>
                    <div className="experience-details-image">
                      <img src={Cutting} alt="Award" />
                    </div>
                    <div className="experience-details-title">
                      <p>Cutting-Edge Technologies</p>
                    </div>
                    <div className="experience-details-description">
                      <p>
                        We always stay up to date on the newest trends in the app creation business, which helps us get better all the time. We know how to use the newest tools, like React Native, Swift, Flutter, and more.                    </p>
                    </div>
                  </div>

                </Slider>
              </div>
            </div>
          </div>
        </section>

        {
          <CallToAction
            bgGradient={"bgDarkVoilet"}
            head={"Ready to build the next big thing?"}
            headTwo={"We'll help you get the ball rolling - for free!"}
            btn={"Bulid Your Mobile Apps With Us"}
          />
        }

        <section className="container-fluid pt-3 pb-3 mt-5 process-section">
          <div className="container">
            <div className='pratice-desktop'>
              <div className="row text-center d-flex justify-content-between align-items-center">
                <div className="col-lg-5">
                  <div className="process-image">
                    <img src={processweb} alt="image" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row process-title ">
                    <div>
                      <h3 className='text-dark'>Process of AKKENNA ANIMATIONS TECHNOLOGIES</h3>
                    </div>
                    <div>
                      <p className='text-para'>
                        The website has grown into an important and necessary part of everyday life. A business must have a website to show off its goods, services, skills, and other things. A website can be used to make and change anything. Akkenna Animation Technologies knows what businesses and start-ups need and makes beautiful websites and content management systems for them. We are the best custom web creation business in the world, and we offer the best web and CMS solutions to clients all over the world.
                      </p>

                    </div>
                    <div>
                      <p className='text-para'>
                        Our website development engineers are very good at using the newest technology and following the latest trends. We worked with companies around the world to turn well-known concepts into beautiful and creative websites. We have a lot of knowledge and imagination making amazing websites and CMS creation for clients all over the world. Our website development experts look at things from a unique point of view, which helps them make web development faster and better. With this website development, the business can do a lot of things quickly and easily, which makes it better.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row process-mobile">
              <div className="col-md-12">
                <div className="process-title">
                  <h3 className='text-dark text-center'>Process of AKKENNA ANIMATIONS TECHNOLOGIES</h3>
                </div>
              </div>
              <div className="col-md-12">
                <div className="process-mobile-image">
                  <div className="row">
                    <div className="col-md-12">
                      <button className="client">Launch and Maintenance</button>
                    </div>
                    <div className="col-md-12">
                      <button className="product">Testing</button>
                    </div>
                    <div className="col-md-12">
                      <button className="website">Development Process</button>
                    </div>
                    <div className="col-md-12">
                      <button className="design">Framework of Design</button>
                    </div>
                    <div className="col-md-12">
                      <button className="analyze">Reviewing of Ideas</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row process-title">
                  <div>
                    <p className='text-para text-center'>
                      The website has grown into an important and necessary part of everyday life. A business must have a website to show off its goods, services, skills, and other things. A website can be used to make and change anything. Akkenna Animation Technologies knows what businesses and start-ups need and makes beautiful websites and content management systems for them. We are the best custom web creation business in the world, and we offer the best web and CMS solutions to clients all over the world.
                    </p>
                  </div>
                  <div>
                    <p className='text-para text-center'>
                      Our website development engineers are very good at using the newest technology and following the latest trends. We worked with companies around the world to turn well-known concepts into beautiful and creative websites. We have a lot of knowledge and imagination making amazing websites and CMS creation for clients all over the world. Our website development experts look at things from a unique point of view, which helps them make web development faster and better. With this website development, the business can do a lot of things quickly and easily, which makes it better.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid  pb-3 mt-5 custom-solution">
          <div className="container">
            <div className="row text-center d-flex justify-content-between align-items-center">
              <div className="col-md-12 col-lg-12 ">
                <div className="solution-title">
                  <p>How Does a Website Benefit Your Business?</p>
                </div>
                <div className="solution-description">
                  <p>
                    A well-designed and effectively implemented website can benefit your business in numerous ways
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">

                  <div className="col-md-12">
                    <div className="row mobile-view ">
                      <div className="col-md-12 my-2">
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header className="solution-dropdown">
                              <div className="row w-100">
                                <div className="col-3 solution-dropdown-image">
                                  <img src={BenefitIcon1} alt="mike" />
                                </div>
                                <div className="col-9 solution-dropdown-title">
                                  <p className="solution-sub-title">
                                    Global Reach
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="solution-dropdown-content">
                              <p>
                                A website allows your business to be accessible to a worldwide audience. This extends your reach far beyond the confines of a physical location.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>


                      <div className="col-md-12 my-2">
                        <Accordion>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header className="solution-dropdown">
                              <div className="row w-100">
                                <div className="col-3 solution-dropdown-image">
                                  <img src={BenefitIcon1} alt="mike" />
                                </div>
                                <div className="col-9 solution-dropdown-title">
                                  <p className="solution-sub-title">
                                    Credibility and <br />Professionalism
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="solution-dropdown-content">
                              <p>
                                A professional website conveys trust and credibility. It's often the first point of contact between your business and potential customers, making a positive impression crucial.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>

                      <div className="col-md-12 my-2">
                        <Accordion>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header className="solution-dropdown">
                              <div className="row w-100">
                                <div className="col-3 solution-dropdown-image">
                                  <img src={BenefitIcon1} alt="mike" />
                                </div>
                                <div className="col-9 solution-dropdown-title">
                                  <p className="solution-sub-title">
                                    Improved Customer <br />Service
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="solution-dropdown-content">
                              <p>
                                A website offers a platform for customers to find information, ask questions, and even make purchases. It can also incorporate features like chatbots or FAQs to provide instant support.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <ul className="solution-description-list">
                      <li>
                        <div className="desktop-view pb-3">
                          <div className="row">
                            <div className="col-md-1">
                              <img src={BenefitIcon1} alt="mike" />
                            </div>
                            <div className="col-md-11 pt-2">
                              <p className="solution-sub-title">Global Reach</p>
                              <p className="solution-sub-description">
                                A website allows your business to be accessible to a worldwide audience. This extends your reach far beyond the confines of a physical location.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="desktop-view pb-3">
                          <div className="row">
                            <div className="col-md-1">
                              <img src={BenefitIcon2} alt="design" />
                            </div>
                            <div className="col-md-11 pt-2">
                              <p className="solution-sub-title">Credibility and Professionalism</p>
                              <p className="solution-sub-description">
                                A professional website conveys trust and credibility. It's often the first point of contact between your business and potential customers, making a positive impression crucial.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="desktop-view pb-3">
                          <div className="row">
                            <div className="col-md-1">
                              <img src={BenefitIcon3} alt="code" />
                            </div>
                            <div className="col-md-11 pt-2">
                              <p className="solution-sub-title">
                                Improved Customer Service
                              </p>
                              <p className="solution-sub-description">
                                A website offers a platform for customers to find information, ask questions, and even make purchases. It can also incorporate features like chatbots or FAQs to provide instant support.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="solution-image">
                  <img src={BenefitLeft} alt="solutions" />
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="container-fluid  pb-3 mt-5 custom-solution">
          <div className="container">
            <div className="row text-center d-flex justify-content-between align-items-center">
              <div className="col-md-12 col-lg-12 ">
                <div className="solution-title">
                  <p>How Does a Website Benefit Your Business?</p>
                </div>
                <div className="solution-description">
                  <p>
                    A well-designed and effectively implemented website can benefit your business in numerous ways
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="solution-image">
                  <img src={BestOptionRight} alt="solutions" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mobile-view ">
                      <div className="col-md-12 my-2">
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header className="solution-dropdown">
                              <div className="row w-100">
                                <div className="col-3 solution-dropdown-image">
                                  <img src={BestOption1} alt="mike" />
                                </div>
                                <div className="col-9 solution-dropdown-title">
                                  <p className="solution-sub-title">
                                    Expertise
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="solution-dropdown-content">
                              <p>
                                Our team of talented developers has been making high-quality, easy-to-use, and beautiful mobile apps for years. We keep up with the newest trends and technologies to make sure that your app stands out from the rest.                            </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>


                      <div className="col-md-12 my-2">
                        <Accordion>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header className="solution-dropdown">
                              <div className="row w-100">
                                <div className="col-3 solution-dropdown-image">
                                  <img src={BestOption2} alt="mike" />
                                </div>
                                <div className="col-9 solution-dropdown-title">
                                  <p className="solution-sub-title">
                                    Tailored Solutions
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="solution-dropdown-content">
                              <p>
                                We know that the needs of each business are different. Because of this, we offer app creation services that are made to fit your wants. Whether you need an app for e-commerce, a game, or something else, we can help!                            </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>

                      <div className="col-md-12 my-2">
                        <Accordion>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header className="solution-dropdown">
                              <div className="row w-100">
                                <div className="col-3 solution-dropdown-image">
                                  <img src={BestOption3} alt="mike" />
                                </div>
                                <div className="col-9 solution-dropdown-title">
                                  <p className="solution-sub-title">
                                    Timely Delivery
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="solution-dropdown-content">
                              <p>
                                We value your time and know how important it is to be on time. With Akkenna, you can be sure that your app will be done on time and will be of good quality. We try to do our best in every job we take on.                            </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>

                      <div className="col-md-12 my-2">
                        <Accordion>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header className="solution-dropdown">
                              <div className="row w-100">
                                <div className="col-3 solution-dropdown-image">
                                  <img src={BestOption4} alt="mike" />
                                </div>
                                <div className="col-9 solution-dropdown-title">
                                  <p className="solution-sub-title">
                                    Excellent Customer <br /> Support
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="solution-dropdown-content">
                              <p>
                                At every stage of making an app, our dedicated support team is always ready to help you. From the first meeting to the time after the start, we'll be there to answer any questions or worries you may have.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-12">
                    <ul className="solution-description-list">
                      <li>
                        <div className="desktop-view pb-3">
                          <div className="row">
                            <div className="col-md-1">
                              <img src={BestOption1} alt="mike" />
                            </div>
                            <div className="col-md-11 pt-2">
                              <p className="solution-sub-title">Expertise</p>
                              <p className="solution-sub-description">
                                Our team of talented developers has been making high-quality, easy-to-use, and beautiful mobile apps for years. We keep up with the newest trends and technologies to make sure that your app stands out from the rest.                            </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="desktop-view pb-3">
                          <div className="row">
                            <div className="col-md-1">
                              <img src={BestOption2} alt="design" />
                            </div>
                            <div className="col-md-11 pt-2">
                              <p className="solution-sub-title">Tailored Solutions</p>
                              <p className="solution-sub-description">
                                We know that the needs of each business are different. Because of this, we offer app creation services that are made to fit your wants. Whether you need an app for e-commerce, a game, or something else, we can help!                            </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="desktop-view pb-3">
                          <div className="row">
                            <div className="col-md-1">
                              <img src={BestOption3} alt="code" />
                            </div>
                            <div className="col-md-11 pt-2">
                              <p className="solution-sub-title">
                                Timely Delivery
                              </p>
                              <p className="solution-sub-description">
                                We value your time and know how important it is to be on time. With Akkenna, you can be sure that your app will be done on time and will be of good quality. We try to do our best in every job we take on.                            </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="desktop-view pb-3">
                          <div className="row">
                            <div className="col-md-1">
                              <img src={BestOption4} alt="code" />
                            </div>
                            <div className="col-md-11 pt-2">
                              <p className="solution-sub-title">
                                Excellent Customer Support
                              </p>
                              <p className="solution-sub-description">
                                At every stage of making an app, our dedicated support team is always ready to help you. From the first meeting to the time after the start, we'll be there to answer any questions or worries you may have.  </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-3 mb-5 ourservice">
          <div className="container">
            <div className="row text-center d-flex justify-content-center align-items-center">
              <div className="col-lg-10 mt-5">
                <h3 className="fw-bold BringHeading ourservice-title text-smallHeading  circularMedium">
                  Our Website Development Services in Coimbatore
                </h3>
                <p className="our-service-para">
                  Our skilled website developers know how to use the latest web technologies to help you make websites, web platforms, progressive web apps, and other things that will help your business stand out from the tough competition in the market.
                </p>
              </div>
            </div>
            <div className="row justify-content-center mt-3 mb-5">
              <div className="col-lg-10 col-md-12">
                <div id='sliderArrow'>
                  <Slider {...techSetting} >
                    <div className="col-lg-2 techCard">
                      <div className="frontCard">
                        <img src={Hybrid} alt="Software-Development" />
                        <div className="techCard-title">
                          <h6>Hybrid App Development</h6>
                        </div>
                      </div>
                      <div className="backCard">
                        <h6>Hybrid App Development</h6>
                        <p>
                          Hybrid app developers utilize one script to create iOS, Android, and other applications. These applications employ HTML, CSS, and JavaScript.
                        </p>
                        <button className="cardBtnwhite">Learn More</button>
                      </div>
                    </div>
                    <div className="col-lg-2 techCard">
                      <div className="frontCard">
                        <img src={wep} alt="UI-UX-Designing" />
                        <div className="techCard-title">
                          <h6>Web App Development</h6>
                        </div>
                      </div>
                      <div className="backCard">
                        <h6>Web App Development</h6>
                        <p>
                          Web apps must perform properly on mobile devices and be accessible through mobile browsers. These applications run on any device without an app store download.
                        </p>
                        <button className="cardBtnwhite">Learn More</button>
                      </div>
                    </div>
                    <div className="col-lg-2 techCard">
                      <div className="frontCard">
                        <img src={android} alt="Web-Development" />
                        <div className="techCard-title">
                          <h6>Android App Development</h6>
                        </div>
                      </div>
                      <div className="backCard">
                        <h6>Android App Development</h6>
                        <p>
                          Our Android mobile app development company in Coimbatore helps you succeed. Brand protection is possible with mobile app developers.
                        </p>
                        <button className="cardBtnwhite">Learn More</button>
                      </div>
                    </div>
                    <div className="col-lg-2 techCard">
                      <div className="frontCard">
                        <img src={ios} alt="App-Development" />
                        <div className="techCard-title">
                          <h6>iOS App Development</h6>
                        </div>
                      </div>
                      <div className="backCard">
                        <h6>iOS App Development</h6>
                        <p>
                          iOS upgrades constantly protect your data from threats. Consider brand value, scalability, security, aesthetics, and industry attractiveness when hiring an iOS app development provider.
                        </p>
                        <button className="cardBtnwhite">Learn More</button>
                      </div>
                    </div>
                    <div className="col-lg-2 techCard">
                      <div className="frontCard">
                        <img src={native} alt="App-Development" />
                        <div className="techCard-title">
                          <h6>Native App Development</h6>
                        </div>
                      </div>
                      <div className="backCard">
                        <h6>Native App Development</h6>
                        <p>
                          Native app production creates iOS or Android-only mobile applications. These applications use device-specific programming languages and tools. </p>
                        <button className="cardBtnwhite">Learn More</button>
                      </div>
                    </div>
                    <div className="col-lg-2 techCard">
                      <div className="frontCard">
                        <img src={xamarin} alt="App-Development" />
                        <div className="techCard-title">
                          <h6>Xamarin Development</h6>
                        </div>
                      </div>
                      <div className="backCard">
                        <h6>Xamarin Development</h6>
                        <p>
                          Xamarin from Microsoft is a cross-platform mobile application creation tool that lets developers use a single script to make high-quality native mobile apps.                       </p>
                        <button className="cardBtnwhite">Learn More</button>
                      </div>
                    </div>
                    <div className="col-lg-2 techCard">
                      <div className="frontCard">
                        <img src={flutter} alt="App-Development" />
                        <div className="techCard-title">
                          <h6>Flutter App Development</h6>
                        </div>
                      </div>
                      <div className="backCard">
                        <h6>Flutter App Development</h6>
                        <p>
                          This cross-platform framework lets our development team build native Android & iOS apps from a single codebase. We optimize Flutter apps for efficiency.
                        </p>
                        <button className="cardBtnwhite">Learn More</button>
                      </div>
                    </div>
                    <div className="col-lg-2 techCard">
                      <div className="frontCard">
                        <img src={ionic} alt="App-Development" />
                        <div className="techCard-title">
                          <h6>Ionic App Development</h6>
                        </div>
                      </div>
                      <div className="backCard">
                        <h6>Ionic App Development</h6>
                        <p>
                          Ionic makes just mobile app interfaces. Users may enjoy its UI best. ross-platform app development in Coimbatore, Ionic, which is uses AngularJS and HTML5.
                        </p>
                        <button className="cardBtnwhite">Learn More</button>
                      </div>
                    </div>
                    <div className="col-lg-2 techCard">
                      <div className="frontCard">
                        <img src={reactNative} alt="App-Development" />
                        <div className="techCard-title">
                          <h6>React Native App Development</h6>
                        </div>
                      </div>
                      <div className="backCard">
                        <h6>React Native App Development</h6>
                        <p>
                          Smartphone games run everywhere using React Native. React Native mobile app development thinks it gives companies the best experiences.
                        </p>
                        <button className="cardBtnwhite">Learn More</button>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="container-fluid mt-5 mb-5 guide-section">
          <div className="container-fluid">
            <div className="row guide-topic-section">
              <div className="col-md-4">
                <div className="guide-topics">
                  <p>Guide Topics</p>
                </div>
                <div className="guide-menu">
                  <ul className="nav" id="guideNav">
                    <li className="active" onClick={(e) => { handleClick(e, 'question-1') }}>
                      <a href="#question-1">React Native vs Flutter: Deciding on the Right Framework</a>
                    </li>
                    <li onClick={(e) => { handleClick(e, 'question-2') }}>
                      <a href="#question-2">Why build an app?</a>
                    </li>
                    <li onClick={(e) => { handleClick(e, 'question-3') }}>
                      <a href="#question-3">Empowering Mobile Development with JavaScript</a>
                    </li>
                    <li onClick={(e) => { handleClick(e, 'question-4') }}>
                      <a href="#question-4">Our cost estimate for app development services?</a>
                    </li>
                    <li onClick={(e) => { handleClick(e, 'question-5') }}>
                      <a href="#question-5">What kind of apps can your team develop?</a>
                    </li>
                    <li onClick={(e) => { handleClick(e, 'question-6') }}>
                      <a href="#question-6">Why should I choose your mobile app development services for my start-up?</a>
                    </li>
                    <li onClick={(e) => { handleClick(e, 'question-7') }}>
                      <a href="#question-7">Our industries do you serve with mobile app development services in coimbatore?</a>
                    </li>
                    <li onClick={(e) => { handleClick(e, 'question-8') }}>
                      <a href="#question-8">Why Mobile App Development Is Important Your Businesses</a>
                    </li>
                    <li onClick={(e) => { handleClick(e, 'question-9') }}>
                      <a href="#question-9">Benefits of Native Mobile App Development</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-8 col-sm-10">
                <div className="guide-answers circularBook">
                  <div id="question-1" className="guide-details">
                    <p className="guide-sub-question">
                      React Native vs Flutter: Deciding on the Right Framework
                    </p>
                    <p className="guide-sub-question">
                      Flutter
                    </p>
                    <p className="guide-sub-question">
                      Unmatched Performance with Dart
                    </p>
                    <p className="guide-sub-answer">
                      Flutter's secret weapon is Dart, a language designed to optimize performance. By compiling to native
                      ARM code, Flutter achieves top-tier performance levels, ensuring your app runs smoothly on both
                      Android and iOS platforms.
                    </p>
                    <p className="guide-sub-question">
                      Exquisite Customization and Design
                    </p>
                    <p className="guide-sub-answer">
                      With a widget-based architecture, Flutter allows for the creation of stunning custom UIs and seamless
                      animations. Achieve pixel-perfect designs that mirror native experiences on both platforms.
                    </p>
                    <p className="guide-sub-question">
                      Hot Reload for Swift Iterations
                    </p>
                    <p className="guide-sub-answer">
                      Flutter provides a Hot Reload feature, allowing for instantaneous code changes and immediate feedback.
                      This rapid development cycle significantly reduces iteration times and boosts productivity.
                    </p>
                    <p className="guide-sub-question">
                      Single Codebase, Dual Impact
                    </p>
                    <p className="guide-sub-answer">
                      By utilizing a single codebase, Flutter streamlines development efforts. The result? Reduced
                      time-to-market and consistent experiences across both Android and iOS devices.
                    </p>
                    <p className="guide-sub-question">
                      Leverage Familiar Skills
                    </p>
                    <p className="guide-sub-answer">
                      With React Native, your development team can harness their JavaScript expertise, enabling them to
                      build mobile applications seamlessly. This framework leverages the power of JavaScript, a language
                      widely known for its versatility and popularity.
                    </p>
                    <p className="guide-sub-question">
                      Robust Ecosystem & Community Support
                    </p>
                    <p className="guide-sub-answer">
                      Benefit from a thriving ecosystem and a large community of developers. React Native boasts an
                      extensive library of pre-built components and third-party plugins, saving development time and
                      ensuring a smooth workflow.
                    </p>
                    <p className="guide-sub-question">
                      Real-time Updates with Live Reload
                    </p>
                    <p className="guide-sub-answer">
                      React Native offers the invaluable advantage of Live Reload. This feature allows developers to witness
                      real-time updates to the application without the need for a full reload. The result? A better and more
                      effective way to build things.
                    </p>
                    <p className="guide-sub-question">
                      Flexibility and Native Integrations
                    </p>
                    <p className="guide-sub-answer">
                      Customize your application with ease by integrating native modules and libraries, ensuring that your
                      app meets the specific requirements of your project. React Native offers the flexibility needed for
                      tailored solutions.
                    </p>
                    <p className="guide-sub-question">
                      React Native
                    </p>
                    <p className="guide-sub-question">
                      Leverage Familiar Skills
                    </p>
                    <p className="guide-sub-answer">
                      With React Native, your development team can harness their JavaScript expertise, enabling them to
                      build mobile applications seamlessly. This framework leverages the power of JavaScript, a language
                      widely known for its versatility and popularity.
                    </p>
                    <p className="guide-sub-question">
                      Robust Ecosystem & Community Support
                    </p>
                    <p className="guide-sub-answer">
                      Benefit from a thriving ecosystem and a large community of developers. React Native boasts an
                      extensive library of pre-built components and third-party plugins, saving development time and
                      ensuring a smooth workflow.
                    </p>
                    <p className="guide-sub-question">
                      Real-time Updates with Live Reload
                    </p>
                    <p className="guide-sub-answer">
                      React Native offers the invaluable advantage of Live Reload. This feature allows developers to witness
                      real-time updates to the application without the need for a full reload. The result? A better and more
                      effective way to build things.
                    </p>
                    <p className="guide-sub-question">
                      Flexibility and Native Integrations
                    </p>
                    <p className="guide-sub-answer">
                      Customize your application with ease by integrating native modules and libraries, ensuring that your
                      app meets the specific requirements of your project. React Native offers the flexibility needed for
                      tailored solutions.
                    </p>
                    <p className="guide-sub-answer">
                      The choice between React Native and Flutter hinges on your project's unique needs. React Native is
                      ideal for leveraging existing JavaScript expertise, offering a mature ecosystem, and providing
                      real-time updates. On the other hand, Flutter excels in crafting highly customized, performant
                      applications with a single codebase.
                    </p>
                    <p className="guide-sub-answer">
                      At Akkenna, we specialize in both React Native and Flutter development. Our seasoned team of
                      developers will work closely with you to determine the best framework for your project, ensuring a
                      seamless, high-quality mobile application tailored to your specifications.
                    </p>
                  </div>
                  <div id="question-2" className="guide-details">
                    <p className="guide-sub-question">
                      Why build an app?
                    </p>
                    <p className="guide-sub-answer">
                      Every business, whether it's new or old, needs to have a mobile app now. You can't ignore a business's benefits if you own it. Here are some of the ways that business can benefit from making mobile apps.
                    </p>
                    <p className="guide-sub-question">
                      Build a stronger brand
                    </p>
                    <p className="guide-sub-answer">
                      People are more interested in your brand when you give them more value. But if you don't do anything to build your brand, you can't have a deep brand experience. At this point, branded apps for phones come into play.
                    </p>
                    <p className="guide-sub-answer">
                      The way we live is becoming more and more dependent on mobile apps. This is a way for app makers to help their business. The more brand-building features your app has, the better results you can expect from your brand marketing. You can even use this app to learn more about your target audience and improve how you market your brand.
                    </p>
                    <p className="guide-sub-question">
                      Achieve increased customer engagement level
                    </p>
                    <p className="guide-sub-answer">
                      Focusing on getting customers more involved is one of the best ways to bring in more money and make more people aware of your brand. If you can get your customers to do business with you more often, your return on investment, customer lifetime value, and other important numbers will go up. If app makers want more people to use their app, they need to add certain features.
                    </p>
                    <p className="guide-sub-answer">
                      For them, it may be most important to go after different groups of people. This means putting people into different groups and deciding what kind of information goes to each group. By putting your users into groups, you can make more accurate ideas, send personalized messages, and learn more about your customers' travels.
                    </p>
                    <p className="guide-sub-answer">
                      Reward programs, regularly added new features, and deals are some other in-app features that can help get customers more involved. Along with the tasks, it's important to pay attention to the UI/UX design. If the user experience of your app is bad, people may delete it and switch to a similar app that is better.
                    </p>
                    <p className="guide-sub-question">
                      Have a competitive edge in your niche
                    </p>
                    <p className="guide-sub-answer">
                      In the modern world, where things change all the time, it's hard to keep up with your rivals. With a mobile app, you have a big advantage over your rivals.
                    </p>
                    <p className="guide-sub-answer">
                      Mobile apps are much better than traditional websites and digital platforms when it comes to fast contact and making the customer experience better. When you think about all the ways a mobile app plan can help your business, its total effect on how much money you make will become clear.
                    </p>
                    <p className="guide-sub-question">
                      Make a custom marketing channel
                    </p>
                    <p className="guide-sub-answer">
                      The marketing department is another part of a business that can get a lot out of building a business mobile app. When businesses choose mobile apps, the first and most important benefit for digital marketing is that they have direct access to information about users.
                    </p>
                    <p className="guide-sub-answer">
                      The information you get from users' sessions in the app can help you improve your marketing strategies and efforts. Once your marketers have all the information they need, an app makes it easier for them to send more effective and personalized content to users than through standard marketing methods.
                    </p>
                  </div>
                  <div id="question-3" className="guide-details">
                    <p className="guide-sub-question">
                      Empowering Mobile Development with JavaScript </p>
                    <p className="guide-sub-question">
                      Leverage Familiar Skills
                    </p>
                    <p className="guide-sub-answer">
                      With React Native, your development team can harness their JavaScript expertise, enabling them to build mobile applications seamlessly. This framework leverages the power of JavaScript, a language widely known for its versatility and popularity.
                    </p>
                    <p className="guide-sub-question">
                      Robust Ecosystem & Community Support
                    </p>
                    <p className="guide-sub-answer">
                      Benefit from a thriving ecosystem and a large community of developers. React Native boasts an extensive library of pre-built components and third-party plugins, saving development time and ensuring a smooth workflow.
                    </p>
                    <p className="guide-sub-question">
                      Real-time Updates with Live Reload
                    </p>
                    <p className="guide-sub-answer">
                      React Native offers the invaluable advantage of Live Reload. This feature allows developers to witness real-time updates to the application without the need for a full reload. The result? A better and more effective way to build things.
                    </p>
                    <p className="guide-sub-question">
                      Flexibility and Native Integrations
                    </p>
                    <p className="guide-sub-answer">
                      Customize your application with ease by integrating native modules and libraries, ensuring that your app meets the specific requirements of your project. React Native offers the flexibility needed for tailored solutions.
                    </p>

                  </div>
                  <div id="question-4" className="guide-details">
                    <p className="guide-sub-question">
                      Our cost estimate for app development services? </p>
                    <p className="guide-sub-answer">
                      Some simple factors can be used to guess how much it will cost to make an app. It includes the scope of the project, the features and functions that are needed, the base or structure of the app, and the needs for the design and development process.
                    </p>
                    <p className="guide-sub-question">
                      What's needed
                    </p>
                    <p className="guide-sub-answer">
                      We look at the type of app, who it is meant for, and any other features that the client wants. With this information, we can figure out how much work needs to be done and what tools are needed to do it.
                    </p>
                    <p className="guide-sub-question">
                      Connections </p>
                    <p className="guide-sub-answer">
                      We also pay attention to the app's features, such as online payment handling or social media platforms. Our experts in planning look at the needs of the growth as the design gets more complicated and more resources are needed.
                    </p>
                    <p className="guide-sub-question">
                      Date Range
                    </p>
                    <p className="guide-sub-answer">
                      Once we know what your dream app needs to do, we give you a thorough cost estimate that includes the development timeline, the prices at each stage of the project, and a breakdown of the resources.
                    </p>
                    <p className="guide-sub-answer">
                      Overall, the cost estimate for our mobile app creation services relies on a number of things, like the size of the platform, the most important features, the development platform, and how hard it is to create and launch the app.
                    </p>

                  </div>
                  <div id="question-5" className="guide-details">
                    <p className="guide-sub-question">
                      What kind of apps can your team develop?
                    </p>
                    <p className="guide-sub-answer">
                      As a renowned software & app development company, we have the expertise to develop a wide range of applications for mobiles, desktops, and more. Here are the types of apps we build:
                    </p>
                    <p className="guide-sub-answer">
                      <b>Native mobile apps</b> – Our team has an understanding and prior experience in the latest mobile technologies responsible for powering highly-functional apps.
                    </p>
                    <p className="guide-sub-answer">
                      <b>Web apps</b> – Our team is well-versed in various web development technologies that play a vital role in the development of efficient web applications.
                    </p>
                    <p className="guide-sub-answer">
                      <b>E-commerce apps</b> – Our top developers acquire extensive experience in crafting e-commerce apps that handle large amounts of traffic at once.
                    </p>
                    <p className="guide-sub-answer">
                      <b>Enterprise apps</b> – We can develop enterprise-level applications that are designed for the internal operations of large businesses. Our team offers custom solutions for HR and finance systems, project management tools, and more.
                    </p>
                    <p className="guide-sub-answer">
                      <b>Gaming apps</b> – Our developers have the technical flair to build games that revive the experience for online gaming enthusiasts.
                    </p>
                    <p className="guide-sub-answer">
                      We are always looking to push the boundaries and explore new technologies, so if you have a specific concept in mind, please don't hesitate to reach out to us. Akkenna is happy to assess your needs and provide you with an accurate cost estimate.
                    </p>

                  </div>
                  <div id="question-6" className="guide-details">
                    <p className="guide-sub-question">
                      Why should I choose your mobile app development services for my start-up?
                    </p>
                    <p className="guide-sub-answer">
                      We believe that a start-up idea spawns from a basic problem and its most systematic solution. This acknowledgment reflects in our mobile app development services. Here are a few more reasons why you should choose our bespoke solutions:
                    </p>
                    <p className="guide-sub-answer">
                      <b>Expertise:</b> Our developers have the know-how in app development technologies for native and multi-device platforms. Our IT solutions exceed your expectations.
                    </p>
                    <p className="guide-sub-answer">
                      <b>Customization:</b>We understand that every start-up has its set of requirements. This is why we spend a fair share of research time coming up with a data-backed roadmap.
                    </p>
                    <p className="guide-sub-answer">
                      <b>Cost-efficiency:</b>Our services are designed to provide you with the best value for your money and trust in Akkenna. We offer affordable services without compromising on quality or delivery time.
                    </p>
                    <p className="guide-sub-answer">
                      <b>Timely delivery:</b>We understand the importance of delivering on time, which is why we work closely with you to complete the app within the timeframe.
                    </p>
                    <p className="guide-sub-answer">
                      <b>Technical support:</b>Our team reckons the need for post-development assistance & offers technical support to assist with any technical issues you may encounter.
                    </p>
                    <p className="guide-sub-answer">
                      Our conviction to deliver the best in every aspect and a strong understanding of app and web technologies make us an ideal choice for your booming start-up.
                    </p>

                  </div>
                  <div id="question-7" className="guide-details">
                    <p className="guide-sub-question">
                      Our industries do you serve with mobile app development services in coimbatore? </p>
                    <p className="guide-sub-answer">
                      As the first app creation business in the United States, we work with many different industries and fields. Some of them are:
                    </p>
                    <p className="guide-sub-answer">
                      <b>Healthcare:</b> We help healthcare businesses make mobile apps and CRM sites that make it easier to keep track of patient information, schedule meetings, and talk to the staff.
                    </p>
                    <p className="guide-sub-answer">
                      <b>E-commerce:</b>Our e-learning creation services make it easy and fun to learn. Through our e-learning services, you can get access to study materials, school tasks, and other things.
                    </p>
                    <p className="guide-sub-answer">
                      <b>Education:</b>We help insurance companies and banks make apps that make it easy for their users to handle their money.
                    </p>
                    <p className="guide-sub-answer">
                      <b>Financial services:</b>We help insurance firms, and financial institutions develop apps that offer customers a hassle-free experience in money management.
                    </p>
                    <p className="guide-sub-answer">
                      <b>Hospitality:</b>Additionally, our application solutions are tailored for hotels, resorts, and hospitality enterprises. We develop platforms for the hospitality industry that facilitate online booking and other time-saving features.
                    </p>
                    <p className="guide-sub-answer">
                      <b>Logistics and transportation:</b>Our transportation software and application creation services make it easier to ship goods, track them, check on employees, and handle schedules.
                    </p>
                    <p className="guide-sub-answer">
                      Aside from these areas, our team is always interested in finding new ideas and building digital platforms for them with custom app design solutions.

                    </p>
                  </div>
                  <div id="question-8" className="guide-details">
                    <p className="guide-sub-question">
                      Why Mobile App Development Is Important Your Businesses </p>
                    <p className="guide-sub-question">
                      Work on User Expectation
                    </p>
                    <p className="guide-sub-answer">
                      Mobile apps are important for meeting the needs of users. Users are usually happy to find a mobile app that is easy to use and does what they need it to do without making them do anything extra. Integrating third-party tools into mobile apps can make the user experience better.
                    </p>
                    <p className="guide-sub-question">
                      Strengthening Reliable Consumers Base
                    </p>
                    <p className="guide-sub-answer">
                      Another good thing about having a mobile app for your business is that it helps you keep track of what your customers want. Companies can keep up with their customers by giving them freebies, tickets, and other types of savings and deals. The plan lets you give deals to people who use your mobile app and is said to be the best way to do things..
                    </p>
                    <p className="guide-sub-question">
                      Helps in Business Growth and Deals
                    </p>
                    <p className="guide-sub-answer">
                      Businesses often look at new methods on the market that can be used to make mobile apps for their cars. With the number of people who use mobile apps on the rise, there should be more deals that meet the needs of customers. Mobile apps are a great way to make sure you're making progress in a short amount of time.
                    </p>
                    <p className="guide-sub-question">
                      Better Understanding of The Customers
                    </p>
                    <p className="guide-sub-answer">
                      The mobile app gives businesses a great chance to reach out to their target users. In particular, the mobile app gives groups a huge way to handle study and use information about users. Learning how your customers think, what they like, and who they are can help you make the right strategic plans that center on your marketing methods. Organizations can improve the user experience by using these marketing strategies.
                    </p>
                    <p className="guide-sub-question">
                      Business Plans Analysis
                    </p>
                    <p className="guide-sub-answer">
                      The mobile app makes it easy for companies to get accurate, detailed data that can help them predict the future of their businesses. The mobile app helps companies collect important data, like the number of users, how long they spent on a certain page, how many times they've been to that page, and so on.
                    </p>
                    <p className="guide-sub-question">
                      Ditch The Geographical Location Barriers
                    </p>
                    <p className="guide-sub-answer">
                      With many mobile apps, the position of your business is no longer a problem. It lets you, your employees, and your clients stay in touch, even if you are in different time zones or in different places. It gives buyers access to a market that used to be hard for standard businesses to reach.
                    </p>
                    <p className="guide-sub-question">
                      Customization
                    </p>
                    <p className="guide-sub-answer">
                      People like apps because they help their users feel like they are special. Whenever you go to a website, you are always a "guest." That's not the case with mobile apps, which give you the feeling of being at home with their personalized features and functions.
                    </p>

                  </div>
                  <div id="question-9" className="guide-details">
                    <p className="guide-sub-question">
                      Benefits of Native Mobile App Development </p>
                    <p className="guide-sub-question">
                      Exclusive User Experience
                    </p>
                    <p className="guide-sub-answer">
                      If you ask any skilled app developer, they will all say that native mobile app development gives the best user experience that no other mobile app development can. Since they are built with languages, APIs, and tools that are specific to a platform, they tend to work faster and take less time to process. They can offer easy-to-use app experiences because the app's features are already there and it loads faster. On top of that, they can give users a unique experience even if they have a lot of features and functions.
                    </p>
                    <p className="guide-sub-question">
                      Familiar Look and Feel
                    </p>
                    <p className="guide-sub-answer">
                      It's not hard to figure out that every mobile device has its own apps that come with it. When you make a native mobile app for a certain operating system, it will usually look and feel the same to the users. It's the same as the apps that are already on the gadget. This kind of uniformity and likeness is important because it makes the user's experience feel more real. Non-native mobile apps will try to copy the look and feel of a local app, but they will never be exactly the same. This can make customers feel "off" about the app. It fails to make app users feel like they have something in common.
                    </p>
                    <p className="guide-sub-question">
                      More Secure
                    </p>
                    <p className="guide-sub-answer">
                      Security flaws depend a lot on the operating system and how well the code for an app is written. But the risks are higher for some types of mobile apps, like mixed mobile apps, because they are vulnerable to both weaknesses in the computer language they use and in web browsers. Most of the time, security and data protection are more reliable with native apps.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        {

          <ContactForm />

        }

        <section>
          <div className="homeFaq">
            <div className="container">
              <div className="genaral-title">
                <div className="row d-flex justify-content-center align-item-center">
                  <div className="col-md-7 text-center">
                    <h3 className='text-Heading '>Frequently Asked Question</h3>
                  </div>
                </div>
              </div>
              <div className="accordion accordion-faq nunitoMedium" id="accordionfaqExample">
                <div className="row d-flex justify-content-center align-item-center">
                  <div className="col-md-6">
                    <div className="accordion-item">
                      <h3 className="accordion-header" id="faq-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                          How much does it cost to develop a mobile app?
                        </button>
                      </h3>
                      <div id="faq-collapseOne" className="accordion-collapse collapse" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                        <div className="accordion-body">
                          Features, technologies, and complexity of mobile applications vary from one to another, so does
                          the cost of mobile app development. We would recommend you to have a discussion with your mobile
                          app development company to know the cost of mobile app development according to your requirement.                      </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h3 className="accordion-header" id="faq-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
                          How long does it take to develop a mobile application?
                        </button>
                      </h3>
                      <div id="faq-collapseTwo" className="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#accordionfaqExample">
                        <div className="accordion-body">
                          <p>
                            The time required to develop a mobile application can vary widely based on several factors.
                            These include the complexity of the app, the number of features, the platform(s) it's being
                            developed for (iOS, Android, or both), and the level of customization needed. Here's a rough
                            breakdown:
                          </p>
                          <p><b>Simple Apps (1-3 months):</b>Basic applications with limited features, simple user
                            interfaces, and minimal backend integration typically take around 1 to 3 months.</p>
                          <p><b>Moderate Complexity (3-6 months):</b>Apps with a moderate level of complexity, more
                            features, user authentication, database integration, and some level of custom design may take
                            between 3 to 6 months.</p>
                          <p><b>Complex Apps (6+ months):</b>High-end applications with advanced features, complex backend
                            systems, real-time interactions, custom animations, and extensive user interactions can take 6
                            months or more.</p>
                          <p><b>Games and Highly Specialized Apps (Varies):</b>Developing games, augmented reality (AR), or
                            virtual reality (VR) applications can significantly vary in time frame depending on the level of
                            sophistication and graphics involved.</p>
                          <p><b>Cross-Platform vs. Native Development:</b>Cross-platform development using frameworks like
                            React Native or Flutter can sometimes reduce development time compared to building separate
                            native apps for iOS and Android.</p>
                          <p><b>Testing and Debugging (Varies):</b>This phase can add a significant amount of time, as
                            thorough testing is crucial to ensure the app functions correctly on all devices and platforms.
                          </p>
                          <p><b>App Store Approval (Varies):</b>After development, the app needs to go through a review
                            process by Apple's App Store and Google Play, which can take a few days to a couple of weeks.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h3 className="accordion-header" id="faq-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
                          What do mobile application development services include?
                        </button>
                      </h3>
                      <div id="faq-collapseThree" className="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#accordionfaqExample">
                        <div className="accordion-body">
                          Our mobile application development services include app prototyping & strategy, custom mobile app
                          development, progressive web app development, mobile application UI/UX design, testing, and
                          post-maintenance support. Apart from these services, we offer the existing mobile app revamp
                          services to improve their performance.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h3 className="accordion-header" id="faq-headingSeven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseSeven" aria-expanded="false" aria-controls="faq-collapseSeven">
                          Can the NDA be customized for my specific project?
                        </button>
                      </h3>
                      <div id="faq-collapseSeven" className="accordion-collapse collapse" aria-labelledby="faq-headingSeven" data-bs-parent="#accordionfaqExample">
                        <div className="accordion-body">
                          Yes, absolutely. We understand that every project is unique. Our NDA can be tailored to address
                          the specific details and concerns of your app development project. This includes provisions
                          related to the duration of confidentiality, exceptions, and project-specific information.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="accordion-item">
                      <h3 className="accordion-header" id="faq-headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFour" aria-expanded="false" aria-controls="faq-collapseFour">
                          What if I want to develop my mobile application for both Android and iOS?
                        </button>
                      </h3>
                      <div id="faq-collapseFour" className="accordion-collapse collapse" aria-labelledby="faq-headingFour" data-bs-parent="#accordionfaqExample">
                        <div className="accordion-body">
                          We offer cross-platform mobile app development services that are compatible with both Android and
                          iOS operating systems. This means minimal efforts at the developer's end, we can build an
                          application that will be compatible working on both Android and iOS. Our mobile app developers can
                          also assist you with the development of native platform mobile applications for any single
                          operating system i.e. Android or iOS.

                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h3 className="accordion-header" id="faq-headingFive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFive" aria-expanded="false" aria-controls="faq-collapseFive">
                          Will I be getting any support after project completion?
                        </button>
                      </h3>
                      <div id="faq-collapseFive" className="accordion-collapse collapse" aria-labelledby="faq-headingFive" data-bs-parent="#accordionfaqExample">
                        <div className="accordion-body">
                          You will be really happy to know that we are always here to assist you with your problems. Unlike
                          other company believes that work doesn’t end once the App is created. It suggests that you can get
                          maintenance and customer support whenever you are in need even after the process completion.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h3 className="accordion-header" id="faq-headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseSix" aria-expanded="false" aria-controls="faq-collapseSix">
                          How does your business go about making mobile apps?
                        </button>
                      </h3>
                      <div id="faq-collapseSix" className="accordion-collapse collapse" aria-labelledby="faq-headingSix" data-bs-parent="#accordionfaqExample">
                        <div className="accordion-body">
                          <ul>
                            <li>Getting requirements
                            </li>
                            <li>UI and UX design</li>
                            <li>App creation (writing code)</li>
                            <li>Testing and Putting It Live
                            </li>
                            <li>Help and upkeep
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h3 className="accordion-header" id="faq-headingEight">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseEight" aria-expanded="false" aria-controls="faq-collapseEight">
                          How will I be able to communicate with your team to monitor the project's progress?
                        </button>
                      </h3>
                      <div id="faq-collapseEight" className="accordion-collapse collapse" aria-labelledby="faq-headingEight" data-bs-parent="#accordionfaqExample">
                        <div className="accordion-body">
                          <p>Our clients are able to communicate with us promptly. We make use of the following tools to
                            allow our clients to track their project’s progress.
                          </p>
                          <ul>
                            <li>Microsoft Teams
                            </li>
                            <li>Zoom</li>
                            <li>WhatsApp</li>
                            <li>Active Collab/JIRA
                            </li>
                          </ul>
                          <p>Our clients and we use these tools to ensure smooth communication. Apart from these tools, you
                            can suggest any other tools you feel comfortable with.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

    </HelmetProvider>
  )
}

export default AppDevelopment