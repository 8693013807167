import React, { useState } from 'react'
import { useFormik } from 'formik';
import axios from 'axios';
import Swal from 'sweetalert2'
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../../Style/NewCareer.css'
import '../../Style/NewCareerResponsive.css'


const CareerForm = () => {



    const [Loading,SetLoading]=useState(false)


    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            File_Id:'',
            message: '',
        },
        validate: (values) => {
            const errors = {}

            if (!values.name) {
                errors.name = 'Required*';
            }
            if (!values.phone) {
                errors.phone = 'Required*';
            } else if (!/^\d{10}$/.test(values.phone)) {
                errors.phone = 'Must be exactly 10 digits';
            }
            if (!values.email) {
                errors.email = 'Required*';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Must be valid e-mail';
            }
            if(values.File_Id ){
                if (values.File_Id.size > 1000000) {
                    errors.File_Id = 'Required less than 1MB*';
                }
            }
            

            if (!values.message) {
                errors.message = 'Required*';
            } else if (values.message.length > 5000) {
                errors.message = 'Must be 5000 characters or less';
            }

            if (Object.keys(errors).length === 0) {
                formik.setStatus({ isSubmitting: true });
            }

            return errors;
        },
        onSubmit: async(values, { setSubmitting, resetForm }) => {

            SetLoading(true)

            const FormImage = new FormData()
            FormImage.append('files',values.File_Id)

            const response = await axios.post('https://api.akkenna.com/api/upload',FormImage)
            if(response.data[0].id){

                formik.values.id = response.data[0].id
              const res = await axios.post('https://api.akkenna.com/api/mailing/careers',values)
            
              if(res.status == 200){
                SetLoading(false)
                Swal.fire({
                    text: "Form Submitted Succesfully.We will get back to you soon",
                    imageUrl: "https://akkenna.com/static/media/logo.527ce9569c515d13529371370e18d69f.svg",
                    imageWidth: 200,
                    imageHeight: 20,
                    imageAlt: "Custom image",
                    showConfirmButton: true,
                    confirmButtonText: "Thank You",
                  }).then(function() {
                    navigate('/')
                })
              }
            
            }
           
        
            resetForm()
            setTimeout(() => {
                setSubmitting(false);
            }, 2000);
            
        },
    });



  return (
    <main id="career">
        <div className="container-fluid FormbackgroundBg marginTop pt-5 pb-5">
                <div className="container">
                    <div className="row formRow">
                        <div className="col-lg-9">
                            <div className='FormHead'>
                                <h2 className="frmTitle">Career Registration Form</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-3">
                        <div className="col-lg-9 contact-form">
                            <form onSubmit={formik.handleSubmit} >
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-lg-6 col-md-7 col-sm-10 mt-4">
                                        <input type="text" name="name" id="name"
                                            placeholder='Enter Your Name'
                                            onBlur={formik.handleChange}
                                            onChange={formik.handleChange}
                                            value={formik.values.name} />
                                        <p className='text-danger'>{formik.errors.name}</p>
                                    </div>
                                    <div className="col-lg-6 col-md-7 col-sm-10 mt-4">
                                        <input type="email" name="email" id="email"
                                            placeholder='Enter Your E-mail'
                                            onBlur={formik.handleChange}
                                            onChange={formik.handleChange}
                                            value={formik.values.email} />
                                        <p className='text-danger'>{formik.errors.email}</p>
                                    </div>
                                    <div className="col-lg-6 col-md-7 col-sm-10 mt-4">
                                        <input type="text" name="phone" id="phone"
                                            onBlur={formik.handleChange}
                                            onChange={formik.handleChange}
                                            placeholder='Enter Your Phone Number'
                                            value={formik.values.phone} />
                                        <p className='text-danger'>{formik.errors.phone}</p>
                                    </div>
                                    <div className="col-lg-6 col-md-7 col-sm-10 mt-4">
                                        <input type="file" name="File_Id" id='File_Id'
                                            onChange={(e)=>{
                                                formik.setFieldValue('File_Id',e.target.files[0])
                                            }}
                                            placeholder='Upload id'
                                            accept="application/pdf"
                                            />
                                        <p className='text-danger'>{formik.errors.File_Id}</p>
                                    </div>
                                    <div className="col-lg-12 col-md-7 col-sm-10 mt-4">
                                        <textarea name="message"
                                            onBlur={formik.handleChange}
                                            onChange={formik.handleChange}
                                            placeholder='Enter Your Message'
                                            cols="30" rows="10"
                                        ></textarea>
                                        <p className='text-danger'>{formik.errors.message}</p>
                                    </div>
                                    <div className='submit-btn mt-4'>
                                        <button type='button' onClick={formik.handleSubmit} name="submit">
                                            {
                                                Loading ? <Spinner/> : "Apply Now"
                                            }
                                            
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    </main>
  )
}

export default CareerForm