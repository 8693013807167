import '../../../Style/animation.css'
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import graphics from '../../../Assets/Image/service-page/motion-graphics-banner.webp'
import motion from '../../../Assets/Image/service-page/Explainer-Videos-motion.svg'
import Animation from '../../../Assets/Image/service-page/Logo-Animation-motion.svg'
import Corporate from '../../../Assets/Image/service-page/Corporate-Presentations-motion.svg'
import Advertisements from '../../../Assets/Image/service-page/Motion-Graphic-Advertisements-motion.svg'
import Infographics from '../../../Assets/Image/service-page/Infographics-motion.svg'
import Demonstrations from '../../../Assets/Image/service-page/Product-Demonstrations-motion.svg'
import VFX from '../../../Assets/Image/service-page/VFX-motion.svg'
import Educational from '../../../Assets/Image/service-page/Educational-Materials-motion.svg'
import graphicdesign from '../../../Assets/Image/service-page/web-animation-graphic-design-2.webp'
import ConceptDev from "../../../Assets/Image/service-page/concept-development-icon.svg"
import StoryBording from "../../../Assets/Image/service-page/storybording-icon.svg"
import Design from "../../../Assets/Image/service-page/design-icon.svg"
import Sound from "../../../Assets/Image/service-page/sound-icon.svg"
import Review from "../../../Assets/Image/service-page/review-icon.svg"
import SkaterBoy from '../../../Assets/Image/animation/Skater-Boy.webp'
import MadebyRadio from '../../../Assets/Image/animation/Latest-Projects-_-MadebyRadio.webp'
import Producer from '../../../Assets/Image/animation/Producer.gif'
import KineticTypography from '../../../Assets/Image/service-page/kinetic-typography.gif'
import ExplainerVideo from '../../../Assets/Image/service-page/Explainer-Video.gif'
import MotionDesign from '../../../Assets/Image/animation/Motion-Design_-35-Funny-Animations-by-Markus-Magnusson.webp'
import Infographic from '../../../Assets/Image/service-page/infographic.gif'
import ProductAnimation from '../../../Assets/Image/service-page/product-animation.gif'
import LogoAnimation from '../../../Assets/Image/service-page/logo-animation.gif'
import VideoGameAnimation from '../../../Assets/Image/service-page/video-game-animation.gif'
import ContactForm from '../../../Components/ContactForm';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const MotionGraphics = () => {

  const listItem = document.querySelectorAll(".list");

  function activateLink() {
    listItem.forEach((item) => {
      item.classList.remove("active");
    });
    this.classList.add("active");
  }

  listItem.forEach((item) => {
    item.addEventListener("click", activateLink);
  });

  const [scrollToPos, setScrollToPos] = useState(0);

  const handleClick = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    const container = document.querySelector(".guide-answers");
    const containerTop = container.getBoundingClientRect().top;
    const currentScrollTop = container.scrollTop;
    const selectionTop = targetElement.getBoundingClientRect().top;

    setScrollToPos(selectionTop - containerTop + currentScrollTop);

    // Optionally, you can add smooth scrolling animation here
    container.scrollTo({
      top: scrollToPos,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to the desired position when scrollToPos changes
    const container = document.querySelector(".guide-answers");
    container.scrollTo({
      top: scrollToPos,
      behavior: "smooth",
    });
  }, [scrollToPos]);


  useEffect(() => {
    const handleScroll = () => {
      let containerTop = document.getElementsByClassName("guide-answers")[0].getBoundingClientRect();
      let questionOne = document.getElementById("question-1").getBoundingClientRect();
      let questionTwo = document.getElementById("question-2").getBoundingClientRect();
      let questionThree = document.getElementById("question-3").getBoundingClientRect();
      let questionFour = document.getElementById("question-4").getBoundingClientRect();
      let questionFive = document.getElementById("question-5").getBoundingClientRect();
      let questionSix = document.getElementById("question-6").getBoundingClientRect();

      let topCondition = containerTop.top + containerTop.top / 2;

      if (questionOne.top < topCondition && questionOne.top > 0) {
        setActiveClass('#guideNav li a[href="#question-1"]');
      } else if (questionTwo.top < topCondition && questionTwo.top > 0) {
        setActiveClass('#guideNav li a[href="#question-2"]');
      } else if (questionThree.top < topCondition && questionThree.top > 0) {
        setActiveClass('#guideNav li a[href="#question-3"]');
      } else if (questionFour.top < topCondition && questionFour.top > 0) {
        setActiveClass('#guideNav li a[href="#question-4"]');
      } else if (questionFive.top < topCondition && questionFive.top > 0) {
        setActiveClass('#guideNav li a[href="#question-5"]');
      } else if (questionSix.top < topCondition && questionSix.top > 0) {
        setActiveClass('#guideNav li a[href="#question-6"]');
      }
    };

    const setActiveClass = (selector) => {
      document.getElementById("guideNav").querySelectorAll('li').forEach((li) => {
        li.classList.remove("active");
      });
      document.querySelector(selector).parentNode.classList.add("active");
    };

    // Attach scroll event listener
    document.getElementsByClassName("guide-answers")[0].addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      document.getElementsByClassName("guide-answers")[0].removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures that this effect runs once on mount



  const options = {
    loop: true,
    margin: 10,
    dots: false,
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
        loop: false
      },
      600: {
        items: 1,
        nav: true,
        loop: false
      },
      900: {
        items: 1.5,
        nav: true,
        loop: false
      },
      1000: {
        items: 2.5,
        nav: true,
        loop: false
      },
      1300: {
        items: 3,
        nav: true,
        loop: false
      }
    }
  }

  return (
    <HelmetProvider>
    <Helmet>
                <title>Professional 2D and 3D Explainer Video Services in India | Akkenna</title>
                <meta name="description"
                    content="Transform your ideas into captivating visuals with Akkenna's expert 2D and 3D explainer video services in India. Elevate your brand and more" />
                <meta name="keywords"
                    content="explainer video services in india, 2d and 3d animation service,  2d and 3d animated explainer video service, 3D animation services in india" />
            </Helmet>
    <main id="motiongraphics">
      <section className="banner">
        <div className="container-fluid">
          <div className="container bannerCont">
            <div className="row justify-content-between">
              <div className="col-lg-7 bannerLeft">
                <div>
                  <h1 className="text-white">
                    Move Your Brand To The Next Level With The Best Motion Graphics Company in India
                  </h1>
                </div>
                <div className='animation-bannerpara'>
                  <p className="h6 text-bannerpara nunitoMedium">
                    Bringing Imagination to Life, Frame by Frame: Your Journey to Stunning Motion Graphics Starts Here in India!
                  </p>
                </div>
                <div className="animation-bannerBtn">
                  <a href="/contact"  target='_blank'>
                    <button className="whiteBtn bg-whiteButton text-smallHeading fw-bold">
                      Ignite creativity now!
                    </button>
                  </a>
                </div>
              </div>
              <div className="col-lg-5 bannerRightImage">
                <div className="animation-banner">
                  <img src={graphics} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <Container fluid className='mt-5 mb-5 video-explain'>
        <div className="container">
          <div className="row text-center d-flex justify-content-center align-items-center">
            <div className="col-lg-10">
              <h3 className="fw-bold BringHeading">
                Look Out For Some Motion Graphics Videos In The Gallery
              </h3>
              <p className="animation-text-para nunitoRegular">
                Motion graphics are an exciting visual medium that blends graphic design, animation, and sound to provide instructive and entertaining stories. Animation is a visual and auditory form of communication that helps tell stories, explain concepts, and get the point across. Explore Akkenna Motion Graphics Video Company’s gallery now.
              </p>
            </div>
          </div>
        </div>
        <Container>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className='d-flex flex-column justify-content-center align-items-center' >
              <Col lg={9} className="row text-center d-flex justify-content-center align-items-center video-explainer">
                <Nav variant="pills" className='d-flex justify-content-center align-items-center' >
                  <Nav.Item className="col-md-4 video-explainer-title explainer active">
                    <Nav.Link eventKey="first" className="video-explainer-text">Animated Explainer Video</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="col-md-4 video-explainer-title whiteboard">
                    <Nav.Link eventKey="second" className="video-explainer-text">Whiteboard Video</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="col-md-4 video-explainer-title live">
                    <Nav.Link eventKey="third" className="video-explainer-text">Live Video Production</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={10}>
                <Tab.Content>
                  <Tab.Pane eventKey="first" className="row justify-content-center video-cards">
                    <div className="technologyCards">
                      <div>
                        <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/KmSSQveJ258?si=4zC6ZAHjwH7xCitx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      </div>
                      <div>
                        <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/8XST11KhL7k?si=5gimrlerw1JRIYWM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second" className="row justify-content-center video-cards">
                    <div className="technologyCards">
                      <div>
                        <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/4xwQpzeiHLE?si=G80kQjXKgZ2gWGO-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      </div>
                      <div>
                        <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/FDZVGJIM0W8?si=G_TcVgsO77bNWKEV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div className="technologyCards">
                      <div>
                        <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/AEEWRfU4Er8?si=Dc3T4uw07ufaCUOb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      </div>
                      <div>
                        <iframe width="400" height="220" style={{ borderRadius: "10px" }} src="https://www.youtube.com/embed/bBP4HskFDBg?si=3ckJAehRW0FvB2_8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Container>


      <section className="container-fluid animation hireLabelTag pt-4 pb-4">
        <div className="container">
          <div className="row justify-content-center align-items-center">

            <div className="col-md-5 col-lg-5 HireHeading text-right">
              <h4 className="text-white">Need Help With a Project?</h4>
              <h4 className="text-white" style={{ display: "none" }}>
                Planning a Project?
              </h4>
            </div>
            <div className="col-md-2 col-lg-2 hire-arrowImg text-left">
              <img src={arrow} alt="arrow" />
            </div>
            <div className="col-md-3 col-lg-3 hirePosition text-center">
              <img src={btnImage} alt="" style={{ display: "none" }} />
              <div className="hirebtnbg hirePositionRelatbutton">
                <button className="whiteBtn bg-whiteButton text-smallHeading fw-bold text-center hireBtn">
                  <a href="/contact"  target='_blank'>Reach Out</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}



      <section className="container-fluid mt-5 process-section">
        <div className="container">
          <div className="row text-center d-flex justify-content-center align-items-center">
            <div className="col-md-10">
              <div className="process-title">
                <p>Our Process of Creating Motion Graphics</p>
              </div>
            </div>
            <div className="col-md-10 process-description nunitoRegular">
              <div className="">
                <p>
                  At Akkenna Animation, we use an approach that is both methodical and creative, and we do this in order to make sure that the motion graphics project we work on for you exceeds your expectations. An explanation of how we carry out motion graphics projects, broken down into their component parts, is as follows
                </p>
              </div>
            </div>
          </div>
          <div className="row text-center d-flex justify-content-between align-item-center">
            <div className="col-lg-2 col-md-2 centered-div">
              <div className="content">
                <p className="rounded-circle search">
                  <img src={ConceptDev} alt="" />
                </p>
                <label>Concept Development</label>
                {/* <label style="display: none">Concept Development</label> */}
              </div>
            </div>
            <div className="col-lg-2 col-md-2 centered-div">
              <div className="content">
                <p className="rounded-circle bulb">
                  <img src={StoryBording} alt="" />
                </p>
                <label>Storyboarding</label>
                {/* <label style="display: none">Storyboarding</label> */}
              </div>
            </div>
            <div className="col-lg-2 col-md-2 centered-div">
              <div className="content">
                <p className="rounded-circle people">
                  <img src={Design} alt="" />
                </p>
                <label>Design and Animation</label>
                {/* <label style="display: none">Design and Animation</label> */}
              </div>
            </div>
            <div className="col-lg-2 col-md-2 centered-div">
              <div className="content">
                <p className="rounded-circle time">
                  <img src={Sound} alt="" />
                </p>
                <label>Sound Design</label>
                {/* <label style="display: none">Sound Design</label> */}
              </div>
            </div>
            <div className="col-lg-2 col-md-2 centered-div">
              <div className="content">
                <p className="rounded-circle settings">
                  <img src={Review} alt="" />
                </p>
                <label>Review and Final Delivery</label>
                {/* <label style="display: none">Review and Final Delivery</label> */}
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="container-fluid pt-3 pb-3 mt-5 bgVoilet experience-section">
        <div className="container">
          <div className="row text-center d-flex justify-content-center align-items-center">
            <div className="col-md-10 experience-title ">
              <p className='text-bannerpara'>Motion Graphics Design Services Offered By Akkenna</p>
            </div>
          </div>
          <div className="row text-center d-flex justify-content-center align-items-center">
            <div className="col-md-10 experience-description">
              <p className='text-white'>
                If you're looking for a professional motion graphics company in India, look no further than Akkenna. The best motion graphics design services company, we have a team of experts who can create motion graphics that will put your business and message on the map. Take a look at this rundown of our primary offerings:
              </p>
            </div>
          </div>
          <div className="row experience-image nunitoRegular pt-3 mb-3">
            <div className="col-md-6 motion-services">
              <div className="row experience-content ani-boxes">
                <div className="col-md-12 d-flex gap-3 mb-3">
                  <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                    <img src={motion} alt="" />
                  </div>
                  <div>
                    <p className="experience-subtitle mb-1">
                      Explainer Videos
                    </p>
                    <p className="experience-description">
                      These entertaining cartoons will help your audience understand even the most abstract ideas.Effective communication relies on clear, engaging narrative.
                    </p>
                  </div>
                </div>
                <div className="col-md-12 d-flex gap-3 mb-3">
                  <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                    <img src={Animation} alt="" />
                  </div>
                  <div>
                    <p className="experience-subtitle mb-1">Logo Animation</p>
                    <p className="experience-description">
                      Animated company logos that are both captivating and dynamic.Create eye-catching titles for your movies and slideshows.

                    </p>
                  </div>
                </div>

                <div className="col-md-12 d-flex gap-3 mb-3">
                  <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                    <img src={Corporate} alt="" />
                  </div>
                  <div>
                    <p className="experience-subtitle mb-1">Corporate Presentations</p>
                    <p className="experience-description">
                      Liven up your presentations with moving images and other interactive media.Create polished and interesting images that dazzle investors, clients, and other stakeholders.
                    </p>
                  </div>
                </div>
                <div className="col-md-12 d-flex gap-3 mb-3">
                  <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                    <img src={Advertisements} alt="" />
                  </div>
                  <div>
                    <p className="experience-subtitle mb-1">Motion Graphic Advertisements</p>
                    <p className="experience-description">
                      Develop eye-catching online and social media advertising campaigns.Captivate your target market and increase your conversion rate with the help of some eye-catching motion graphics.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 motion-services">
              <div className="row experience-content">
                <div className="col-md-12 d-flex gap-3 mb-3">
                  <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                    <img src={Infographics} alt="" />
                  </div>
                  <div>
                    <p className="experience-subtitle mb-1">Infographics</p>
                    <p className="experience-description">
                      Data-driven moving infographics for interactively presenting data and knowledge.Interactive diagrams that simplify your facts for the average viewer.
                    </p>
                  </div>
                </div>
                <div className="col-md-12 d-flex gap-3 mb-3">
                  <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                    <img src={Demonstrations} alt="" />
                  </div>
                  <div>
                    <p className="experience-subtitle mb-1">Product Demonstrations</p>
                    <p className="experience-description">
                      Use captivating animations to show off your wares in motion.In a captivating way, emphasize features, benefits, and functionality.
                    </p>
                  </div>
                </div>
                <div className="col-md-12 d-flex gap-3 mb-3">
                  <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                    <img src={VFX} alt="" />
                  </div>
                  <div>
                    <p className="experience-subtitle mb-1">VFX</p>
                    <p className="experience-description">
                      Add eye-popping special effects to your videos and movies for a more realistic cinematic feel.Raise the bar on the professionalism of your multimedia creations.
                    </p>
                  </div>
                </div>
                <div className="col-md-12 d-flex gap-3 mb-3">
                  <div className="col-md-1 rounded-circle settings width-40 motion-icon">
                    <img src={Educational} alt="" />
                  </div>
                  <div>
                    <p className="experience-subtitle mb-1">Educational Materials</p>
                    <p className="experience-description">
                      Create instructional animations for use in training materials and online classes.Use interesting images to break down difficult material and improve comprehension.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid pt-3 experience2-section">
        <div className="container">
          <div className="row text-center d-flex justify-content-center align-items-center">
            <div className="col-md-10 experience-title">
              <p>Why Should You Choose Our Akkenna Motion Design Services?</p>
            </div>
          </div>
          <div className="row experience-image nunitoRegular pt-3 mb-1">
            <div className="col-md-7">
              <div className="row experience-content">
                <div className="col-md-12">
                  <p className="experience-description">
                    Why should you hire us for motion design? We can make your ideas come to life in a way that is both captivating and engrossing to an audience. Our team of talented designers and animators is dedicated to providing you with the most impressive motion graphics possible, and Akkenna is the best motion graphics company in India.
                  </p>
                  <p className="experience-description">
                    With a keen eye for detail, an unwavering dedication to quality, and an insatiable appetite for new ideas, we give life to your concepts through captivating animations. Our motion design services will help your marketing campaigns, educational content, or brand promotion stand out in a visually crowded field. When you work with us, you're investing in quality, originality, and the ability to capture and inspire with your message.
                  </p>
                  <p className="experience-description">
                    Discover how we can completely transform the process that your company uses to create motion designs.
                  </p>
                </div>
                <div className="motionBtn pt-3">
                  <button className="darkBtn bg-BlueButton text-white"><a href="/contact">Set Up A Call For Today</a></button>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="experience-banner motionImg box-3d">
                <img src={graphicdesign} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>




      <section className="container-fluid pt-3 pb-3 mt-5 explainer-section">
        <div className="container-fluid">
          <div className="row text-center d-flex justify-content-center align-item-center">
            <div className="col-md-8 explainer-title">
              <p>Types Of Motion Graphics Videos</p>
            </div>
          </div>
          <div className="row text-center d-flex justify-content-center align-item-center">
            <div className="col-md-8 explainer-description nunitoRegular">
              <p>
                Motion graphics videos are flexible and can be used in many situations to share information, keep people interested, and tell interesting stories. You may have seen the following types of motion graphics videos:
              </p>
            </div>
          </div>
          <div className="row text-center d-flex justify-content-center align-item-center">
            <div className="col-md-12 explain-carousel">
              <OwlCarousel className='owl-theme ' loop margin={15} nav {...options}>
                <div className="item">
                  <div className="item-image">
                    <img src={SkaterBoy} alt="" />
                  </div>
                  <div className="item-title">
                    <p>2D Motion Graphics</p>
                  </div>
                  <div className="item-description">
                    <p>
                      2D motion graphics use shapes, text, and images to create animations.
                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src="assets/image/animation/GetArrow.svg" alt='graphics'/>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={MadebyRadio} alt="" />
                  </div>
                  <div className="item-title">
                    <p>3D Motion Graphics</p>
                  </div>
                  <div className="item-description">
                    <p>
                      Using 3D objects and surroundings, 3D motion graphics generate realistic animations.
                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src="assets/image/animation/GetArrow.svg" alt='graphics'/>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={Producer} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Stop motion animation</p>
                  </div>
                  <div className="item-description">
                    <p>
                      Stop motion simulates movement by manipulating real-world items or puppets and capturing frames.
                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src="assets/image/animation/GetArrow.svg" alt='graphics'/>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={KineticTypography} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Kinetic Typography</p>
                  </div>
                  <div className="item-description">
                    <p>
                      Kinetic typography uses moving text. The use of fonts, colors, and motion to highlight a point is creative.
                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src="assets/image/animation/GetArrow.svg" alt='graphics'/>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={ExplainerVideo} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Explainer Videos</p>
                  </div>
                  <div className="item-description">
                    <p>
                      Motion graphics are used in explainer movies to break down complicated ideas to simple ones. </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src="assets/image/animation/GetArrow.svg" alt='graphics'/>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={MotionDesign} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Whiteboard Animation</p>
                  </div>
                  <div className="item-description">
                    <p>
                      Whiteboard animation is a type of animation that looks like a person drawing on a chalk or whiteboard.
                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src="assets/image/animation/GetArrow.svg" alt='graphics'/>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={Infographic} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Infographics</p>
                  </div>
                  <div className="item-description">
                    <p>
                      Data and statistics are shown in infographic clips. They simplify information by visualizing statistics and facts.
                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src="assets/image/animation/GetArrow.svg" alt='graphics'/>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={ProductAnimation} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Product Animation</p>
                  </div>
                  <div className="item-description">
                    <p>
                      Animations explain how products work or solve problems. Motion visuals demonstrate its benefits.
                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src="assets/image/animation/GetArrow.svg" alt='graphics'/>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={LogoAnimation} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Logos</p>
                  </div>
                  <div className="item-description">
                    <p>
                      Corporate logo animations use dynamic motion graphics to bring them to life.
                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src="assets/image/animation/GetArrow.svg" alt='graphics'/>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src={VideoGameAnimation} alt="" />
                  </div>
                  <div className="item-title">
                    <p>Video Game Graphics</p>
                  </div>
                  <div className="item-description">
                    <p>
                      Motion graphics are often used to make user interfaces, movements inside games, and special effects.
                    </p>
                  </div>
                  <div className="item-get-started">
                    <p>
                      Get Started
                      <span>
                        <img src="assets/image/animation/GetArrow.svg" alt='graphics'/>
                      </span>
                    </p>
                  </div>
                </div>
              </OwlCarousel>

            </div>

          </div>
        </div>
      </section>

      <Container fluid className='mt-5 mb-5'>
        <Container>
          <Row>
            <div className="row text-center d-flex justify-content-center align-item-center">
              <div className="col-md-8 guide-title">
                <p>Motions Graphics and Pieces of Information</p>
              </div>
            </div>
            <div className="row text-center d-flex justify-content-center align-item-center">
              <div className="col-md-10 guide-description nunitoRegular">
                <p>
                  Motion graphics mix graphic design and animation to produce fascinating moving images. It includes animation, graphics, icons, and other visuals. Motion graphics are utilized in films, presentations, websites, and ads. They can simplify complex information and are versatile. Motion graphics are useful for conveying concepts, improving marketing messages, and grabbing audiences by blending design aesthetics with animation.
                </p>
              </div>
            </div>
            <Col lg={12} className='d-flex mt-3'>
              <Col lg={4} className="guide-menu">
                <div className="guide-topics">
                  <p>Guide Topics</p>
                </div>
                <ul className="nav" id="guideNav">
                  <li onClick={(e) => handleClick(e, "question-1")} className="active">
                    <a href="#question-1">Benefits of Motion Graphics</a>
                  </li>
                  <li onClick={(e) => handleClick(e, "question-2")}>
                    <a href="#question-2">Key Features of Motion Graphics</a>
                  </li>
                  <li onClick={(e) => handleClick(e, "question-3")}><a href="#question-3">What kinds of tools and apps are used most often in motion graphics design?</a></li>
                  <li onClick={(e) => handleClick(e, "question-4")}>
                    <a href="#question-4">What current or upcoming trends are most affecting motion graphics?</a>
                  </li>
                  <li onClick={(e) => handleClick(e, "question-5")}>
                    <a href="#question-5">Modern Methods and Equipment Used For Motion Graphics Videos</a>
                  </li>
                  <li onClick={(e) => handleClick(e, "question-6")}>
                    <a href="#question-6">Akkenna's Individualization and Branding</a>
                  </li>
                </ul>
              </Col>
              <Col lg={8} className="guide-answers">
                <div id="question-1" className="guide-details">
                  <p className="guide-sub-question">
                    Benefits of Motion Graphics
                  </p>
                  <p className="guide-sub-answer">
                    Motion graphics are a type of digital animation that combines visual design, typography, and audio to create material that is interesting and compelling. Unlike traditional animation, which often involves characters and stories, motion graphics focuses on communicating information, ideas, or messages through dynamic pictures and movement.
                  </p>

                  <ul className="guide-sub-answer">
                    <li>Enhanced Engagement</li>
                    <li>Simplified, Complex Ideas</li>
                    <li>Visual Storytelling</li>
                    <li>Brand recognition</li>
                    <li>Versatility</li>
                    <li>Increased Conversion Rates</li>
                  </ul>

                </div>

                <div id="question-2" className="guide-details">
                  <p className="guide-sub-question">
                    Key Features of Motion Graphics
                  </p>
                  <p className="guide-sub-question">
                    Graphics and text </p>
                  <p className="guide-sub-answer">
                    Motion graphics often use graphics, shapes, and text as their main visual features. These elements move, change, and change again to share ideas.
                  </p>
                  <p className="guide-sub-question">
                    Animation</p>
                  <p className="guide-sub-answer">
                    Motion graphics involve moving these visual features. Animation can include simple moves like sliding or rotating, as well as more complicated changes and transitions.
                  </p>
                  <p className="guide-sub-question">
                    Narration</p>
                  <p className="guide-sub-answer">
                    People often use motion graphics to tell a story or explain an idea. They break down complicated thoughts so that people can understand them better.

                  </p>
                  <p className="guide-sub-question">
                    Short Format</p>
                  <p className="guide-sub-answer">
                    Most motion images are short, lasting between a few seconds and a few minutes. This shortness helps keep people interested and works well for online material.
                  </p>
                  <p className="guide-sub-question">
                    Audio</p>
                  <p className="guide-sub-answer">
                    Sound, like background music and narration, is often added to motion graphics to improve the viewing experience and get the message across.
                  </p>
                </div>

                <div id="question-3" className="guide-details">
                  <p className="guide-sub-question">What kinds of tools and apps are used most often in motion graphics design?
                  </p>
                  <p className="guide-sub-answer">
                    At Akkenna, an animated video or motion graphics video company, our designers often create and edit motion graphics with software such as
                  </p>
                  <ul>
                    <li>Adobe After Effects</li>
                    <li>Adobe Premiere Pro</li>
                    <li>Cinema 4D and </li>
                    <li>Autodesk Maya </li>

                  </ul>
                  <p className="guide-sub-answer">
                    The other common software is Autodesk Maya. These tools provide access to a comprehensive library of features that can be utilized while animating graphics and producing effects.
                  </p>

                </div>
                <div id="question-4" className="guide-details">
                  <p className="guide-sub-question">
                    What current or upcoming trends are most affecting motion graphics?
                  </p>
                  <p className="guide-sub-answer">
                    Designers and studios need to accept developing technology and adjust to these prevailing trends in order to remain competitive and relevant in the field of motion graphics. A blend of inventiveness and technological advancement continues to shape the development of motion graphics.
                  </p>

                  <p className="guide-sub-answer">
                    We have collected a list of some of the most interesting trends in motion graphic design that you can add to your collection of graphic design weaponry. This list can be found below.
                  </p>

                  <div className="guide-sub-answer d-flex gap-5">
                    <ul>
                      <li>Liquid Motion</li>
                      <li>Mixing Media </li>
                      <li>AI in Motion Design</li>
                      <li>Kynetic Typography</li>
                      <li>Hyper Realism</li>
                    </ul>
                    <ul>
                      <li>Multi Dimensional graphics </li>
                      <li>Micro Interaction</li>
                      <li>Data Visualization</li>
                      <li>NFT & Blockchain</li>
                      <li>Animated Logos</li>
                    </ul>
                  </div>
                </div>
                <div id="question-5" className="guide-details">
                  <p className="guide-sub-question">
                    Modern Methods and Equipment Used For Motion Graphics Videos
                  </p>
                  <p className="guide-sub-answer">
                    At Akkenna, we're dedicated to providing you with cutting-edge motion graphics that not only attract viewers but also get your message across clearly. In order to do this, we use a set of high-tech motion graphics creation tools, software, and technologies.
                  </p>
                  <p className="guide-sub-question">
                    Superior Software for Any Need
                  </p>
                  <p className="guide-sub-answer">
                    Our motion graphics team is proficient in Adobe After Effects, Adobe Animate, and Cinema 4D.
                  </p>
                  <p className="guide-sub-question">
                    Modern Animation Software</p>
                  <p className="guide-sub-answer">
                    We use Autodesk Maya and Cinema 4D for 2D and 3D animation. These tools add life to your work with flexibility and adaptability.
                  </p>
                  <p className="guide-sub-question">
                    Proficient Video Editing</p>
                  <p className="guide-sub-answer">
                    We use Adobe Premiere Pro and Final Cut Pro for smooth transitions and polished output.

                  </p>
                  <p className="guide-sub-question">
                    Creation of Intelligent Contracts</p>
                  <p className="guide-sub-answer">
                    In NFTs, where motion graphics are popular, we use Ethereum's Solidity language for smart contracts.
                  </p>
                  <p className="guide-sub-question">
                    Computer-Generated Imagery and Editing</p>
                  <p className="guide-sub-answer">
                    The industry-standard composing software Nuke powers our visual effects and composing.
                  </p>
                </div>
                <div id="question-6" className="guide-details">
                  <p className="guide-sub-question">
                    Akkenna's Individualization and Branding
                  </p>
                  <p className="guide-sub-answer">
                    Akkenna knows motion graphics must be customized. We pride ourselves on our remarkable ability to effortlessly integrate each client's logo and messaging into our work. Using custom motion graphics, we energize your brand and spread your message.
                  </p>
                  <p className="guide-sub-question">
                    Make a Name for Yourself, Fashionably </p>
                  <p className="guide-sub-answer">
                    Every brand has its own values, tone, and identity. We spend time getting to know your brand's personality and style to make sure every motion graphic we make matches.
                  </p>
                  <p className="guide-sub-question">
                    Improving Brand Recognition</p>
                  <p className="guide-sub-answer">
                    Motion graphics are more than simply images—they represent your brand. Our professional designers and animators work with you to produce breathtaking animations and strengthen your brand's identity.
                  </p>
                  <p className="guide-sub-question">
                    Integrity Across All Devices</p>
                  <p className="guide-sub-answer">
                    Today's multi-platform world requires uniformity. We optimize your motion graphics for social media, websites, and mobile apps.

                  </p>
                  <p className="guide-sub-question">
                    Improved Communication</p>
                  <p className="guide-sub-answer">
                    The brand message is its core. We create captivating images from your important messaging.
                  </p>
                  <p className="guide-sub-question">
                    Telling Your Own Story Through Images</p>
                  <p className="guide-sub-answer">
                    Our goal is to tell your brand's distinctive story. The stories we write reflect your values, goals, and personality.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>

      </Container>


      {

<ContactForm/>

}









      <section>
        <div className="homeFaq">
          <div className="container">
            <div className="genaral-title">
              <div className="row d-flex justify-content-center align-item-center">
                <div className="col-md-7 text-center">
                  <h3 className="fw-bold">Frequently Asked Question</h3>

                </div>
              </div>
            </div>
            <div className="accordion accordion-faq" id="accordionfaqExample">
              <div className="row d-flex justify-content-center align-item-center">
                <div className="col-md-6">
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingOne">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
                        Motion pictures are cool, but how can they help my business?
                      </button>
                    </h3>
                    <div id="faq-collapseOne" className="accordion-collapse collapse" aria-labelledby="faq-headingOne" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">
                        Motion graphics combine animation and visual design to convey ideas clearly. They help businesses by improving brand identity, making complicated ideas easier to understand, and getting people interested.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
                        What is included in the pricing, and are there any hidden costs?
                      </button>
                    </h3>
                    <div id="faq-collapseThree" className="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">
                        No, there are no hidden charges. It's important to have a clear idea of how the prices work. Ask what's included in the price given and if there might be any extra costs.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
                        Can I customize the motion graphics to match my brand's style and message?
                      </button>
                    </h3>
                    <div id="faq-collapseTwo" className="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">
                        Yes, most motion graphics services let you change things so that the final result fits with your brand's identity, style guidelines, and message.
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingSix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseSix" aria-expanded="false" aria-controls="faq-collapseSix">
                        How can I measure the success of a motion graphics project?
                      </button>
                    </h3>
                    <div id="faq-collapseSix" className="accordion-collapse collapse" aria-labelledby="faq-headingSix" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">
                        Success can be defined by things like more people getting involved, a higher rate of conversion, and brand recognition. Metrics like views, shares, and comments from viewers can show how well the movie works.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFour" aria-expanded="false" aria-controls="faq-collapseFour">
                        What happens if I need updates or changes to the video in the future?
                      </button>
                    </h3>
                    <div id="faq-collapseFour" className="accordion-collapse collapse" aria-labelledby="faq-headingFour" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">
                        Akkenna Animation offers post-production help for updates or changes that keep your motion graphics content up-to-date and useful.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="faq-headingEight">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseEight" aria-expanded="false" aria-controls="faq-collapseEight">
                        Do I need to prepare any content or materials for the motion graphics project?
                      </button>
                    </h3>
                    <div id="faq-collapseEight" className="accordion-collapse collapse" aria-labelledby="faq-headingEight" data-bs-parent="#accordionfaqExample">
                      <div className="accordion-body">
                        Depending on the project, you may need to give things like branding assets, product information, or a general idea of what you want to say. Akkenna Animation will tell you what you need to do.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    </HelmetProvider>
  )
}

export default MotionGraphics